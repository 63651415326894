import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAPS_API_KEY } from "../../../constants/key";
import mapsService from "../../../services/maps.service";
import { useDispatch, useSelector } from "react-redux";
import { nextStep } from "../../../redux/actions/StepsActions";
import { FaMapPin } from "react-icons/fa";
import { FiTrash } from "react-icons/fi";
import { FaCircleCheck, FaCircleMinus } from "react-icons/fa6";
import './Maps.scss'
import { Button } from "primereact/button";
import CustomButton from "../Buttons/customButton";
import { distance } from "framer-motion";

interface MapMarkerProps {
  lat: number;
  lng: number;
}

interface MapsProps {
  defaultCenter: {
    lat: number;
    lng: number;
  };
  address: string;
}

const CustomMarker: React.FC<{ lat: number; lng: number }> = () => (
  <div
    style={{
      position: "absolute",
      top: -10,
      left: -10,
      zIndex: 1000,

    }}
  ><FaMapPin size={14} style={{ color: "#EF4444" }}></FaMapPin></div>
);


const Maps: React.FC<MapsProps> = ({ defaultCenter, address }) => {
  const [center, setCenter] = useState(defaultCenter);
  const [shouldLoadMap, setShouldLoadMap] = useState(false);
  const [markers, setMarkers] = useState<MapMarkerProps[]>([]);
  const dispatch = useDispatch();

  const calculatedDistance = useSelector(
    (state: any) => state.distance.calculatedDistance
  );

  useEffect(() => {
    if (address) {
      handleAddressSubmit();
    }
  }, [address, center.lat, center.lng]);

  const handleAddressSubmit = async () => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${GOOGLE_MAPS_API_KEY}`
    );
    const data = await response.json();

    if (data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location;
      setCenter({ lat, lng });
      setShouldLoadMap(true);
    }
  };
  const handleMapClick = async (event: { lat: number; lng: number }) => {
    const newMarkers = [...markers, event];
    setMarkers(newMarkers);
  };


  const deleteMarker = () => {
    if (markers.length > 0) {
      const newMarkers = [...markers];
      newMarkers.pop();
      setMarkers(newMarkers);
    }
  }
  const calculateDistance = async (
    pointA: MapMarkerProps,
    pointB: MapMarkerProps
  ) => {
    try {
      const response = await mapsService.calculateDistance({
        origin: `${pointA.lat},${pointA.lng}`,
        destination: `${pointB.lat},${pointB.lng}`,
      });

      if (response.data.routes.length > 0) {
        const distance = response.data.routes[0].legs[0].distance.text;
        dispatch({ type: "SET_CALCULATED_DISTANCE", payload: distance });
        dispatch(nextStep());
      }
    } catch (error) {
      console.error("Error calculating distance:", error);
    }
  };

  const validate = async () => {
    const distance = getDistance(markers[markers.length - 2], markers[markers.length - 1]);
    dispatch({ type: "SET_CALCULATED_DISTANCE", payload: distance });
    dispatch(nextStep());
  }

  function rad(x: number) {
    return x * Math.PI / 180;
  };

  function getDistance(p1: any, p2: any) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d.toFixed(0) + 'm'; // returns the distance in meter
  };

  return (
    <div className="map-container">
      {shouldLoadMap && (
        <div style={{ height: "500px", width: "100%" }}>
          {/* <FiTrash onClick={deleteMarker} ></FiTrash> */}
          <div style={{
            display: "flex", alignItems: "center",
            justifyContent: "start", columnGap: "10px", marginBottom: "12px",
            marginLeft: "15px", marginTop: "-50px"
          }}>
            <div onClick={deleteMarker}>
              <CustomButton title="Supprimer" disabled={markers.length === 0} />
            </div>
            <div onClick={validate}>
              <CustomButton title="Valider ma distance" disabled={markers.length !== 2} />
            </div>
          </div>
          <div style={{ height: "454px", width: "100%" }}>
            <GoogleMapReact
              key={markers.length}
              bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
              defaultCenter={center}
              defaultZoom={19}
              options={{
                mapTypeId: "satellite",
                minZoom: 19,
                maxZoom: 19,
                draggable: false,
                zoomControl: false,
                tilt: 0
              }}
              onClick={({ lat, lng }) => handleMapClick({ lat, lng })}
              center={center}
            >
              {markers.map((marker, index) => (
                <CustomMarker key={index} lat={marker.lat} lng={marker.lng} />
              ))}
            </GoogleMapReact>
          </div>

        </div>
      )}
    </div>
  );
};

export default Maps;
