export enum QuoteConstants {

  individual = 'Particulier',
  professional = 'Professionnel',
  coliving = 'Copropriété',

  house = 'Maison',
  appartment = 'Appartement',

  privateParking = 'Parking privé de l\'entreprise',
  publicParking = 'Parking publique',
  sharedParking = 'Parking copropriété',
  houseOfAPro = 'A mon domicile',

  circuitBreaker1 = 'Disjoncteurs monophasé',
  circuitBreaker3 = 'Disjoncteurs triphasé',
  fuse = 'Fusible',

  yes = 'Oui',
  no = 'Non',

  lessThanFive = 'Entre 1 et 5 bornes',
  lessThanTen = 'Entre 5 et 10 bornes',
  moreThanTen = 'Supérieure à 10 bornes',


  ConnectedStation = 'Borne connectée',
  DisconnectedStation = 'Borne non connectée',
}

export enum DealStatus {
  ToSign = 'TO_SIGN',
  Lost = 'LOST',
  Won = 'WON',
  ToEvaluate = 'TO_EVALUATE',
  ToAmend = 'TO_AMEND',
  VisitToDo = 'VISIT_TO_DO',
  Unachievable = 'UNACHIEVABLE',
  Unreachable = 'UNREACHABLE',
  ToDraft = 'TO_DRAFT'
}

export enum OrderStatus {
  ToPlan = 'TO_PLAN',
  Planned = 'PLANNED',
  Installed = 'INSTALLED',
  Paid = 'PAID'
}

export enum orderStatus {
  toPlan = 'A planifier',
  planned = 'Planifiee',
  installed = 'Installée',
  paid = 'Payée'
}

export enum homePageFilters {
  user = 'user',
  concession = 'concession',
  plaque = 'plaque',
  allTime = 'allTime',
  months = 'threeMonths',
  currentYear = 'currentYear'
}
export enum homePageRoutes {
  order = 'order',
  installation = 'installation',
}

export enum MailType {
  AMEND_EMS = 1,
  IND_EVALUATE_CLIENT = 2,
  IND_EVALUATE_INSTALLER = 3,
  PRO_EVALUATE_CLIENT = 4,
  PRO_EVALUATE_INSTALLER = 5,
  INSTALLED_CLIENT = 6,
  INSTALLED_EMS = 7,
  PLAN_REMINDER = 8,
  PLAN_REMINDER_DIRECTION = 9,
  PLANNED_INSTALLER = 10,
  SIGN_CLIENT = 11,
  DRAFT_EMS = 12,
  UNREACHABLE_CLIENT = 13
}

export enum Role {
  Installer = 'Installer',
  InstallerManager = 'InstallerManager',
  Commercial = 'Commercial',
  Direction = 'Direction',
  LSAdmin = 'LSAdmin',
  EMSAdmin = 'EMSAdmin'
}
// API
export enum CircuitBreaker {
  Mono = 'MONO',
  Tri = 'TRI',
  Fuse = 'FUSE'
}

export enum StationType {
  Connected = 'CONNECTED',
  Disconnected = 'NOT_CONNECTED'
}

