import React from "react";


const CoOwnership: React.FC = () => {
  return (
<div>
    <h1>Copropriété</h1>
    <p style={{fontSize: "20px"}}>
    Under construction
    </p>
  </div>
  )
};

export default CoOwnership;
