import React from "react";


function PartnerOffer() {
  return (
    <div>
      <h1>L'offre 360 partenaires</h1>
      <p style={{ fontSize: "20px" }}>
      Under construction
      </p>
    </div>
  )
};

export default PartnerOffer;
