import { Tag } from "primereact/tag";
import { DealStatus, OrderStatus } from "../../../constants/enum";
import "./status.scss";
import { useSelector } from "react-redux";
import { mapStatus } from "../../../helper/statusMapper";

interface badgeProps {
  text: string;
  page?: string;
}

function StatusRenderer({ text, page }: badgeProps) {
  const isDark = useSelector((state: any) => state.theme.isDark);

  function getStatusClass(text: string) {
    switch (text) {
      case OrderStatus.ToPlan:
      case DealStatus.ToEvaluate:
        return 'status-warning';
      case DealStatus.ToSign:
      case OrderStatus.Planned:
        return isDark ? 'status-open-dark' : '';

      case DealStatus.Lost:
        return 'status-lose';
      case DealStatus.Won:
      case OrderStatus.Installed:
      case OrderStatus.Paid:
        return 'status-win';
      default:
        return '';
    }
  }

  const getSeverity = (value: string) => {
    switch (value) {
      case DealStatus.Won:
      case OrderStatus.Installed:
      case OrderStatus.Paid:
        return 'success';

      case DealStatus.ToSign:
      case OrderStatus.Planned:
        return 'info';

      case OrderStatus.ToPlan:
      case DealStatus.ToEvaluate:
      case DealStatus.VisitToDo:
      case DealStatus.ToAmend:
      case DealStatus.ToDraft:
        return 'warning';

      case DealStatus.Lost:
      case DealStatus.Unachievable:
      case DealStatus.Unreachable:
        return 'danger';

      default:
        return null;
    }
  };

  const getIcon = (text: string) => {
    switch (text) {
      case DealStatus.Won:
      case OrderStatus.Installed:
      case OrderStatus.Paid:
        return 'pi pi-check';
      case DealStatus.Lost:
      case DealStatus.Unachievable:
        return 'pi pi-times';

      case DealStatus.ToSign:
        return 'pi pi-spinner'
      case OrderStatus.Planned:
        return 'pi pi-calendar';

      case OrderStatus.ToPlan:
      case DealStatus.ToEvaluate:
      case DealStatus.VisitToDo:
      case DealStatus.ToAmend:
      case DealStatus.ToDraft:
        return 'pi pi-exclamation-circle';

      default:
        return null;
    }
  };
  if (text === null) {
    return null;
  }

  const severity = getSeverity(text);
  const icon = getIcon(text);

  const getDarkClass = (text: string): string => {
    let tag = getSeverity(text);
    switch (tag) {
      case 'info':
        return 'info-dark';
      case 'success':
        return 'success-dark';
      case 'danger':
        return 'danger-dark'
      case 'warning':
        return 'warning-dark'

      default:
        return '';
    }
  }

  return (
    <div>
      <Tag
        value={mapStatus(text, page)}
        severity={severity}
        icon={icon}
        style={{
          background: text === DealStatus.VisitToDo ? 'purple' : 'defaultBackground',
          fontSize: '11px'
        }}
        className={isDark ? getDarkClass(text) : 'he'}
      ></Tag>
    </div>
  );
}

export default StatusRenderer;
