import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nextStep, updateStep3Data, } from "../../../../redux/actions/StepsActions";
import { Text } from "@nextui-org/react";
import "../Step.scss";
import SharedCard from "../../../shared/SharedCard";
import disjoncteur1 from "../../../../assets/quote-icons/disjoncteur1.png";
import disjoncteur3 from "../../../../assets/quote-icons/disjoncteur3.png";
import fusibleWhite from "../../../../assets/quote-icons/fusibleWhite.png";
import fusible from "../../../../assets/quote-icons/fusible.png";
import d1White from "../../../../assets/quote-icons/d1White.png";
import d3White from "../../../../assets/quote-icons/d3White.png";
import { CircuitBreaker, QuoteConstants } from "../../../../constants/enum";

function Step3() {
  const dispatch = useDispatch();
  const selectedCard = useSelector((state: any) => state.steps.selectedCards.step3);

  const handleCardSelect = (selectedData: any) => {
    dispatch(updateStep3Data(selectedData));
    dispatch(nextStep());
  };
  const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);
  const isDark = useSelector((state: any) => state.theme.isDark) as boolean;

  useEffect(() => {
    const handleResize = () => {
      setIsXSmallScreen(window.innerWidth < 650);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const widthClass = isXSmallScreen ? "150px" : "200px";

  return (
    <div className="custom__container">
      <Text className="step__title">
        Le tableau électrique de votre maison est-il protégé par ?
      </Text>
      <div className="content__container">
        <div
          className={selectedCard === QuoteConstants.circuitBreaker1 ? "card__selected" : ""}
          onClick={() => handleCardSelect(CircuitBreaker.Mono)}>
          <SharedCard
            title={QuoteConstants.circuitBreaker1}
            image={!isDark ? disjoncteur1 : d1White}
            width={widthClass}
            height="180px"
            layout="square" />
        </div>
        <div
          className={selectedCard === QuoteConstants.circuitBreaker3 ? "card__selected" : ""}
          onClick={() => handleCardSelect(CircuitBreaker.Tri)}>
          <SharedCard
            title={QuoteConstants.circuitBreaker3}
            image={!isDark ? disjoncteur3 : d3White}
            width={widthClass}
            height="180px"
            layout="square" />
        </div>
        <div
          className={selectedCard === QuoteConstants.fuse ? "card__selected" : ""}
          onClick={() => handleCardSelect(QuoteConstants.fuse)}>
          <SharedCard
            title={QuoteConstants.fuse}
            image={!isDark ? fusible : fusibleWhite}
            width={widthClass}
            height="180px"
            layout="square" />
        </div>
      </div>
    </div>
  );
}

export default Step3;
