import axios from 'axios';
import { Order } from '../entities/order.entity';

const API_URL = process.env.REACT_APP_API_URL;

export function getOrders() {
    return axios.get(API_URL + 'orders', { withCredentials: true });
};

export function getNumberOfOrders(id: number, partnerId: number, parentPartnerId: number) {
    return axios.get(API_URL + `orders/number-of-orders/${id}/${partnerId}/${parentPartnerId}`, { withCredentials: true });
}

export async function saveDealService(order: Order) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post(API_URL + 'orders', order, { withCredentials: true, headers: customHeaders });
}

export function getOrdersByClientId(clientId: number) {
    return axios.get(API_URL + `orders/by-client-id/${clientId}`, { withCredentials: true });
};

export async function updateOrder(order: Order) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.put(API_URL + 'orders', order, { withCredentials: true, headers: customHeaders });
}

export async function updateOrderStatus(orderId: string, newStatus: string) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    try {
        const response = await axios.put(API_URL + `orders/update-status/${orderId}/${newStatus}`, null, {
            withCredentials: true,
            headers: customHeaders,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function downloadOrderFrom(order: Order) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post(API_URL + 'orders/download-order-form', order, { withCredentials: true, headers: customHeaders, responseType: 'blob' });
}