import axios from 'axios';
import { LoginDto, RegisterDto } from '../dtos/auth.dto';
import { Quote } from '../entities/quote.entity';
import { PriceWithDetailDto, cheapestEMSDeal } from '../dtos/price-with-details.dto';

const API_URL = process.env.REACT_APP_API_URL;
const test = process.env;

export async function getCheapestQuote(quote: Quote) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post<cheapestEMSDeal>(API_URL + 'quote/cheapest', quote, { withCredentials: true, headers: customHeaders });
}

export async function getQuotePrice(quote: Quote) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post<PriceWithDetailDto[]>(API_URL + 'quote', quote, { withCredentials: true, headers: customHeaders });
}
export async function processQuote(quote: Quote) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post(API_URL + 'quote/process-quote', quote, { withCredentials: true, headers: customHeaders });
}

export async function downloadQuote(quote: Quote) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post(API_URL + 'quote/download-quote', quote, { withCredentials: true, headers: customHeaders, responseType: 'blob' });
}
