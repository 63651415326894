import React from "react";


function Offer() {
    return (
        <div>
            <h1>L'offre EMS</h1>
            <p style={{ fontSize: "20px" }}>
            Under construction
            </p>
        </div>
    )
};

export default Offer;
