import { Client } from '../../../entities/client.entity';
import { Dialog } from 'primereact/dialog';
import { useSelector } from 'react-redux';
import './UpdateClient.scss';
import UpdateClientForm from './core/UpdateClientForm';


interface UpdateProps {
    open: boolean;
    onClose: () => void;
    client?: Client;
    handleUpdate: (data: string) => void;
}

export default function UpdateClient({ open, onClose, client, handleUpdate }: UpdateProps) {
    function handleUpdateForm(reason: string) {
        handleUpdate(reason);
        onClose();
    }

    const isDark = useSelector((state: any) => state.theme.isDark) as boolean;
    return (
        <Dialog
            header="Modifier la fiche client"
            headerStyle={{
                padding: "15px 24px", borderTopLeftRadius: "18px", borderTopRightRadius: "18px", fontWeight: "500",
                background: isDark ? "#2a323d" : "#fff", color: isDark ? "rgba(255,255,255,.87)" : "black"
            }}
            visible={open} onHide={onClose}
            closable={false}
            closeOnEscape={true}
            dismissableMask={true}
            style={{ width: '30vw', padding: "0px", borderRadius: "18px", background: "red" }}
            className={isDark ? 'modal-dark' : 'modal-light'}
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            contentStyle={{
                borderBottomLeftRadius: "18px", borderBottomRightRadius: "18px", background: isDark ? "#2a323d" : "#fff",
                color: isDark ? "rgba(255,255,255,.87)" : "#495057"
            }}>
            <UpdateClientForm client={client} submit={handleUpdateForm} cancel={onClose}></UpdateClientForm>
        </Dialog >
    );
}

