import EclaireImageImport from "./Logo/Eclair-sans-ems.png";
import { Image } from "@nextui-org/react";

function EclaireImage() {
    return (
        <div className="divImageEclaire">
            <Image
                src={EclaireImageImport}
                alt="Logo"
                width={100}
            />
        </div>
    );

}

export default EclaireImage;