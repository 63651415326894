import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function uploadFile(file: any, folder: any) {
    const formData = new FormData();
    formData.append('folder', folder);
    formData.append('file', file);
    const customHeaders = {
        'Content-Type': 'multipart/form-data;',
    };
    return await axios.post(API_URL + 'upload', formData, { withCredentials: true, headers: customHeaders });
}