import { Input, Text, getDocumentTheme } from "@nextui-org/react";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Deal } from "../../entities/deal.entity";
import {
    getDeals,
} from "../../services/deals.service";
import { formatDate } from "../../helper/dateFormattor";
import StatusRenderer from "../shared/StatusRenderer/status";
import { Toast } from "primereact/toast";
import TypeRenderer from "../shared/TypeRenderer/Type";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { UppercaseFormatter } from "../../helper/uppercaseFormattor";
import CustomButton from "../shared/Buttons/customButton";
import { Tooltip } from "primereact/tooltip";
import { useSelector } from "react-redux";
import { User } from "../../entities/user.entity";
import "../shared/Tables/table.scss";
import { DealStatus, OrderStatus } from "../../constants/enum";
import ActionModal from "./action-modal/ActionModal";
import { getOrders } from "../../services/orders.service";
import { Order } from "../../entities/order.entity";
import CommentIndicator from "../shared/CommentIndicator/CommentIndicator";
import Details from "../deals/details/Details";
import { constants } from "buffer";

function Installations() {
    const loggedUser = useSelector((state: any) => state.login.loggedUser) as User;
    const filters = ['Leads', 'Installation', 'Terminés'];
    const [showFilter, setShowFilter] = useState(false);
    const [deals, setDeals] = useState<Deal[] | any>([]);
    const [withoutFilterDeals, setWithoutFilterDeals] = useState<Deal[]>([]);
    const toast = useRef<Toast | null>(null);
    const [searchInput, setSearchInput] = useState('');
    const [showActionModal, setShowActionModal] = useState(false);
    const [selectedDeal, setSelectedDeal] = useState<Deal>();
    const [orders, setOrders] = useState<Order[]>([]);
    const [sideBarData, setSideBarData] = useState<any>();
    const [visible, setVisible] = useState(false);
    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
        let theme = window.localStorage.getItem('data-theme');
        setIsDark(theme === 'dark');

        const observer = new MutationObserver((mutation) => {
            let newTheme = getDocumentTheme(document?.documentElement);
            setIsDark(newTheme === 'dark');
        });

        observer.observe(document?.documentElement, {
            attributes: true,
            attributeFilter: ['data-theme', 'style']
        });

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        (
            async () => {

                await getOrders()
                    .then(response => {
                        setOrders(response.data);
                    });

                await getDeals()
                    .then(response => {
                        let filteredResponse = response.data as Deal[];
                        filteredResponse.forEach(d => {
                            let order = orders.find(o => o.deal?.id === d.id);
                            d.order = order;
                        });
                        setDeals(filteredResponse);
                        setWithoutFilterDeals(filteredResponse);
                    })
                    .catch(err => console.error('Disconnected'));
            }
        )();
    }, [orders.length]);

    const closeActionModal = () => {
        setShowActionModal(false);
    }

    function getActionModalIndex() {
        if (!selectedDeal?.order) {
            switch (selectedDeal?.status) {
                case DealStatus.ToEvaluate:
                case DealStatus.VisitToDo:
                    return 1;
                default:
                    return 0;
            }
        }
        else {
            if (selectedDeal.order.status === OrderStatus.Planned) {
                return 2;
            }
        }
    }

    const handleFilter = (filter: string) => {
        let filtered: Deal[] = [];
        switch (filter) {
            case 'Leads':
                filtered = withoutFilterDeals.filter(deal => [DealStatus.ToEvaluate, DealStatus.VisitToDo].includes(deal.status as DealStatus));
                break;
            case 'Installation':
                filtered = withoutFilterDeals.filter(deal => [OrderStatus.Planned, OrderStatus.ToPlan].includes(deal.order?.status as OrderStatus));
                break;
            case 'Terminés':
                filtered = withoutFilterDeals.filter(deal => [OrderStatus.Installed].includes(deal.order?.status as OrderStatus));
                break;
        }

        if (filtered.length !== deals.length) {
            setDeals(filtered);
        }
        else {
            setDeals(withoutFilterDeals);
        }
    }

    const handleInputChange = (event: any) => {
        const inputValue = event.target.value;
        setSearchInput(inputValue);

        const filteredResults = withoutFilterDeals.filter((deal) =>
            deal.id?.toString().startsWith(inputValue.toLowerCase()) ||
            deal.clientId?.firstName.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            deal.clientId?.lastName.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            deal.clientId?.type.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            deal.clientId?.city.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            deal.clientId?.partnerId?.lastName.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            deal.installerName?.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            deal.creationDate?.toString().includes(inputValue.toLowerCase()) ||
            deal.clientId?.companyName?.toLowerCase().includes(inputValue.toLowerCase()) ||
            deal.clientId?.siretCode?.toString().startsWith(inputValue.toLowerCase())
        );

        setDeals(filteredResults);
    };

    const getBadgeNumber = (filter: string) => {
        switch (filter) {
            case 'Leads':
                return withoutFilterDeals.filter(deal => [DealStatus.ToEvaluate, DealStatus.VisitToDo].includes(deal.status as DealStatus)).length.toString();
            case 'Installation':
                return withoutFilterDeals.filter(deal => [OrderStatus.Planned, OrderStatus.ToPlan].includes(deal.order?.status as OrderStatus)).length.toString();
            case 'Terminés':
                return withoutFilterDeals.filter(deal => [OrderStatus.Installed].includes(deal.order?.status as OrderStatus)).length.toString();
        }
    }
    const handleFilters = () => {
        setShowFilter(!showFilter);
        if (!showFilter) {
            setDeals(withoutFilterDeals);
        }
    }

    function relaunchFormat(relaunch: number): string {
        switch (relaunch) {
            case 1:
                return '1ère relance';
            case 2:
                return '2ème relance';
            case 3:
                return '3ème relance';
            default:
                return '';
        }
    }

    const handleSideBarOpen = (data: any) => {
        setSideBarData(data);
        setVisible(true);
        setShowActionModal(false)
    }

    const handleHide = () => {
        setVisible(false);
        setSideBarData(null);
    };

    const onColumnClick = () => {
        setShowActionModal(true);
    }

    const dynamicBodyTemplate = (value: any) => {
        return (
            <div onClick={onColumnClick} style={{ width: '100%', height: '100%' }}>
                <span style={{ cursor: 'pointer' }}>
                    {value}
                </span>
            </div>
        );
    };

    const dynamicBodyTemplateUpperCase = (value: any) => {
        return (
            <div onClick={onColumnClick} style={{ width: '100%', height: '100%' }}>
                <span style={{ cursor: 'pointer' }}>
                    {UppercaseFormatter(value)}
                </span>
            </div>
        );
    };

    const dynamicBodyTemplateDate = (value: any) => {
        return (
            <div onClick={onColumnClick} style={{ width: '100%', height: '100%' }}>
                <span style={{ cursor: 'pointer' }}>
                    {formatDate(value)}
                </span>
            </div>
        );
    };

    const dynamicBodyTemplateTypeRenderer = (rowData: any) => {
        return (
            <div onClick={onColumnClick} style={{ width: '100%', height: '100%' }}>
                <span style={{ cursor: 'pointer' }}>
                    <TypeRenderer text={rowData.clientId.type} companyName={rowData.clientId.companyName} />
                </span>
            </div>
        );
    };
    const dynamicBodyTemplateRelaunch = (value: any) => {
        return (
            <div onClick={onColumnClick} style={{ width: '100%', height: '100%' }}>
                <span style={{ cursor: 'pointer' }}>
                    {relaunchFormat(value)}
                </span>
            </div>
        );
    };

    const dynamicBodyTemplateStatus = (rowData: any) => {
        return (
            <div onClick={onColumnClick} style={{ width: '100%', height: '100%' }}>
                {(rowData.status === DealStatus.Lost || rowData.status === DealStatus.Unachievable) ? (
                    <>
                        <Tooltip target=".status" style={{ fontSize: '12px' }} />
                        <div className="status" data-pr-tooltip={rowData.withdrawReason}
                            data-pr-position="right">
                            <StatusRenderer text={rowData.order ? rowData.order.status : rowData.status} />
                        </div>
                    </>
                ) :
                    <StatusRenderer text={rowData.order ? rowData.order.status : rowData.status} />}
            </div>
        );
    };

    function displayOrder(rowData: any) {
        if (rowData.orders.length !== 0)
            return dynamicBodyTemplateDate(rowData.orders[(rowData.orders.length - 1)].installationDate);
        else
            return '';
    };


    return (
        <div>
            <Container>
                <Row className="mt-2 mb-3">
                    <Col>
                        <Text size={36} b>Installations</Text>
                    </Col>
                </Row>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="div__filter">
                        <div className="div__input_icon">
                            <Input
                                width='90%'
                                type="search"
                                placeholder="Rechercher une installation"
                                value={searchInput}
                                onChange={handleInputChange}
                                aria-labelledby="search"
                            />
                            <div onClick={handleFilters}>
                                <CustomButton title="Filtres" />
                            </div>
                        </div>
                        {showFilter && <div className="div__button_filter">
                            {filters.map((filter, index) => (
                                <Col key={index} style={{ display: 'flex' }} className="justify-content-center">
                                    <Button
                                        aria-labelledby="btn"
                                        rounded
                                        outlined
                                        size="small"
                                        badge={getBadgeNumber(filter)}
                                        onClick={() => handleFilter(filter)}
                                        label={filter}
                                        style={{ width: '7rem', color: isDark ? '#8dd0ff' : '' }}
                                    >
                                    </Button>
                                </Col>
                            ))}
                        </div>}
                    </div>
                </div>
                <Row>
                    <Col>
                        <div className={!isDark ? "custom_table__light" : "custom_table__dark"}>
                            <DataTable stripedRows columnResizeMode="expand" resizableColumns reorderableColumns paginator rows={10} value={deals}
                                removableSort tableStyle={{ minWidth: '50rem' }} sortMode="multiple" selectionMode="single"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[10, 25, 50]}
                                emptyMessage="Aucune affaire." currentPageReportTemplate="Affaire {first} jusqu'à {last} sur {totalRecords}"
                                onRowSelect={(e) => { setSelectedDeal(e.data); }}
                            >
                                <Column
                                    aria-labelledby="IdAffaire"
                                    field="id"
                                    header=""
                                    body={(rowData) => (
                                        <div onClick={() => handleSideBarOpen(rowData)}>
                                            <CommentIndicator text={rowData.id} comment={rowData.customNotes} />
                                        </div>
                                    )}
                                ></Column>
                                <Column aria-labelledby="idAffaire" field="id" header="Affaire" sortable style={{ fontWeight: "500" }} body={(rowData) => dynamicBodyTemplate(rowData.id)} ></Column>
                                <Column aria-labelledby="creation" field="creationDate" header="Creation" body={(rowData) => dynamicBodyTemplateDate(rowData.creationDate.toString())} sortable />
                                <Column aria-labelledby="type" field="clientId.type" header="Type de client" style={{ minWidth: '6rem' }} body={(rowData) => dynamicBodyTemplateTypeRenderer(rowData)} sortable />
                                <Column aria-labelledby="lastname" field="clientId.lastName" header="Nom du client" sortable style={{ fontWeight: "500" }} body={(rowData) => dynamicBodyTemplate(rowData.clientId.lastName)} ></Column>
                                <Column aria-labelledby="city" field="clientId.city" header="Ville" sortable style={{ fontWeight: "500" }} body={(rowData) => dynamicBodyTemplate(rowData.clientId.city)}></Column>
                                <Column aria-labelledby="status" field="status" header="Statut" body={(rowData) => dynamicBodyTemplateStatus(rowData)} sortable />
                                <Column aria-labelledby="installer" field="installerName" header="Technicien" sortable body={(rowData) => dynamicBodyTemplate(rowData.installerName)} />
                                <Column aria-labelledby="relaunch" header="Relance" body={(rowData) => dynamicBodyTemplateRelaunch(rowData.relaunch)} sortable />
                                {/* <Column aria-labelledby="installation" header="Date d'installation" body={(rowData) => displayOrder(rowData)} sortable ></Column> */}
                                <Column aria-labelledby="update" field="updateDate" header="Dernière modification" body={(rowData) => dynamicBodyTemplateDate(rowData.updateDate)} sortable />
                                <Column aria-labelledby="concession" field="clientId.partnerId.partner.name" header="Concession" body={(rowData) => dynamicBodyTemplate(rowData.clientId.partnerId.partner.name)} sortable />
                                <Column aria-labelledby="partnerLastname" field="clientId.partnerId.lastName" header="Commercial" sortable body={(rowData) => dynamicBodyTemplate(rowData.clientId.partnerId.lastName)} />
                                <Column aria-labelledby="comment" field="comment" header="Commentaire" body={(rowData) => dynamicBodyTemplate(rowData.comment)} />
                            </DataTable >
                        </div>

                    </Col>
                </Row>
                {
                    showActionModal && selectedDeal && (
                        <ActionModal open={showActionModal} onClose={closeActionModal} deal={selectedDeal} index={getActionModalIndex()} />
                    )
                }
                <Toast ref={toast} position="top-right" />
                <Details visible={visible} onHide={handleHide} deal={sideBarData} />

            </Container >
        </div >
    )
}

export default Installations;
