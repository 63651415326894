import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nextStep, updateStep4Data, updateStep4ProData, } from "../../../../../redux/actions/StepsActions";
import { Text } from "@nextui-org/react";
import "../../Step.scss";
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import CustomButton from "../../../../shared/Buttons/customButton";

interface FormData {
    description: string;
}

function Step4ClientComment() {
    const dispatch = useDispatch();
    const selectedCard = useSelector((state: any) => state.steps.selectedCards.step4);

    const handleCardSelect = (selectedData: any) => {
        dispatch(updateStep4Data(selectedData));
        dispatch(nextStep());
    };
    const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);

    useEffect(() => {
        const handleResize = () => {
            setIsXSmallScreen(window.innerWidth < 650);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const widthClass = isXSmallScreen ? "150px" : "200px";
    const formik = useFormik<FormData>({
        initialValues: {
            description: ''
        },
        validate: (data) => {
            let errors: Partial<FormData> = {};

            if (!data.description) {
                errors.description = 'Le commentaire est obligatoire';
            } else if (data.description.length > 255) {
                errors.description = 'Le commentaire ne peut pas dépasser 255 caractères';
            }
            return errors;
        },
        onSubmit: (data) => {
            dispatch(updateStep4ProData(data.description));
            dispatch(nextStep());
        }
    });
    const isFormFieldInvalid = (name: keyof FormData) => !!(formik.touched[name] && formik.errors[name]);


    const getFormErrorMessage = (name: keyof FormData) => {
        return isFormFieldInvalid(name) ? (
            <small className="p-error" style={{ marginTop: '5px' }}>{formik.errors[name]} </small>
        ) : (
            <small className="p-error" style={{ marginTop: '5px' }}>&nbsp;</small>
        );
    };
    const submit = () => {
        formik.submitForm();
    };
    return (
        <div className="custom__container"   >
            <Text className="step__title">
                Veuillez ajouter un commentaire sur l'installation
            </Text>
            <div>
                <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                    <InputTextarea
                        name="description"
                        rows={4}
                        cols={60}
                        value={formik.values.description}
                        onChange={(e) => {
                            formik.setFieldValue('description', e.target.value);
                        }}
                    />
                    {getFormErrorMessage('description')}
                    <div onClick={submit} style={{ marginTop: '15px' }}>
                        <CustomButton title="Suivant" />
                    </div>
                </form>
            </div>
        </div >
    );
}

export default Step4ClientComment;
