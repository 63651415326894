import ChangePassword from '../credentials/change-password/ChangePassword';
import CustomButton from '../shared/Buttons/customButton';
import Login from '../credentials/login/Login';
import LogoEMS from '../../assets/Logo/EMS-sans-texte.png';
import Reveal from '../animations/Reveal';
import {
  Col,
  Container,
  Row
} from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import './Home.scss';
import { Contact } from '../../entities/contact.entity';
import { saveContact } from '../../services/contacts.service';

function Home() {
  const toast = useRef<Toast | null>(null);
  const navigate = useNavigate();
  const { login } = useParams();

  const [showLoginModal, setShowLoginModal] = useState(login ? true : false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [email, setEmail] = useState<string | undefined>('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mailValid, setMailValid] = useState<boolean>(true);
  const [message, setMessage] = useState('');

  const closeLoginModal = (firstLogin?: boolean, email?: string) => {
    setShowLoginModal(false);
    if (!firstLogin && !email) {
      navigate('/');
    } else {
      setEmail(email);
      setShowChangePassword(true);
    }
  };

  const closeChangePasswordModal = (reason?: string) => {
    setShowChangePassword(false);
    if (reason === 'Success' && toast.current) {
      toast.current.show({ severity: 'success', summary: 'Mot de passe changé avec succès. Veuillez vous connecter.', life: 6000 });
    }
    navigate('/');
  };

  async function submit(): Promise<void> {
    let contact = {
      firstName,
      lastName,
      email,
      message,
      creationDate: new Date()
    } as Contact;
    await saveContact(contact)
      .then(_ => {
        toast.current?.show({ severity: 'success', summary: 'Message envoyé. Merci.', life: 6000 });
        setEmail('');
        setFirstName('');
        setLastName('');
        setMessage('');
      });
  }

  const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);

  useEffect(() => {
    const handleResize = () => {
      setIsXSmallScreen(window.innerWidth < 650);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const smallScreen = isXSmallScreen ? "ems-phone" : "ems-web";
  const structure = isXSmallScreen ? "ems-phone-structure" : "ems-web-structure";
  const cardCustom = isXSmallScreen ? "custom_div_for_card-phone" : "custom_div_for_card-web";
  const contact = isXSmallScreen ? "contact-phone" : "contact-web";

  return (
    <Container fluid>
      <div className={structure}>
        <div>
          <div className="main-container">
            <div>
              <img src={LogoEMS} alt="Logo" className="w-6 shadow-2" style={{ height: '6rem', width: '16rem' }} />
            </div>
          </div>
          <div>
            <div >
              <span className="welcome-message">
                EMS est le spécialiste de la mobilité électrique.
              </span>
            </div>
            <div className={smallScreen}>
              <span className="sub_welcome-message">
                Notre expertise couvre la vente et l'installation de bornes de recharge pour véhicules électriques, sur toutes les gammes de puissance, ainsi que la vente et la gestion des cartes de recharge. Ceci pour les clients particuliers et professionnels.
              </span>
            </div>
          </div>
        </div>

        <div className={cardCustom}>
          <h1 className={contact}>Contactez-nous</h1>
          <div className="row homeStyle" style={{ width: '100%' }}>
            <div className='col'>
              <InputText id="lastname" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Nom' />
            </div>
          </div>
          <div className="row homeStyle" style={{ width: '100%' }}>
            <div className='col'>
              <InputText id="firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='Prénom' />
            </div>
          </div>
          <div className="row homeStyle" style={{ width: '100%' }}>
            <div className='col'>
              <InputText id="mail" className={!mailValid ? 'p-invalid' : ''} value={email} onChange={(e) => {
                const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
                setEmail(e.target.value);
                setMailValid(emailRegex.test(e.target.value));
              }} placeholder='Email' />
            </div>
          </div>
          <div className="row homeStyle" style={{ width: '100%' }}>
            <div className='col'>
              <InputTextarea value={message} onChange={(e) => setMessage(e.target.value)} rows={5} cols={30} maxLength={200} placeholder='Votre message' style={{ resize: "none" }} />
            </div>
          </div>
          <div className="footer" >
            <div onClick={submit}>
              <CustomButton title="Envoyer" disabled={false} ></CustomButton>
            </div>
          </div>
        </div>


      </div>

      {
        showLoginModal && (
          <Login open={showLoginModal} onClose={closeLoginModal} />
        )
      }
      {
        showChangePassword && (
          <ChangePassword open={showChangePassword} onClose={closeChangePasswordModal} email={email} />
        )
      }
      <Toast ref={toast} position="top-right" />
    </Container >
  )
};

export default Home;