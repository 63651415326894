import { Image } from "@nextui-org/react";
import LogoEMS from "../../assets/Logo/EMS-sans-texte.png";
import WhiteLogoEMS from "../../assets/Logo/EMS-white.png";
import LogoCarLovers from "../../assets/Logo/carlovers_eclair.png"

interface LogoProps {
  isDark?: boolean;
  partner: boolean;
}

function Logo({ isDark, partner }: LogoProps) {
  return (
    <div>
      {!partner && (
        <Image
          src={isDark ? WhiteLogoEMS : LogoEMS}
          alt="Logo"
          height={95}
          width={100}
        />
      )}
      {partner && (
        <Image
          src={LogoCarLovers}
          alt="Logo"
          height={95}
          width={100}
        />
      )}
    </div>

  );
}

export default Logo;