import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nextStep, updateStep4Data, } from "../../../../redux/actions/StepsActions";
import { Text } from "@nextui-org/react";
import Maps from "../../../shared/Maps/Maps";
import "../Step.scss";
import "./customStep4.scss";
import MapAlternative from "./MapAlternative/MapAlternative";
import { FaExclamationTriangle } from "react-icons/fa";
import CustomButton from "../../../shared/Buttons/customButton";
import { AutoComplete } from "primereact/autocomplete";
import { getAddresses } from "../../../../services/address.service";

function Step4Map() {
  const dispatch = useDispatch();
  const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);

  useEffect(() => {
    const handleResize = () => {
      setIsXSmallScreen(window.innerWidth < 650);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const widthClass = isXSmallScreen ? "150px" : "20px";

  const initialCenter = {
    lat: 0,
    lng: 0
  };

  const [address, setAddress] = useState("");
  const [addressObject, setAddressObject] = useState<any>(null);
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [dropdownDisabled, setDropdownDisabled] = useState(true);
  const [fullAddress, setFullAddress] = useState("");
  const calculatedDistance = useSelector((state: any) => state.distance.calculatedDistance);
  const [validated, setValidated] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const isButtonDisabled = !address || !zipCode || !city;



  const searchAddresses = async (event: any) => {
    if (event.query.length > 3) {
      try {
        let res = await getAddresses(event.query);
        setAddresses(res.data.features.map((f: any) => f.properties));
      } catch (error) { }
    }
  };

  const handleChange = (event: any) => {
    setAddress(event.value)
    setAddressObject(typeof (event.value) !== 'string' ? event.value : null);
    setCity(event.value.city);
    setZipCode(event.value.postcode);
    setCity(event.value.city);
  }

  const runOptionB = () => {
    setShowMap(false);
    setShowInput(true);
  }
  const runOptionA = () => {
    setShowMap(true);
    setShowInput(false);
  }

  const handleValidation = () => {
    setValidated(true);
    let data = "";
    if (addressObject) {
      data = addressObject!.label + " France";
    } else {
      data = address + " France";
    }

    dispatch(updateStep4Data({ address: addressObject.name, zipCode: zipCode, city: city }));
    setFullAddress(data);
  }
  const getDistance = (distance: number) => {
    dispatch({ type: "SET_CALCULATED_DISTANCE", payload: distance.toString() + 'm' });
    dispatch(nextStep());
  }
  const isDark = useSelector((state: any) => state.theme.isDark);

  return (
    <div >

      <div className="custom__container">
        <Text className="step__title">
          Quelle est votre adresse ?
        </Text>
        {fullAddress.length === 0 &&
          <div className={isDark ? "input__container-dark" : "input__container-light"}>
            <AutoComplete value={address} field="label" suggestions={addresses} completeMethod={searchAddresses} onChange={(e) => handleChange(e)} />
          </div>
        }

        {!validated &&
          <div onClick={handleValidation}>
            <CustomButton title="Valider ma distance" />
          </div>}
      </div>

      {validated && showMap &&
        <div className="div__icon">
          <div onClick={runOptionB}>
            <CustomButton title="Saisie manuelle" />
          </div>
        </div>
      }
      {showInput && validated &&
        <div className="div__optionA">
          <div className="container__warning">
            <FaExclamationTriangle className="icon__warning" />
            <span className="span_warning" >
              La saisie sur carte est plus précise. L'option choisie ne nous permettra pas de générer un devis précis.
              Les prix affichés seront donc approximatifs, jusqu'à l'obtention de la distance exacte.
            </span>
          </div>

          <div onClick={runOptionA}>
            <CustomButton title="Carte" />
          </div>
        </div>
      }

      {showInput && <MapAlternative handleValidate={getDistance} />}
      {fullAddress.length != 0 && showMap && (<Maps address={fullAddress} defaultCenter={initialCenter} />)}
    </div>
  );
}

export default Step4Map;
