import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nextStep, updateStep3Data, } from "../../../../../redux/actions/StepsActions";
import { Text } from "@nextui-org/react";
import "../../Step.scss";
import SharedCard from "../../../../shared/SharedCard";
import yes from "../../../../../assets/quote-icons/yes.png";
import no from "../../../../../assets/quote-icons/no.png";
import { QuoteConstants } from "../../../../../constants/enum";
import { FaCheck, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

function Step3BisColiving() {
  const dispatch = useDispatch();
  const selectedCard = useSelector((state: any) => state.steps.selectedCards.step2);

  const handleCardSelect = (selectedData: any) => {
    dispatch(updateStep3Data(selectedData));
    dispatch(nextStep());
  };
  const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);

  useEffect(() => {
    const handleResize = () => {
      setIsXSmallScreen(window.innerWidth < 650);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const widthClass = isXSmallScreen ? "150px" : "200px";

  return (
    <div className="custom__container"   >
      <Text className="step__title">
        Existe-t-il déjà des bornes dans votre copropriété ?
      </Text>
      <div className="content__container">
        <div
          className={selectedCard === QuoteConstants.yes ? "card__selected" : ""}
          onClick={() => handleCardSelect(QuoteConstants.yes)}>
          <SharedCard
            title={QuoteConstants.yes}
            // image={yes}
            icon={<FaThumbsUp size="100%" className="w-6 shadow-2" />}
            width={widthClass}
            layout="square" />
        </div>
        <div
          className={selectedCard === QuoteConstants.no ? "card__selected" : ""}
          onClick={() => handleCardSelect(QuoteConstants.no)}>
          <SharedCard
            title={QuoteConstants.no}
            icon={<FaThumbsDown size="100%" className="w-6 shadow-2" />}
            // image={no}
            width={widthClass}
            layout="square" />
        </div>
      </div>
    </div>
  );
}

export default Step3BisColiving;
