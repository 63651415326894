import { Card, Text } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import stations from "../../assets/filters/stations.png"
import accessories from "../../assets/filters/accessories.png"
import cards from "../../assets/filters/cards.png"
import "./ProductsPartner.scss";
import { getProducts } from "../../services/products.service";
import { Product } from "../../entities/product.entity";
import { getImage } from "../../helper/getImage";
import { ApplyTVA } from "../../helper/tvaHelper";
import { FiSettings } from "react-icons/fi";

function ProductsPartner() {
    const [products, setProducts] = useState<Product[]>([]);
    const [showData, setShowData] = useState("");
    const [flippedCards, setFlippedCards] = useState<number[]>([]);

    const loadData = (type: string) => () => {
        setShowData(type);
    };

    useEffect(() => {
        (
            async () => {
                await getProducts()
                    .then(response => {
                        let products = response.data as Product[];
                        let productsToShow = products.filter((item) => item.extra === false);
                        setProducts(productsToShow);
                    })
                    .catch(err => console.error('Disconnected'));
            }
        )();
    }, []);

    const handleCardClick = (id: number) => {
        if (flippedCards.includes(id)) {
            setFlippedCards(flippedCards.filter(cardId => cardId !== id));
        } else {
            setFlippedCards([...flippedCards, id]);
        }
    };
    return (
        <div>
            <Container>
                <div className="card__container">

                    <div>
                        <Card css={{ w: "120px", h: "175px", background: "inherit" }} isPressable onClick={loadData("Borne")}>
                            <div className="mb-3">
                                <img src={stations} alt="Bornes" className="w-6 shadow-2" />
                            </div>
                            <div className="div__filterTitle">
                                <span className="mb-1 span__filterTitle" >Bornes</span>
                            </div>
                        </Card>
                    </div>
                    <div>
                        <Card css={{ w: "120px", h: "175px", background: "inherit" }} isPressable onClick={loadData("Accessoires")} >
                            <div className="mb-3">
                                <img src={accessories} alt="Accessoires" className="w-6 shadow-2" />
                            </div>
                            <div className="div__filterTitle">
                                <span className="mb-1 span__filterTitle" >Accessoires</span>
                            </div>
                        </Card>
                    </div>
                    <div>
                        <Card css={{ w: "120px", h: "175px", background: "inherit" }} isPressable >
                            <div className="mb-3">
                                <img src={cards} alt="Cartes" className="w-6 shadow-2" />
                            </div>
                            <div className="div__filterTitle">
                                <span className="mb-1 span__filterTitle" >Cartes</span>
                            </div>
                        </Card>
                    </div>
                </div>

            </Container>

            {showData.length !== 0 && products.length !== 0 && (
                <div className="card__container">
                    {products
                        .filter((item) => showData === 'Accessoires' ? (item.category === 'Accessoires' || item.category === 'Cable') : item.category === showData)
                        .map((stationItem) => (
                            <div key={stationItem.id} onClick={() => handleCardClick(stationItem?.id ? stationItem.id : 0)}>
                                <div className={flippedCards.includes(stationItem?.id ? stationItem.id : 0) ? "cardBack" : "cardFront"}>
                                    <div className="front">
                                        <div className="mb-3">
                                            <img src={getImage(stationItem.name)} alt={stationItem.name} className="w-6 shadow-2 image__card" />
                                        </div>
                                        <div className="div__cardText">
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "flex-start", marginLeft: "20px" }}>
                                                <span className="mb-1 span_type" >{stationItem.type}</span>
                                                <span className="mb-1 span__text">{stationItem.name}</span>
                                            </div>
                                            {/* <div>
                                                <span className="mt-2 mb-1 span__price">{ApplyTVA(stationItem.price)}  €</span>
                                            </div> */}

                                        </div>
                                    </div>

                                    <div className="back">
                                        <span className="mt-1 mb-2 span__brand">{stationItem.brand} </span>
                                        <div className="div__description">
                                            <span className="mt-1 mb-1 span__description">{stationItem.description} </span>
                                        </div>
                                        {(stationItem.chargingNorm || stationItem.currentType || stationItem.chargingType || stationItem.cableLength) &&
                                            <div className="div__technique">

                                                <span className=" mt-1 mb-1 span_subtitle"> <FiSettings /> Caractéristiques techniques</span>
                                                <div className="mb-1 row" style={{ width: "100%" }}>
                                                    <span className="col-7 span__techTitle">Norme de charge</span>
                                                    <span className="col-auto span__techDescription">{stationItem?.chargingNorm} </span>
                                                </div>
                                                <div className="mb-1 row" style={{ width: "100%" }}>
                                                    <span className="col-7 span__techTitle">Type de courant</span>
                                                    <span className="col-auto span__techDescription">{stationItem?.currentType} </span>
                                                </div>
                                                <div className="mb-1 row" style={{ width: "100%" }}>
                                                    <span className="col-7 span__techTitle">Type de charge</span>
                                                    <span className="col-auto span__techDescription">{stationItem?.chargingType} </span>
                                                </div>
                                                <div className="mb-1 row" style={{ width: "100%" }}>
                                                    <span className="col-7 span__techTitle">Longueur de câble</span>
                                                    <span className="col-auto span__techDescription">{stationItem?.cableLength}m </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}

                </div>)}

            {showData.length === 0 && products.length !== 0 && (
                <div>
                    <div className="card__container">
                        {products
                            .map((stationItem) => (
                                <div key={stationItem.id} onClick={() => handleCardClick(stationItem?.id ? stationItem.id : 0)}>
                                    <div className={flippedCards.includes(stationItem?.id ? stationItem.id : 0) ? "cardBack" : "cardFront"}>
                                        <div className="front">
                                            <div className="mb-3">
                                                <img src={getImage(stationItem.name)} alt={stationItem.name} className="w-6 shadow-2 image__card" />
                                            </div>
                                            <div className="div__cardText">
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "flex-start", marginLeft: "20px" }}>
                                                    <span className="mb-1 span_type" >{stationItem.type}</span>
                                                    <span className="mb-1 span__text">{stationItem.name}</span>
                                                </div>
                                                {/* <div>
                                                    <span className="mt-2 mb-1 span__price">{ApplyTVA(stationItem.price)}  €</span>
                                                </div> */}

                                            </div>
                                        </div>

                                        <div className="back">
                                            <span className="mt-1 mb-2 span__brand">{stationItem.brand} </span>
                                            <div className="div__description">
                                                <span className="mt-1 mb-1 span__description">{stationItem.description} </span>
                                            </div>
                                            {(stationItem.chargingNorm || stationItem.currentType || stationItem.chargingType || stationItem.cableLength) &&
                                                <div className="div__technique">

                                                    <span className=" mt-1 mb-1 span_subtitle"> <FiSettings /> Caractéristiques techniques</span>
                                                    <div className="mb-1 row" style={{ width: "100%" }}>
                                                        <span className="col-7 span__techTitle">Norme de charge</span>
                                                        <span className="col-auto span__techDescription">{stationItem?.chargingNorm} </span>
                                                    </div>
                                                    <div className="mb-1 row" style={{ width: "100%" }}>
                                                        <span className="col-7 span__techTitle">Type de courant</span>
                                                        <span className="col-auto span__techDescription">{stationItem?.currentType} </span>
                                                    </div>
                                                    <div className="mb-1 row" style={{ width: "100%" }}>
                                                        <span className="col-7 span__techTitle">Type de charge</span>
                                                        <span className="col-auto span__techDescription">{stationItem?.chargingType} </span>
                                                    </div>
                                                    <div className="mb-1 row" style={{ width: "100%" }}>
                                                        <span className="col-7 span__techTitle">Longueur de câble</span>
                                                        <span className="col-auto span__techDescription">{stationItem?.cableLength}m </span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}

                    </div>
                </div>)}
        </div>
    )
};

export default ProductsPartner;
