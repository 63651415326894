import { Text } from '@nextui-org/react';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { TabMenu } from 'primereact/tabmenu';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FiMinusCircle, FiPlus, FiTrash, FiTrash2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import {
    CircuitBreaker,
    DealStatus,
    QuoteConstants,
    Role
} from '../../../constants/enum';
import { InstallationPerDealDto } from '../../../dtos/installation-per-deal.dto';
import { PriceWithDetailDto } from '../../../dtos/price-with-details.dto';
import { ProductPerDealDto } from '../../../dtos/product-per-deal.dto';
import { ProductDto } from '../../../dtos/product.dto';
import { Client } from '../../../entities/client.entity';
import { Deal } from '../../../entities/deal.entity';
import { InstallationDetail } from '../../../entities/installation-detail.entity';
import { Product } from '../../../entities/product.entity';
import { Quote } from '../../../entities/quote.entity';
import { getImage } from '../../../helper/getImage';
import { getQuote } from '../../../helper/quoteGetter';
import { ApplyTVA, RemoveTVA } from '../../../helper/tvaHelper';
import {
    deleteInstallationsPerDeal,
    deleteProductsPerDeal,
    getInstallationsPerDealByDealId,
    getProductsPerDealByDealId,
    saveInstallationPerDeal,
    saveProductPerDeal,
    updateDeal
} from '../../../services/deals.service';
import { getInstallationDetails } from '../../../services/installation-details.service';
import { addProduct, getProducts } from '../../../services/products.service';
import { getQuotePrice } from '../../../services/quote.service';
import { createSignatureRequest } from '../../../services/yousign.service';
import '../../shared/DarkMode.scss';
import '../update/UpdateDeal.scss';
import ProfessionalUpdate from './professional/ProfessionalUpdate';
import UpdateClientForm from '../../clients/update/core/UpdateClientForm';

interface NewUpdateDealsProps {
    open: boolean;
    onClose: () => void;
    deal?: Deal;
    handleUpdate: (data: string) => void;
}

interface ExtraProduct {
    product: string;
    price: number;
    quantity: number;
    id?: number;
}

function UpdateDeal({ open, onClose, deal, handleUpdate }: NewUpdateDealsProps) {
    const loggedUser = useSelector((state: any) => state.login.loggedUser);
    const isDark = useSelector((state: any) => state.theme.isDark) as boolean;
    const [activeIndex, setActiveIndex] = useState(0);
    const [accessories, setAccessories] = useState<Product[]>([]);
    const [chargingStations, setChargingStations] = useState<Product[]>([]);
    const [installationDetails, setInstallationDetails] = useState<InstallationDetail[]>([]);
    const [productsPerDeal, setProductsPerDeal] = useState<ProductPerDealDto[]>([]);
    const [installationsPerDeal, setInstallationsPerDeal] = useState<InstallationPerDealDto[]>([]);
    const [selectedAccessories, setSelectedAccessories] = useState<Product[]>([]);
    const [showAccessories, setShowAccessories] = useState(false);
    const [newPrice, setNewPrice] = useState(deal?.amount!);
    const [client] = useState<Client | undefined>(deal?.clientId);
    // const [modifiedFields, setModifiedFields] = useState<ModifiedFieldsClient>({});
    const [dealAccessories, setDealAccesories] = useState<Product[]>([]);
    const [estimatedDistance, setEstimatedDistance] = useState<number>();
    const [discount, setDiscount] = useState<number>(deal?.discount ? deal.discount : 0);
    const [circuitBreakerType, setCircuitBreakerType] = useState<string>();
    const [selectedChargingStation, setSelectedChargingStation] = useState<Product>();
    const [priceWithDetail, setPriceWithDetail] = useState<PriceWithDetailDto>();
    const [extraProducts, setExtraProducts] = useState<ExtraProduct[]>([]);
    const [dealExtraProducts, setDealExtraProducts] = useState<ExtraProduct[]>([]);
    const [type, setType] = useState<string>();

    const items = [
        { label: 'Client', icon: 'pi pi-fw pi-user' },
        { label: 'Affaire', icon: 'pi pi-fw pi-calendar' },
    ];

    useEffect(() => {
        (
            async () => {
                await getProducts()
                    .then((response) => {
                        setAccessories(response.data.filter((p: Product) => (p.category === 'Cable' || p.category === 'Accessoires') && p.id != 2));
                        let stations = [...response.data.filter((p: Product) => p.category === 'Borne')];
                        setChargingStations(stations);
                    });

                await getInstallationDetails()
                    .then((response) => {
                        setInstallationDetails(response.data);
                    });

                await getProductsPerDealByDealId(deal?.id!)
                    .then(response => {
                        if (response.data && response.data.length) {
                            setProductsPerDeal(response.data);

                            if (!selectedChargingStation) {
                                let res = response.data.find((ppd: any) => ppd.product.category == 'Borne');
                                if (res) {
                                    setSelectedChargingStation(res.product);
                                }
                            }

                            let dealExtraProducts = response.data
                                .filter((ppd: any) => ppd.product.category === 'Extra')
                                .map((ppd: any) => { return { product: ppd.product.name, price: ppd.product.price, quantity: ppd.quantity, id: ppd.id } as ExtraProduct });
                            if (dealExtraProducts) {
                                setDealExtraProducts(dealExtraProducts);
                            }
                            formik.setFieldValue('circuitBreakerType', circuitBreakerType)
                            let accessories = productsPerDeal.filter(ppd => ppd.product.category !== 'Borne' && ppd.product.category !== 'Extra').map(ppd => ppd.product);
                            setDealAccesories(accessories);
                        }
                    })
                    .catch(err => console.error(err));

                await getInstallationsPerDealByDealId(deal?.id!)
                    .then(response => {
                        if (response.data.length) {
                            setInstallationsPerDeal(response.data);

                            if (!circuitBreakerType) {
                                let cbType = response.data[0].installationDetail.circuitBreaker;
                                setCircuitBreakerType(cbType);
                            }

                            if (!estimatedDistance) {
                                let mainInstallation = response.data.find((ipd: InstallationPerDealDto) => ipd.installationDetail.maxInstallationDistance !== null && ipd.installationDetail.minInstallationDistance !== null).installationDetail;
                                let totalDistance = mainInstallation.maxInstallationDistance;
                                if (response.data.length > 1) {
                                    let extraInstallation = response.data.find((ipd: InstallationPerDealDto) => ipd.installationDetail.maxInstallationDistance === null && ipd.installationDetail.minInstallationDistance);
                                    if (extraInstallation)
                                        totalDistance += extraInstallation.quantity;
                                }

                                let distance = totalDistance;
                                if (deal?.distance) {

                                    if (deal.distance <= 5) {
                                        distance = 5;
                                    } else if (deal.distance <= 10){
                                        distance = 10;
                                    } else if (deal.distance <= 15) {
                                        distance = 15;
                                    } else if (deal.distance <= 25) {
                                        distance = 25;
                                    } else {
                                        distance = deal.distance;
                                    }
                                }

                                setEstimatedDistance(distance);
                                formik.setFieldValue('estimatedDistance', totalDistance);
                            }
                        }
                    })
                    .catch(err => console.error(err));

                await calculateQuote();
            }
        )();
        if (deal && deal.clientId) {
            setType(deal.clientId.type)
        }

    }, [productsPerDeal.length, selectedAccessories.length, estimatedDistance, circuitBreakerType, selectedChargingStation, discount, extraProducts, extraProducts.length, dealExtraProducts.length]);

    async function calculateQuote() {
        let distance = (estimatedDistance ? estimatedDistance + 'm' : (formik.values.estimatedDistance ? formik.values.estimatedDistance + 'm' : formik.values.estimatedDistance));
        let quote: Quote = {
            typeOfPerson: deal?.clientId?.type!,
            housing: '',
            circuitBreaker: circuitBreakerType ? circuitBreakerType : formik.values.circuitBreakerType,
            distance: distance,
            dealId: deal?.id!,
            discount: discount,
        };
        let accessoriesPrice = selectedAccessories?.reduce((acc, curr) => Number(acc) + Number(curr.price), 0);
        if (quote.circuitBreaker && quote.distance) {
            await getQuotePrice(quote)
                .then((response: AxiosResponse<PriceWithDetailDto[]>) => {
                    let priceWithDetail = response.data.find(x => x.stationId === selectedChargingStation?.id);
                    setPriceWithDetail(priceWithDetail);
                    let price = 0;
                    if (priceWithDetail) {
                        price = Number(priceWithDetail?.total.price);
                        let extraProductTotal = extraProducts?.reduce((acc, curr) => Number(acc) + Number(curr.price * curr.quantity), 0);
                        price += Number(extraProductTotal);
                        if (accessoriesPrice) {
                            price += Number(accessoriesPrice);
                        }
                    }
                    setNewPrice(price);
                });
        }
    }

    const formik = useFormik<any>({
        initialValues: {
            city: client?.city ? client.city : '',
            chargingStationType: 1,
            estimatedDistance: estimatedDistance,
            circuitBreakerType: circuitBreakerType,
            siretCode: client?.siretCode ? client.siretCode : '',
            companyName: client?.companyName ? client.companyName : '',
            extraProducts: dealExtraProducts as ExtraProduct[],
        },
        validate: (data) => {
        },
        onSubmit: async (data) => {
            if (Number(deal?.amount).toFixed(2) !== newPrice.toFixed(2)) {
                await submitInstallationPerDeal();
                await submitProducts(data.extraProducts);
                await submitDeal();
            }
        }
    });

    async function submitInstallationPerDeal() {
        let installationPerDealsToSave: InstallationPerDealDto[] = [];
        let mainInstallationToSave: InstallationPerDealDto = {
            deal: deal,
            installationDetail: priceWithDetail?.detail.installation[0]!,
            quantity: 1
        };
        installationPerDealsToSave.push(mainInstallationToSave);

        if (priceWithDetail?.detail.installation.length! > 1 && priceWithDetail?.detail.installation[1]) {
            let extraInstallationToSave: InstallationPerDealDto = {
                deal: deal,
                installationDetail: priceWithDetail?.detail.installation[1],
                quantity: estimatedDistance! - 25
            };
            installationPerDealsToSave.push(extraInstallationToSave);
        }

        let oldInstallationsIds = installationsPerDeal.map(ipd => ipd.id);
        oldInstallationsIds.forEach(async (id) => {
            await deleteInstallationsPerDeal(id!);
        })

        installationPerDealsToSave.forEach(async (ipd) => {
            await saveInstallationPerDeal(ipd);
        });
    }

    /**
     * @param extraProducts 
     * Saves ExtraProducts 
     * Deletes Deal's ProductPerDeals
     * Then Add new ProductPerDeals
     * Not optimal
     */
    async function submitProducts(extraProducts: any) {
        let extraProductToSave: ProductDto[] = [];
        let productSavedPromise: Promise<any>[] = [];
        if (extraProducts.length !== 0) {
            extraProducts.forEach(async (extra: ExtraProduct) => {
                let product: ProductDto = {
                    name: extra.product,
                    price: Number(extra.price),
                    category: 'Extra',
                    type: 'Extra',
                    brand: 'Extra',
                    description: 'Extra',
                    extra: true
                };
                extraProductToSave.push(product);
            });

            extraProductToSave.forEach(async (product) => {
                productSavedPromise.push(addProduct(product));
            });

            await Promise.all(productSavedPromise).then((values) => {
                values.forEach((value) => {
                    selectedAccessories.push(value.data as Product);
                });
            })
        }

        if (productsPerDeal && productsPerDeal.length > 1) {
            let indexToEdit = productsPerDeal.findIndex(ppd => ppd.product.id == 2);
            if( productsPerDeal[indexToEdit]?.quantity){
                productsPerDeal[indexToEdit].quantity = circuitBreakerType === CircuitBreaker.Mono ? 1 : 3;
            }

            let oldProductIds = productsPerDeal.map(ipd => ipd.id);
            oldProductIds.forEach(async (id) => {
                await deleteProductsPerDeal(id!);
            });
        }

        priceWithDetail?.detail.products.forEach(async p => {
            let quantity = 1;
            let extraProduct = extraProducts.find((ep: ExtraProduct) => ep.product === p.name && ep.price === p.price);
            if (extraProduct) {
                quantity = extraProduct.quantity;
            }
            let productPerDeal: ProductPerDealDto = {
                deal: deal,
                product: p,
                quantity: quantity
            };
            await saveProductPerDeal(productPerDeal);
        });

        selectedAccessories.forEach(async (acc) => {
            let productPerDeal: ProductPerDealDto = {
                deal: deal,
                product: acc,
                quantity: 1
            };
            await saveProductPerDeal(productPerDeal);
        });
    }

    async function submitDeal() {
        let savedDeal: Deal;
        deal!.amount = newPrice;
        deal!.distance = estimatedDistance;
        if (discount) {
            deal!.discount = discount;
        }
        deal!.updateDate! = new Date();
        deal!.updatedBy = loggedUser?.email;
        deal!.status = loggedUser.role === Role.EMSAdmin ? DealStatus.ToSign : DealStatus.ToEvaluate;
        await updateDeal(deal!).then(async (value) => {
            savedDeal = value.data as Deal;
            if (savedDeal.status === DealStatus.ToSign) {
                try {
                    let quote = await getQuote(savedDeal);
                    if (savedDeal) {
                        await createSignatureRequest(quote);
                        handleUpdate("successful update");
                    } else {
                        handleUpdate("failed update");
                    }
                } catch (error) {
                    console.error(error);
                }
            }
            onClose();
            window.location.reload();
            formik.resetForm();
        })
    }

    const accessoryTemplate = (option: any) => {
        return (
            <div className="flex align-items-center" key={option.id}>
                <div>
                    <span style={{ fontWeight: '500' }}>{option.brand} </span>
                    <span>{option.name} </span>
                    <span>{ApplyTVA(option.price, deal?.clientId?.type)}€</span>
                </div>
            </div>
        );
    };

    const distanceRadioButtons = [
        {
            id: 'five',
            name: 'distance',
            value: '- de 5m',
            inputId: '5'
        },
        {
            id: 'ten',
            name: 'distance',
            value: '- de 10m',
            inputId: '10'
        },
        {
            id: 'fifteen',
            name: 'distance',
            value: '- de 15m',
            inputId: '15'
        },
        {
            id: 'twentyfive',
            name: 'distance',
            value: '- de 25m',
            inputId: '25'
        },
        {
            id: 'twentyfiveplus',
            name: 'distance',
            value: '+ de 25m',
            inputId: '26'
        }
    ];

    const circuitBreakerRadioButtons = [
        {
            id: 'mono',
            value: 'Monophasé',
            apivalue: CircuitBreaker.Mono
        },
        {
            id: 'tri',
            value: 'Triphasé',
            apivalue: CircuitBreaker.Tri
        },
    ];

    const handleAddProduct = () => {
        const updatedExtraProducts = [...formik.values.extraProducts, { product: '', price: 0, quantity: 1 }];
        formik.setFieldValue('extraProducts', updatedExtraProducts);
    };

    function handleInputChange(index: number, fieldName: keyof ExtraProduct, value: any) {
        const updatedExtraProducts = [...formik.values.extraProducts];
        updatedExtraProducts[index][fieldName] = value;
        formik.setFieldValue('extraProducts', updatedExtraProducts);
        setExtraProducts(updatedExtraProducts)
    };

    async function deleteExtra(extraProduct: ExtraProduct) {
        await deleteProductsPerDeal(extraProduct.id!);
        setDealExtraProducts(dealExtraProducts.filter(product => product.id != extraProduct.id));
        calculateQuote();
        await submitDeal();
    }

    function deleteNewExtra(index: number) {
        formik.values.extraProducts.splice(index, 1);
        formik.setFieldValue('extraProducts', formik.values.extraProducts);
    }

    return (
        <Dialog
            showHeader={false}
            visible={open} onHide={onClose}
            closable={true}
            closeOnEscape={true}
            dismissableMask={true}
            style={{ width: '50vw', padding: "0px", borderRadius: "18px" }}
            className={isDark ? 'modal-dark' : 'modal-light'}
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            contentStyle={{
                padding: '0px', borderRadius: "18px", background: isDark ? "#2a323d" : "#fff",
                color: isDark ? "rgba(255,255,255,.87)" : "#495057"
            }}>
            <div className="modal-body" style={{ padding: "10px", minHeight: "16rem" }}>
                <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} style={{ width: "100%" }} />
                <Container>
                    <form onSubmit={formik.handleSubmit} >
                        {
                            activeIndex === 0 &&
                            <div>
                                <UpdateClientForm client={client} submit={() => { }} cancel={() => { }}></UpdateClientForm>
                            </div>
                        }
                        {
                            activeIndex === 1 && deal && type === QuoteConstants.professional &&
                            <div>
                                <ProfessionalUpdate deal={deal} chargingStations={chargingStations} accessories={accessories}></ProfessionalUpdate>
                            </div>
                        }
                        {activeIndex === 1 && deal && type === QuoteConstants.individual &&
                            (
                                <div>
                                    {deal?.comment !== null && deal?.comment !== '' && (
                                        <div className="div__comment">
                                            <span className="span__titleComment">Commentaire de l'installateur</span>
                                            <span className="span__commentDescription">{deal?.comment}</span>
                                        </div>
                                    )

                                    }
                                    <Container>
                                        <Accordion >
                                            <AccordionTab header="Type de borne">
                                                {chargingStations?.map((cs) => {
                                                    return (
                                                        <Container className="mb-2" key={cs.id}>
                                                            <div >
                                                                <Row onClick={() => {
                                                                    setSelectedChargingStation(cs)
                                                                }}
                                                                    className={selectedChargingStation?.id === cs.id ? 'row__selected' : ''}
                                                                >
                                                                    <Col xs={10} md={10}>
                                                                        <Row>
                                                                            <Col><Text size={14} style={{ float: 'left', margin: '0' }}>{cs.type}</Text></Col>
                                                                            <Col>
                                                                                <Text size={14} style={{ float: 'right', margin: '0', fontWeight: "500", letterSpacing: "0.3px" }}>{cs.brand}</Text>
                                                                            </Col>

                                                                        </Row>
                                                                        <Row>
                                                                            <Col></Col>
                                                                            <Col><Text size={14} style={{ float: 'right', margin: '0' }}>{cs.name}</Text></Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col xs={2} md={2}>
                                                                        <img src={getImage(cs.name)} alt={cs.name} className="w-6 shadow-2" width={50} height={50} />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Container>
                                                    )
                                                })}
                                            </AccordionTab>
                                            <AccordionTab header="Accessoires">
                                                {dealAccessories?.map((acc) => {
                                                    return (
                                                        <div style={{ padding: '5px 0' }} key={acc.id}>
                                                            <Row>
                                                                <Col><Text size={14} style={{ float: 'left', margin: '0' }}>{acc.type}</Text></Col>
                                                                <Col><Text size={14} style={{ float: 'right', margin: '0', fontWeight: "500", letterSpacing: "0.3px" }}>{acc.brand}</Text></Col>
                                                            </Row>
                                                            <Row>
                                                                <Col></Col>
                                                                <Col><Text size={14} style={{ float: 'right', margin: '0' }}>{acc.name}</Text></Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                })}
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <hr style={{ width: '70%' }}></hr>
                                                </div>
                                                {selectedAccessories?.map((acc) => {
                                                    return (
                                                        <div style={{ padding: '5px 0' }} key={acc.id}>
                                                            <Row>
                                                                <Col><Text size={14} style={{ color: '#4338CA', float: 'left', margin: '0' }}>{acc.type}</Text></Col>
                                                                <Col><Text size={12} style={{ color: '#4338CA', float: 'right', margin: '0', fontWeight: "500" }}>{acc.brand}</Text></Col>
                                                            </Row>
                                                            <Row>
                                                                <Col></Col>
                                                                <Col><Text size={12} style={{ color: '#4338CA', float: 'right', margin: '0' }}>{acc.name}</Text></Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                })}
                                                <div>
                                                    <Row className="align-items-center">
                                                        <Col className="multiselct__accessories">
                                                            {showAccessories ?
                                                                <MultiSelect appendTo="self" value={selectedAccessories} onChange={(e: MultiSelectChangeEvent) => {
                                                                    setSelectedAccessories(e.value);
                                                                }}
                                                                    onHide={() => setShowAccessories(false)}
                                                                    options={accessories} itemTemplate={accessoryTemplate} placeholder="Accessoires"
                                                                    maxSelectedLabels={0} className="w-full md:w-20rem"
                                                                    style={{ float: 'left', margin: '0', width: '100%' }}
                                                                    panelStyle={{ fontSize: '10px' }} />

                                                                :
                                                                <Button onClick={() => setShowAccessories(!showAccessories)} severity="info" style={{ float: 'left', margin: '0' }} text tooltip="Ajouter des accessoires" tooltipOptions={{ position: 'top', baseZIndex: 9999 }}>
                                                                    <FiPlus></FiPlus>
                                                                </Button>
                                                            }
                                                        </Col>
                                                        {selectedAccessories && selectedAccessories.length > 0 && (
                                                            <Col>
                                                                <Text weight={'medium'} size={14} style={{ color: '#4338CA', float: 'right', letterSpacing: '0.1px', marginTop: '10px' }}>Total: {ApplyTVA(selectedAccessories?.reduce((acc, curr) => Number(acc) + Number(curr.price), 0), deal?.clientId?.type)} € (TTC)</Text>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </div>
                                            </AccordionTab>
                                            <AccordionTab header="Distance">
                                                <Row>
                                                    <Col>
                                                        <div className="radioButton__distance" >
                                                            {distanceRadioButtons.map((btn, i) => {
                                                                return (
                                                                    <div key={i} className="item-spacing">
                                                                        <RadioButton
                                                                            {...btn}
                                                                            checked={formik.values.estimatedDistance === Number(btn.inputId)}
                                                                            onChange={() => {
                                                                                formik.setFieldValue('estimatedDistance', Number(btn.inputId));
                                                                                setEstimatedDistance(Number(btn.inputId));
                                                                            }}
                                                                        />
                                                                        <label htmlFor={btn.inputId} className="ml-1" style={{ fontSize: '14px' }}>
                                                                            {btn.value}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            }
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {estimatedDistance! > 25 && (
                                                    <Row>
                                                        <Col>
                                                            <Text size={13} color="#6A6A6A" weight={'medium'} style={{ marginBottom: '0px', letterSpacing: '0.01px' }}>Veuillez préciser la distance</Text>
                                                            <InputNumber id="distance2" min={26} max={200} value={estimatedDistance} showButtons
                                                                onValueChange={(e: InputNumberValueChangeEvent) => setEstimatedDistance(e.value!)} />
                                                        </Col>
                                                    </Row>
                                                )}
                                            </AccordionTab>
                                            <AccordionTab header="Type de disjoncteur">
                                                <div className="radioButton__distance" >
                                                    {circuitBreakerRadioButtons.map((btn, i) => {
                                                        return (
                                                            <div key={i} className="item-spacing">
                                                                <RadioButton
                                                                    {...btn}
                                                                    checked={formik.values.circuitBreakerType === btn.apivalue}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue('circuitBreakerType', btn.apivalue);
                                                                        setCircuitBreakerType(btn.apivalue);
                                                                    }}
                                                                />
                                                                <label htmlFor={btn.id} className="ml-1" style={{ fontSize: '14px' }}>
                                                                    {btn.value}
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </AccordionTab>
                                            {loggedUser.role === Role.EMSAdmin &&
                                                <AccordionTab header="Demande installateur">
                                                    <div className="div__extra">
                                                        <Button type="button" text onClick={handleAddProduct} style={{ width: 'fit-content' }}>
                                                            <div>
                                                                <FiPlus style={{ fontSize: '12px', fontWeight: '500' }} />
                                                                <span style={{ fontSize: '14px' }}> Ajouter un produit</span>
                                                            </div>
                                                        </Button>
                                                    </div>
                                                    {
                                                        dealExtraProducts &&
                                                        dealExtraProducts.map((product: ExtraProduct, index: number) => (
                                                            <div key={product.id} className="extraProducts__div">
                                                                <div className="product__div">
                                                                    <div style={{ width: '10%' }}>
                                                                        <span className="title__extra">Produit</span>
                                                                    </div>
                                                                    <div style={{ width: '30%' }}>
                                                                        <InputText
                                                                            value={product.product}
                                                                            disabled
                                                                            onChange={(e) => product.product = e.target.value}
                                                                            style={{ width: '100%' }}
                                                                            tabIndex={-1}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: '10%' }}>
                                                                        <span className="title__extra">Prix (HT)</span>
                                                                    </div>
                                                                    <div style={{ width: '30%' }}>
                                                                        <InputNumber
                                                                            value={product.price}
                                                                            disabled
                                                                            onChange={(e) => product.price = e.value!}
                                                                            size={6}
                                                                            style={{ width: '100%' }}
                                                                            min={0}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: '15%' }}>
                                                                        <InputNumber
                                                                            value={product.quantity}
                                                                            disabled
                                                                            onChange={(e) => product.quantity = e.value!}
                                                                            mode="decimal" showButtons min={0} max={10}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: '5%', cursor: 'pointer' }} onClick={() => deleteExtra(product)}>
                                                                        <FiMinusCircle size={20}></FiMinusCircle>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    {formik.values.extraProducts.map((_: ExtraProduct, index: number) => (
                                                        <div key={index} className="extraProducts__div">
                                                            <div className="product__div">
                                                                <div style={{ width: '10%' }}>
                                                                    <span className="title__extra">Produit</span>
                                                                </div>
                                                                <div style={{ width: '30%' }}>
                                                                    <InputText
                                                                        id={`product-${index}-product`}
                                                                        name={`extraProducts[${index}].product`}
                                                                        value={formik.values.extraProducts[index]?.product || ''}
                                                                        onChange={(e) => handleInputChange(index, 'product', e.target.value)}
                                                                        style={{ width: '100%' }}
                                                                        tabIndex={-1}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '10%' }}>
                                                                    <span className="title__extra">Prix (HT)</span>
                                                                </div>
                                                                <div style={{ width: '30%' }}>
                                                                    <InputNumber
                                                                        id={`product-${index}-price`}
                                                                        name={`extraProducts[${index}].price`}
                                                                        value={formik.values.extraProducts[index]?.price || ''}
                                                                        onChange={(e) => handleInputChange(index, 'price', e.value)}
                                                                        size={6}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '15%' }}>
                                                                    <InputNumber
                                                                        id={`product-${index}-quantity`}
                                                                        name={`extraProducts[${index}].quantity`}
                                                                        value={formik.values.extraProducts[index]?.quantity || ''}
                                                                        onChange={(e) => handleInputChange(index, 'quantity', e.value)}
                                                                        mode="decimal" showButtons min={0} max={10}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '5%', cursor: 'pointer' }} onClick={() => deleteNewExtra(index)}>
                                                                    <FiMinusCircle size={20}></FiMinusCircle>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </AccordionTab>
                                            }
                                            {loggedUser.role === Role.EMSAdmin &&
                                                <AccordionTab header="Remise">
                                                    <Row>
                                                        <Col>
                                                            <Text size={13} color="#6A6A6A" weight={'medium'} style={{ marginBottom: '0px', letterSpacing: '0.01px' }}>Appliquer une remise (TTC)</Text>
                                                            <InputNumber id="discount" min={0} value={discount} mode="currency" currency="EUR" locale="fr-FR"
                                                                onValueChange={(e: InputNumberValueChangeEvent) => setDiscount(e.value!)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {discount != 0 &&
                                                        <Row>
                                                            <Col>
                                                                <Text size={13} color="#6A6A6A" weight={'medium'} style={{ marginBottom: '0px', letterSpacing: '0.01px' }}>{RemoveTVA(discount!, deal?.clientId?.type)}€ (HT)</Text>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </AccordionTab>
                                            }
                                        </Accordion>
                                    </Container>
                                    <Container>
                                        <Row className="justify-content-left">
                                            <Col>
                                                <Row>
                                                    <Col> <Text size={14}>Prix initial: {ApplyTVA(deal?.amount!, deal?.clientId?.type)} € (TTC)</Text></Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                {
                                                    Number(newPrice).toFixed(2) != Number(deal?.amount).toFixed(2) && (
                                                        <Row>
                                                            <Col> <Text b size={14}>Nouveau total: {ApplyTVA(newPrice, deal?.clientId?.type)} € (TTC)</Text></Col>
                                                        </Row>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container>
                                        <div style={{ marginBottom: "6px" }}>
                                            <Button
                                                style={{
                                                    backgroundColor: "lightgrey",
                                                    color: "black",
                                                    borderColor: "lightgrey",
                                                    padding: "10px",
                                                    marginRight: "10px",
                                                    fontSize: "14px"
                                                }}
                                                aria-labelledby="annuler" label="Annuler" onClick={onClose} />
                                            <Button
                                                style={{
                                                    backgroundColor: "#3EFFFA",
                                                    color: "black",
                                                    borderColor: "#3EFFFA",
                                                    padding: "10px",
                                                    fontSize: "14px"
                                                }}
                                                aria-labelledby="valider" type="submit" label="Valider"
                                                onClick={(e) => (e.persist(), formik.setFieldValue('submittedFor', 'deal'))} />
                                        </div>
                                    </Container>
                                </div>
                            )}
                    </form>
                </Container >
            </div >
        </Dialog >
    );
}

export default UpdateDeal;