import React from "react";


function ChargingStations() {
  return (
    <div>
      <h1>Comment choisir sa borne ?</h1>
      <p style={{ fontSize: "20px" }}>
      Under construction
      </p>
    </div>
  )
};

export default ChargingStations;
