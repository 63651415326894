function Private() {
  return (
    <div>
      <h1>Particulier</h1>
      <p style={{ fontSize: "20px" }}>
        Under construction
      </p>
    </div>
  )
}

export default Private;
