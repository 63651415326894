import { Modal, Text } from "@nextui-org/react";
import { Col, Container, Row } from "react-bootstrap";
import { FiMail, FiPhoneCall } from "react-icons/fi";
import { User } from "../../entities/user.entity";
import { getRole } from "../../helper/roleGetter";

interface UserProfileProps {
    open: boolean;
    onClose: any;
    loggedUser: User;
}

function UserProfile({ open, onClose, loggedUser }: UserProfileProps) {
    return (<Modal closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        width="500px"
        blur>
        <Modal.Header>
            <Text id="modal-title" size={18} b>
                {loggedUser?.firstName} {loggedUser?.lastName}
            </Text>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    <Col xs={8} md={8}><Text size={18} b>{loggedUser?.partner?.name}</Text></Col>
                    <Col xs={4} md={4}><Text size={18} b>{getRole(loggedUser?.role)}</Text></Col>
                </Row>
                <Row>
                    <Col xs={1} md={1}><FiPhoneCall></FiPhoneCall></Col>
                    <Col><Text>{loggedUser.phone}</Text></Col>
                </Row>
                <Row>
                    <Col xs={1} md={1}><FiMail></FiMail></Col>
                    <Col><Text>{loggedUser?.email}</Text></Col>
                </Row>
            </Container>
        </Modal.Body>
    </Modal>);
};

export default UserProfile;