import { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import { Modal, Input, Button, Text, Dropdown } from "@nextui-org/react";
import './SignIn.scss';
import { FiMail, FiPhone, FiUser } from "react-icons/fi";
import { getPartners } from "../../../services/partners.service";
import { Partner } from "../../../entities/partner.entity";
import { registerv2 } from "../../../services/auth.service";
import { RegisterV2Dto } from "../../../dtos/auth.dto";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
import { getRole } from "../../../helper/roleGetter";

interface LoginProps {
  visible: boolean;
  onClose: (data?: string) => void;
}

interface Form {
  lastname: string;
  firstname: string;
  username: string;
  phone: string;
  email: string;
}

function SignIn({ visible, onClose }: LoginProps) {
  const loggedUser = useSelector((state: any) => state.login.loggedUser);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [input, setInput] = useState<Form>({
    lastname: '',
    firstname: '',
    username: '',
    phone: '',
    email: '',
  });

  const [selectedPartner, setSelectedPartner] = useState<Partner | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>('Commercial');

  const [error, setError] = useState<Form>({
    lastname: '',
    firstname: '',
    username: '',
    phone: '',
    email: ''
  });

  const loadPartners = async () => {
    await getPartners()
      .then(response => {
        setPartners(response.data.filter((partner: Partner) => partner.subPartners.length == 0));
      })
      .catch(err => console.error('Disconnected'))
      .finally(() => setSelectedPartner(partners[0]));
  };

  useEffect(() => {
    (
      async () => {
        await loadPartners();
      }
    )();
  }, [partners.length]);

  function onInputChange(event: any) {
    const { name, value } = event.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(event);
  }

  function selectPartner(event: any) {
    let partnerId = Array.from(event)[0] as number;
    const newPartner = partners.find(partner => partner.id == partnerId);
    setSelectedPartner(newPartner);
  }

  function selectRole(event: any) {
    let role = Array.from(event)[0] as string;
    setSelectedRole(role);
  }

  function validateInput(event: BaseSyntheticEvent) {
    let { name, value } = event.target;
    setError(prev => {
      const stateObj: Form = { ...prev, [name]: '' };
      switch (name) {
        case "lastname":
          if (!value) {
            stateObj[name as keyof Form] = "Veuillez entrer un nom.";
          }
          break;

        case "firstname":
          if (!value) {
            stateObj[name as keyof Form] = "Veuillez entrer un prénom.";
          }
          break;

        case "username":
          if (!value) {
            stateObj[name as keyof Form] = "Veuillez entrer un nom d'utilisateur.";
          }
          break;

        case 'phone':
          if (!value) {
            stateObj[name as keyof Form] = "Veuillez entrer un numéro de téléphone.";
          } else {
            if (!value.match(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gmi)) {
              stateObj[name as keyof Form] = "Veuillez entrer une numéro de téléphone valide.";
            }
          }
          break;
        case 'email':
          if (!value) {
            stateObj[name as keyof Form] = "Veuillez entrer une adresse mail.";
          } else {
            if (!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i)) {
              stateObj[name as keyof Form] = "Veuillez entrer une adresse mail valide.";
            }
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  }

  function canSubmit(): boolean {
    let res = input.email != ''
      && input.firstname != ''
      && input.lastname != ''
      && input.phone != ''
      && /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gmi.test(input.phone)
      && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(input.email);
    return res;
  }

  const toast = useRef<Toast | null>(null);

  function submit() {

    const registerDto: RegisterV2Dto = {
      email: input.email,
      firstName: input.firstname,
      lastName: input.lastname,
      phone: input.phone,
      role: selectedRole,
      partner: selectedPartner,
      creationDate: new Date(),
      updateDate: new Date(),
      createdBy: loggedUser.email,
      updatedBy: loggedUser.email,
      firstLogin: true
    };

    registerv2(registerDto)
      .then(() => {
        onClose('Success');
      })
      .catch(error => {
        if (toast.current) {
          toast.current.show({ severity: 'error', summary: error.response.data.message, life: 6000 });
        }
      });
  }

  function selectableRoles(): string[] {
    switch (loggedUser.role) {
      case 'Direction':
        return ['Commercial', 'Direction'];
      case 'LSAdmin':
        return ['Commercial', 'Direction', 'LSAdmin'];
      case 'EMSAdmin':
        return ['Commercial', 'Direction', 'LSAdmin', 'EMSAdmin', 'Installer', 'InstallerManager'];
      case 'InstallerManager':
        return ['Installer'];
      default:
        return [];
    }
  }

  function closeHandler() {
    onClose();
  }

  return (
    <div>
      <Modal
        closeButton
        blur
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
        width="450px"
      >
        <Modal.Header>
          <Text b id="modal-title" size={18}>
            Créer un compte EMS
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Input
            aria-label='lastname'
            name='lastname'
            clearable
            bordered
            fullWidth
            color="primary"
            size="lg"
            placeholder="Nom"
            contentLeft={<FiUser />}
            onChange={onInputChange}
            onBlur={validateInput}
          />
          {error.lastname && <small className='err'>{error.lastname}</small>}
          <Input
            aria-label='firstname'
            name='firstname'
            clearable
            bordered
            fullWidth
            color="primary"
            size="lg"
            placeholder="Prénom"
            contentLeft={<FiUser />}
            onChange={onInputChange}
            onBlur={validateInput}
          />
          {error.firstname && <small className='err'>{error.firstname}</small>}
          <Input
            aria-label='phone'
            name='phone'
            clearable
            bordered
            fullWidth
            color="primary"
            size="lg"
            placeholder="Téléphone"
            contentLeft={<FiPhone />}
            onChange={onInputChange}
            onBlur={validateInput}
          />
          {error.phone && <small className='err'>{error.phone}</small>}
          <Input
            aria-label='email'
            name='email'
            clearable
            bordered
            fullWidth
            color="primary"
            size="lg"
            placeholder="Email"
            contentLeft={<FiMail size={20} />}
            onChange={onInputChange}
            onBlur={validateInput}
          />
          {error.email && <small className='err'>{error.email}</small>}
          <Dropdown>
            <Dropdown.Button light>{selectedPartner?.name}</Dropdown.Button>
            <Dropdown.Menu
              aria-label="Concession"
              selectionMode="single"
              onSelectionChange={(e) => { selectPartner(e) }}>
              {
                partners.map(partner => {
                  return <Dropdown.Item key={partner.id}>{partner.name}</Dropdown.Item>
                })
              }
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Button light>{selectedRole}</Dropdown.Button>
            <Dropdown.Menu
              aria-label="Concession"
              selectionMode="single"
              onSelectionChange={(e) => { selectRole(e) }}>
              {
                selectableRoles().map(role => {
                  return <Dropdown.Item key={role}>{getRole(role)}</Dropdown.Item>
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button onPress={submit} auto disabled={!canSubmit()} >
            Créer l'utilisateur
          </Button>
        </Modal.Footer>
        <Toast ref={toast} position="top-right" />
      </Modal>

    </div>
  );
}
export default SignIn;
