import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nextStep, updateStep2Data, } from "../../../../../redux/actions/StepsActions";
import { Text } from "@nextui-org/react";
import "../../Step.scss";
import SharedCard from "../../../../shared/SharedCard";
import house from "../../../../../assets/quote-icons/house.png";
import appartment from "../../../../../assets/quote-icons/appartment.png";
import { QuoteConstants } from "../../../../../constants/enum";
import { FaHouse } from "react-icons/fa6";
import { FaBuilding } from "react-icons/fa";

function Step2() {
  const dispatch = useDispatch();
  const selectedCard = useSelector(
    (state: any) => state.steps.selectedCards.step2
  );

  const handleCardSelect = (selectedData: any) => {
    dispatch(updateStep2Data(selectedData));
    dispatch(nextStep());
  };
  const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);

  useEffect(() => {
    const handleResize = () => {
      setIsXSmallScreen(window.innerWidth < 650);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const widthClass = isXSmallScreen ? "150px" : "200px";

  return (
    <div className="custom__container">
      <Text className="step__title"> Votre client vit dans ? </Text>
      <div className="content__container">
        <div
          onClick={() => handleCardSelect(QuoteConstants.house)}
          className={selectedCard === QuoteConstants.house ? "card__selected" : ""}>
          <SharedCard
            title={QuoteConstants.house}
            // image={house}
            icon={<FaHouse size="100%" className="w-6 shadow-2" />}
            width={widthClass}
            layout="square" />
        </div>
        <div
          onClick={() => handleCardSelect(QuoteConstants.appartment)}
          className={selectedCard === QuoteConstants.appartment ? "card__selected" : ""}>
          <SharedCard
            title={QuoteConstants.appartment}
            // image={appartment}
            icon={<FaBuilding size="100%" className="w-6 shadow-2" />}
            width={widthClass}
            layout="square" />
        </div>
      </div>
    </div>
  );
}

export default Step2;
