import AppleImageImport from "./AppleImage.png";
import { Image } from "@nextui-org/react";

function AppleImage() {
    return (
        <div className="divImageApple">
            <Image
                src={AppleImageImport}
                alt="Logo"
                width={175}
            />
        </div>

    );
}

export default AppleImage;