const initialState = {
  step: 1,
  step1Data: null,
  step2Data: null,
  step3Data: null,
  step4Data: null,
  step4ProData: null,
  step5Data: null,
  step5DeltaData: null,
  step6Data: null,
  selectedCards: {
    step1: null,
    step2: null,
    step3: null,
    step4: null,
    step5: null,
    step5Delta: null,
    step6: null,
  },
  calculatedDistance: null,
};

export const stepReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "NEXT_STEP":
      return { ...state, step: state.step + 1 };
    case "PREVIOUS_STEP":
      return { ...state, step: state.step - 1 };
    case "UPDATE_STEP1_DATA":
      return {
        ...state,
        step1Data: action.payload,
        selectedCards: {
          ...state.selectedCards,
          step1: action.payload,
        },
      };
    case "UPDATE_STEP2_DATA":
      return {
        ...state,
        step2Data: action.payload,
        selectedCards: {
          ...state.selectedCards,
          step2: action.payload,
        },
      };
    case "UPDATE_STEP3_DATA":
      return {
        ...state,
        step3Data: action.payload,
        selectedCards: {
          ...state.selectedCards,
          step3: action.payload,
        },
      };
    case "UPDATE_STEP4_DATA":
      return {
        ...state,
        step4Data: action.payload,
        selectedCards: {
          ...state.selectedCards,
          step4: action.payload,
        },
      };
    case "UPDATE_STEP4_PRO_DATA":
      return {
        ...state,
        step4ProData: action.payload,
        selectedCards: {
          ...state.selectedCards,
          step4: action.payload,
        },
      };
    case "UPDATE_STEP5_DATA":
      return {
        ...state,
        step5Data: action.payload,
        selectedCards: {
          ...state.selectedCards,
          step5: action.payload,
        },
      };
    case "UPDATE_STEP5_DELTA_DATA":
      return {
        ...state,
        step5DeltaData: action.payload,
        selectedCards: {
          ...state.selectedCards,
          step5Delta: action.payload,
        },
      };
    case "UPDATE_STEP6_DATA":
      return {
        ...state,
        step6Data: action.payload,
        selectedCards: {
          ...state.selectedCards,
          step5: action.payload,
        },
      };
    case "RESET_FIELDS":
      return initialState;
    default:
      return state;
  }
};


export const distanceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_CALCULATED_DISTANCE":
      return {
        ...state,
        calculatedDistance: action.payload,
      };
    case "RESET_FIELDS":
      return initialState;
    default:
      return state;
  }
};
