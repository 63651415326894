import axios from 'axios';
import { Client } from '../entities/client.entity';

const API_URL = process.env.REACT_APP_API_URL;
const test = process.env;

export function getClients() {
    return axios.get(API_URL + 'clients', { withCredentials: true });
};
export async function saveClientService(client: Client) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post(API_URL + 'clients', client, { withCredentials: true, headers: customHeaders });
}
export async function updateClientService(client: Client) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };

    return await axios.put(API_URL + 'clients', client, { withCredentials: true, headers: customHeaders });
}
