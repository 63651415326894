import axios from "axios";
import { MailDto } from "../dtos/mail.dto";

const API_URL = process.env.REACT_APP_API_URL;

export async function sendMail(mail: MailDto) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post(API_URL + 'mail', mail, { withCredentials: true, headers: customHeaders });
}