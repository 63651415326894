// import { useAnimation } from "framer-motion";
import React, { Children, useEffect, useRef, useState } from "react";
import { Deal } from "../../../../entities/deal.entity";
import { Button } from "primereact/button";
import { useFormik } from 'formik';
import { FiPlus } from "react-icons/fi";
import { InputText } from "primereact/inputtext";
import '../../update/UpdateDeal.scss';
import './ProfessionalUpdate.scss';
import { Product } from "../../../../entities/product.entity";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";

interface UpdateProps {
    deal: Deal;
    chargingStations: Product[];
    accessories: Product[];

}

interface ProfessionalProducts {
    product: string;
    quantity: number;
    brand: string;
    price: string;
    type: string
    list: Product[];
}



function ProfessionalUpdate({ deal, chargingStations, accessories }: UpdateProps) {

    const type = [
        { id: '1', type: 'Borne' },
        { id: '2', type: 'Accessoire' },
    ];

    const formik = useFormik<any>({
        initialValues: {
            products: [{
                product: '',
                brand: '',
                quantity: null,
                price: null,
                type: '',
                list: []
            }],
        },


        onSubmit: async (data) => {
            console.log("confirming")
            // formik.resetForm();
        },
    });

    useEffect(() => {
    })

    const handleAddProduct = () => {
        const updatedProducts = [...formik.values.products, { product: '', quantity: '', type: '', price: null }];
        formik.setFieldValue('products', updatedProducts);
    };

    const handleInputChange = (index: number, fieldName: keyof ProfessionalProducts, value: string) => {
        const updatedProducts = [...formik.values.products];
        updatedProducts[index][fieldName] = value;

        formik.setFieldValue('products', updatedProducts);
    };

    const handleDropdownChange = (index: number, fieldName: keyof ProfessionalProducts, value: any) => {
        const updatedProducts = [...formik.values.products];
        updatedProducts[index][fieldName] = value;
        if (fieldName !== 'type') {
            updatedProducts[index].brand = value.brand;
            updatedProducts[index].price = value.price;
        } else {
            if (value.type === 'Accessoire') {
                updatedProducts[index].list = accessories;
            } else {
                updatedProducts[index].list = chargingStations;
            }


        }
        formik.setFieldValue('products', updatedProducts);
    };

    const accessoryTemplate = (option: any) => {
        return (
            <div className="flex align-items-center" key={option.id}>
                <div>
                    <span style={{ fontWeight: '500' }}>{option.brand} </span>
                    <span>{option.name} </span>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="div__extra">
                <Button type="button" text onClick={handleAddProduct}>
                    <div>
                        <FiPlus style={{ fontSize: '12px', fontWeight: '500' }} />
                        <span style={{ fontSize: '14px' }}> Ajouter un produit</span>
                    </div>
                </Button>
            </div>

            {formik.values.products.map((product: ProfessionalProducts, index: number) => (
                <div key={index} >
                    <div className="row custom__multiselect" style={{ marginBottom: "5px" }}>
                        <div className="col-2 div__section">
                            <Dropdown
                                value={formik.values.products[index]?.type}
                                options={type}
                                onChange={(e) => handleDropdownChange(index, 'type', e.value)}
                                id={`product-${index}-type`}
                                name={`products[${index}].type`}
                                appendTo="self"
                                style={{ float: 'left', margin: '0', width: '100%' }}
                                panelStyle={{ fontSize: '10px' }}
                                optionLabel="type"
                                placeholder="Type"
                            />

                        </div>
                        <div className="col-6 div__section" style={{ paddingLeft: "0px" }}>
                            <Dropdown
                                value={formik.values.products[index]?.product}
                                options={formik.values.products[index]?.list}
                                onChange={(e) => handleDropdownChange(index, 'product', e.value)}
                                id={`product-${index}-product`}
                                name={`products[${index}].product`}
                                appendTo="self"
                                itemTemplate={accessoryTemplate}
                                style={{ float: 'left', margin: '0', width: '100%' }}
                                panelStyle={{ fontSize: '10px' }}
                                optionLabel="brand"
                                placeholder="Produit"
                                scrollHeight="100px"
                            />
                        </div>
                        <div className="col-2 div__section price" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                            <InputText
                                id={`product-${index}-quantity`}
                                name={`products[${index}].quantity`}
                                value={formik.values.products[index]?.quantity || ''}
                                onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                size={6}
                                type="number"
                                placeholder="Quantité"
                            />
                        </div>
                        {Number(formik.values.products[index]?.price) !== 0 &&
                            <div className="col-2 div__section">
                                <span className="title__extra" >Prix/Unité</span>
                                <span>{formik.values.products[index]?.price}</span>
                            </div>
                        }



                    </div>
                </div>
            ))
            }
            <div style={{ marginTop: "20px", marginBottom: "6px" }}>
                <Button
                    style={{
                        backgroundColor: "lightgrey",
                        color: "black",
                        borderColor: "lightgrey",
                        padding: "10px",
                        marginRight: "10px",
                        fontSize: "14px"

                    }}
                    aria-labelledby="annuler" label="Annuler" />
                <Button
                    style={{
                        backgroundColor: "#3EFFFA",
                        color: "black",
                        borderColor: "#3EFFFA",
                        padding: "10px",
                        fontSize: "14px"
                    }}
                    aria-labelledby="valider" type="submit" label="Valider"
                    onClick={(e) => (e.persist(), formik.setFieldValue('submittedFor', 'client'))} />
            </div>
        </div >

    )
}

export default ProfessionalUpdate;



