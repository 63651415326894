import { Button, Modal, Text } from "@nextui-org/react";
import { User } from "../../../entities/user.entity";
import { updateUser } from "../../../services/users.service";
import { useSelector } from "react-redux";

interface DeleteUserProps {
    visible: boolean;
    onClose: (data?: string) => void;
    user?: User;
}

function DeleteUser({ visible, onClose, user }: DeleteUserProps) {

    const loggedUser = useSelector((state: any) => state.login.loggedUser) as User;

    function submit() {
        let today = new Date();
        user!.deletionDate = today;
        user!.updateDate = today;
        user!.updatedBy = loggedUser.email;
        updateUser(user!)
            .then(res => {
                onClose('Success');
            });
    }
    function closeHandler() {
        onClose();
    }

    return (
        <div>
            <Modal
                closeButton
                blur
                aria-labelledby="modal-title"
                open={visible}
                onClose={closeHandler}
                width="450px"
            >
                <Modal.Header>
                    <Text b id="modal-title" size={18}>
                        Supprimer utilisateur
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Text>
                        Êtes-vous sûr de vouloir supprimer l'utilisateur?
                    </Text>
                    <Text b>{user?.firstName} {user?.lastName}</Text>
                </Modal.Body>
                <Modal.Footer>
                    <Button onPress={submit} auto>
                        Oui
                    </Button>
                    <Button auto flat color="error" >
                        Non
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>);
}

export default DeleteUser;