import { Input, Text, getDocumentTheme } from "@nextui-org/react";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Deal } from "../../entities/deal.entity";
import { getDeals, getInstallationsPerDealByDealId, getProductsPerDealByDealId } from "../../services/deals.service";
import { formatDate } from "../../helper/dateFormattor";
import StatusRenderer from "../shared/StatusRenderer/status";
import "./Deals.scss";
import { ContextMenu } from 'primereact/contextmenu';
import DeleteDeal from "./delete/DeleteDeal";
import { Toast } from "primereact/toast";
import TypeRenderer from "../shared/TypeRenderer/Type";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { priceFormattor } from "../../helper/priceFormattor";
import { Button } from "primereact/button";
import { UppercaseFormatter } from "../../helper/uppercaseFormattor";
import ValidateDeal from "./confirmation/validateDeal";
import { Quote } from "../../entities/quote.entity";
import { InstallationPerDealDto } from "../../dtos/installation-per-deal.dto";
import { downloadQuote } from "../../services/quote.service";
import CustomButton from "../shared/Buttons/customButton";
import { TodaysDate } from "../../helper/todaysDate";
import { Tooltip } from "primereact/tooltip";
import { ApplyTVA } from "../../helper/tvaHelper";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../entities/user.entity";
import "../shared/Tables/table.scss"
import { updateTheme } from "../../redux/actions/ThemeAction";
import { useLocation } from "react-router-dom";
import { CircuitBreaker, DealStatus, MailType, QuoteConstants, homePageFilters } from "../../constants/enum";
import { MailDto } from "../../dtos/mail.dto";
import { sendMail } from "../../services/mail.service";
import { mapStatus } from "../../helper/statusMapper";
import SiretRenderer from "../shared/SiretRenderer/SiretRenderer";
import { getQuote as getQuoteFromBack } from '../../helper/quoteGetter';
import { downloadSignedDocument, sendSignatureRequest } from "../../services/yousign.service";
import AddComment from "./comments/AddComment";
import CommentIndicator from "../shared/CommentIndicator/CommentIndicator";
import Details from "./details/Details";
import UpdateDeal from "./update/UpdateDeal";


function Deals() {
    const loggedUser = useSelector((state: any) => state.login.loggedUser) as User;
    const location = useLocation();
    const filter = location.state?.filter;
    const filters = [DealStatus.ToSign, DealStatus.Won, DealStatus.Lost];
    const [showFilter, setShowFilter] = useState(false);
    const [deals, setDeals] = useState<Deal[] | any>([]);
    const [withoutFilterDeals, setWithoutFilterDeals] = useState<Deal[]>([]);
    const toast = useRef<Toast | null>(null);
    const [toastMessage, setToastMessage] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [validateModal, setvalidateModal] = useState(false);

    const [isDark, setIsDark] = useState(false);
    const [visible, setVisible] = useState(false);
    const [sideBarData, setSideBarData] = useState<any>();


    useEffect(() => {
        let theme = window.localStorage.getItem('data-theme');
        setIsDark(theme === 'dark');

        const observer = new MutationObserver((mutation) => {
            let newTheme = getDocumentTheme(document?.documentElement);
            setIsDark(newTheme === 'dark');
        });

        observer.observe(document?.documentElement, {
            attributes: true,
            attributeFilter: ['data-theme', 'style']
        });

        return () => observer.disconnect();
    }, []);
    const dispatch = useDispatch();

    dispatch(updateTheme(isDark))

    useEffect(() => {
        (
            async () => {
                await getDeals()
                    .then(response => {
                        let deals = filterByRole(response.data as Deal[]);
                        switch (filter) {
                            case homePageFilters.user:
                                deals = deals.filter(deal => deal.clientId?.partnerId?.email === loggedUser.email
                                    && [DealStatus.ToSign, DealStatus.ToEvaluate, DealStatus.VisitToDo, DealStatus.ToAmend, DealStatus.Unreachable].includes(deal.status as DealStatus));
                                break;
                            case homePageFilters.concession:
                                deals = deals.filter(deal => deal.clientId?.partnerId?.partner?.name === loggedUser.partner?.name
                                    && [DealStatus.ToSign, DealStatus.ToEvaluate, DealStatus.VisitToDo, DealStatus.ToAmend, DealStatus.Unreachable].includes(deal.status as DealStatus));
                                break;
                            default:
                                break;
                        }
                        setDeals(deals);
                        setWithoutFilterDeals(deals);
                    })
                    .catch(err => console.error('Disconnected'));
            }
        )();
    }, []);

    function filterByRole(deals: Deal[]): Deal[] {
        switch (loggedUser.role) {
            case 'Commercial':
                return deals.filter(deal => deal.clientId?.partnerId?.email === loggedUser.email);
            case 'Direction':
                return deals.filter(deal => deal.clientId?.partnerId?.partner?.id === loggedUser.partner?.id);
            default:
                return deals;
        }
    }

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showAddCommentModal, setShowAddCommentModal] = useState(false);
    const [showViewCommentModal, setShowViewCommentModal] = useState(false);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedDeal, setSelectedDeal] = useState<Deal>();
    const [contextMenuDeal, setContextMenuDeal] = useState<Deal>();

    const openUpdateModal = (rowData: any) => {
        if (rowData) {
            setSelectedDeal(rowData);
            setShowUpdateModal(true);
        }
    }

    const openAddCommentModal = (rowData: any) => {
        if (rowData) {
            setSelectedDeal(rowData);
            setShowAddCommentModal(true);
        }
    }

    const closeAddCommentModal = () => {
        setShowAddCommentModal(false);
        setSelectedDeal(undefined);
    };

    const openViewCommentModal = (rowData: any) => {
        if (rowData) {
            setSelectedDeal(rowData);
            setShowViewCommentModal(true);
        }
    }

    const closeViewCommentModal = () => {
        setShowViewCommentModal(false);
        setSelectedDeal(undefined);
    };

    const closeUpdateModal = () => {
        setShowUpdateModal(false);
        setSelectedDeal(undefined);
    };

    const openConfirmationModal = (rowData: any) => {
        if (rowData) {
            setSelectedDeal(rowData);
            setShowConfirmationModal(true);
        }
    }


    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const closeValidateModal = () => {
        setvalidateModal(false);
    }
    const updateStatus = (rowData: any) => {
        setvalidateModal(true);
    }

    async function sendQuote(rowData: any) {
        let savedDeal = rowData as Deal;
        try {
            let quote = await getQuoteFromBack(savedDeal);
            if (savedDeal) {
                let mail = {
                    mailType: MailType.SIGN_CLIENT,
                    deal: savedDeal,
                    quote: quote
                } as MailDto;
                await sendMail(mail);
                handleUpdate("successful update");
            } else {
                handleUpdate("failed update");
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleValidation = (data: string) => {
        setToastMessage(data);
        if (toast.current) {
            switch (data) {
                case "successful booking":
                    toast.current.show({ severity: 'success', summary: 'Affaire validée', life: 6000 });
                    window.location.reload();
                    break;
                case "failed booking":
                    toast.current.show({ severity: 'error', summary: 'Echec de validation', life: 6000 });
                    break;
                default:
                    break;
            }
        }
    }


    const handleUpdate = (data: string) => {
        setToastMessage(data);
        if (toast.current) {
            switch (data) {
                case "successful update":
                    toast.current.show({ severity: 'success', summary: 'Modification enregistrée', life: 6000 });
                    window.location.reload();
                    break;
                case "failed update":
                    toast.current.show({ severity: 'error', summary: 'Echec de la modification', life: 6000 });
                    break;
                case "failed email":
                    toast.current.show({ severity: 'error', summary: 'Echec de l\'envoi de mail', life: 6000 });
                    break;
                default:
                    break;
            }
        }
    }

    const cm = useRef<ContextMenu>(null);

    const actions = [
        {
            label: 'Envoyer le devis', icon: 'pi pi-fw pi-envelope', command: async () => {
                await sendSignatureRequest(selectedDeal?.signatureRequestId!).then(() => {
                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Devis envoyé au client', life: 6000 });
                    }
                }).catch(() => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Erreur lors de l\'envoi du devis', life: 6000 });
                    }
                });
            }, visible: selectedDeal?.status !== DealStatus.Lost && selectedDeal?.status !== DealStatus.ToEvaluate && selectedDeal?.status !== DealStatus.VisitToDo
        },
        {
            label: 'Télécharger le devis', icon: 'pi pi-fw pi-download', command: async () => {
                let quote = await getQuote();
                let filename = `${selectedDeal?.clientId?.lastName}_EMS_DEVIS_${TodaysDate()}.pdf`;

                let response = await downloadQuote(quote);

                const blob = new Blob([response.data]);

                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;

                a.download = filename;
                a.click();

                window.URL.revokeObjectURL(url);
            },
            visible: selectedDeal?.status !== DealStatus.ToEvaluate && selectedDeal?.status !== DealStatus.ToAmend
        },
        {
            label: 'Télécharger le devis signé', icon: 'pi pi-fw pi-download', command: async () => {
                let response = await downloadSignedDocument(selectedDeal?.signatureRequestId!);
                let filename = `${selectedDeal?.clientId?.lastName}_EMS_DEVIS_${TodaysDate()}.pdf`;

                const blob = new Blob([response.data]);

                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;

                a.download = filename;
                a.click();

                window.URL.revokeObjectURL(url);
            },
            visible: selectedDeal?.status !== DealStatus.ToEvaluate && selectedDeal?.status === DealStatus.Won && selectedDeal?.signatureRequestId !== null
        },
        { label: 'Mettre à jour', icon: 'pi pi-fw pi-pencil', command: () => openUpdateModal(selectedDeal), visible: selectedDeal?.status === DealStatus.ToAmend || selectedDeal?.status === DealStatus.ToDraft },

        { label: 'Modifier', icon: 'pi pi-fw pi-pencil', command: () => openUpdateModal(selectedDeal), visible: (selectedDeal?.status === DealStatus.ToSign || selectedDeal?.status === DealStatus.VisitToDo || selectedDeal?.status === DealStatus.ToEvaluate) && selectedDeal?.clientId?.type !== QuoteConstants.professional },
        // { label: 'Ajouter une note', icon: 'pi pi-fw pi-plus-circle', command: () => openAddCommentModal(selectedDeal), visible: selectedDeal?.status === DealStatus.ToSign || selectedDeal?.status === DealStatus.VisitToDo || selectedDeal?.status === DealStatus.ToEvaluate },
        { label: 'Abandonner', icon: 'pi pi-fw pi-trash', command: () => openConfirmationModal(selectedDeal) },
        { separator: selectedDeal?.status === DealStatus.ToSign, visible: selectedDeal?.status === DealStatus.ToSign },
        // { label: 'Envoyer devis', icon: 'pi pi-fw pi-check-circle', command: () => { sendQuote(selectedDeal) }, visible: selectedDeal?.status === DealStatus.ToSign },
        { label: 'Gagnée', icon: 'pi pi-fw pi-check-circle', command: () => { updateStatus(selectedDeal) }, visible: selectedDeal?.status === DealStatus.ToSign },


    ];

    async function getQuote() {
        var cbType = CircuitBreaker.Mono;
        let resIpd = await getInstallationsPerDealByDealId(selectedDeal?.id!);
        if (resIpd.data.length) {
            let mainInstallation = resIpd.data.find((ipd: InstallationPerDealDto) => ipd.installationDetail.maxInstallationDistance !== null && ipd.installationDetail.minInstallationDistance !== null).installationDetail;
            cbType = mainInstallation.circuitBreaker;
            var totalDistance = mainInstallation.maxInstallationDistance;
            if (resIpd.data.length > 1) {
                let extraInstallation = resIpd.data.find((ipd: InstallationPerDealDto) => ipd.installationDetail.maxInstallationDistance === null && ipd.installationDetail.minInstallationDistance);
                totalDistance += extraInstallation.quantity;
            }
        }

        let quote: Quote = {
            typeOfPerson: selectedDeal?.clientId?.type!,
            housing: '',
            circuitBreaker: cbType!,
            distance: totalDistance! + 'm',
            client: selectedDeal?.clientId,
            dealId: selectedDeal?.id!,
            deal: selectedDeal
        };

        return quote;
    }


    const handleFilter = (filter: string) => {
        switch (filter) {
            case DealStatus.ToSign:
            case DealStatus.Won:
            case DealStatus.Lost:
                let newDeals = withoutFilterDeals.filter(deal => deal.status === filter);
                setDeals(newDeals);
                break;
            case 'Affaires':
                setDeals(withoutFilterDeals);
                break;
            default:
                break;
        }
    }
    const setContextMenu = (e: any) => {
        setSelectedDeal(e.value);
    }
    const handleInputChange = (event: any) => {
        const inputValue = event.target.value;
        setSearchInput(inputValue);

        const filteredResults = withoutFilterDeals.filter((deal) =>
            deal.clientId?.firstName.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            deal.clientId?.lastName.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            deal.clientId?.type.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            deal.clientId?.lastName.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            deal.creationDate?.toString().includes(inputValue.toLowerCase()) ||
            deal.clientId?.companyName?.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            deal.clientId?.siretCode?.toString().startsWith(inputValue.toLowerCase())
        );

        setDeals(filteredResults);
    };

    const getBadgeNumber = (filter: string) => {
        switch (filter) {
            case DealStatus.ToSign:
            case DealStatus.Won:
            case DealStatus.Lost:
                return withoutFilterDeals.filter(deal => deal.status === filter).length.toString();
            case 'Affaires':
                return withoutFilterDeals.length.toString();
            default:
                break;
        }
    }
    const handleFilters = () => {
        setShowFilter(!showFilter);
        if (!showFilter) {
            setDeals(withoutFilterDeals);
        }
    }

    const handleHide = () => {
        setVisible(false);
        setSideBarData(null);
    };

    const handleSideBarOpen = (data: any) => {
        setSideBarData(data);
        setVisible(true);
    }
    return (
        <div>
            <Container>
                <Row className="mt-2 mb-3">
                    <Col>
                        <Text size={36} b>Affaires</Text>
                    </Col>
                </Row>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="div__filter">
                        <div className="div__input_icon">
                            <Input
                                width='90%'
                                type="search"
                                placeholder="Rechercher une affaire"
                                value={searchInput}
                                onChange={handleInputChange}
                                aria-labelledby="search"
                            />
                            <div onClick={handleFilters}>
                                <CustomButton title="Filtres" />
                            </div>
                        </div>
                        {showFilter && <div className="div__button_filter">
                            {filters.map((filter, index) => (
                                <Col key={index} style={{ display: 'flex' }} className="justify-content-center">
                                    <Button
                                        aria-labelledby="btn"
                                        rounded
                                        outlined
                                        size="small"
                                        badge={getBadgeNumber(filter)}
                                        onClick={() => handleFilter(filter)}
                                        label={mapStatus(filter)}
                                        style={{ width: '7rem', color: isDark ? '#8dd0ff' : '' }}
                                    >
                                    </Button>
                                </Col>
                            ))}
                        </div>}
                    </div>
                </div>
                <Row>
                    <Col>
                        <div className={!isDark ? "custom_table__light" : "custom_table__dark"}>
                            <ContextMenu model={actions} ref={cm} className={isDark ? 'dark-context' : 'light'} />
                            <DataTable stripedRows columnResizeMode="expand" resizableColumns paginator rows={10} value={deals}
                                removableSort tableStyle={{ minWidth: '50rem' }} sortMode="multiple" selectionMode="single"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[10, 25, 50]}
                                emptyMessage="Aucune affaire." currentPageReportTemplate="Affaire {first} jusqu'à {last} sur {totalRecords}"
                                onContextMenu={(e) => cm.current?.show(e.originalEvent)}
                                contextMenuSelection={selectedDeal || undefined} onContextMenuSelectionChange={(e) => setSelectedDeal(e.value)} >
                                <Column
                                    aria-labelledby="IdAffaire"
                                    field="id"
                                    header=""
                                    body={(rowData) => (
                                        <div onClick={() => handleSideBarOpen(rowData)}>
                                            <CommentIndicator text={rowData.id} comment={rowData.customNotes} />
                                        </div>
                                    )}
                                ></Column>
                                <Column
                                    aria-labelledby="IdAffaire"
                                    field="id"
                                    header="Id"
                                    sortable
                                    style={{ fontWeight: "500" }}
                                ></Column>

                                <Column aria-labelledby="firstname" field="clientId.firstName" header="Prénom" sortable style={{ fontWeight: "500" }} ></Column>
                                <Column aria-labelledby="lastname" field="clientId.lastName" header="Nom" sortable style={{ fontWeight: "500" }} body={(rowData) => UppercaseFormatter(rowData.clientId.lastName)} ></Column>
                                <Column aria-labelledby="type" field="clientId.type" header="Type" style={{ minWidth: '6rem' }} body={(rowData) => <TypeRenderer text={rowData.clientId.type} companyName={rowData.clientId.companyName} />} sortable />
                                <Column aria-labelledby="creation" field="creationDate" header="Ouverture" body={(rowData) => formatDate(rowData.creationDate.toString())} sortable />
                                <Column aria-labelledby="devis" field="amount" header="Devis" body={(rowData) => priceFormattor(ApplyTVA(rowData.amount, rowData.clientId.type)).concat(' €')} sortable />
                                <Column aria-labelledby="status" field="status" header="Affaire" body={(rowData) => {
                                    return (
                                        <>
                                            {(rowData.status === DealStatus.Lost || rowData.status === DealStatus.Unachievable) ? (
                                                <>
                                                    <Tooltip target=".status" style={{ fontSize: '12px' }} />
                                                    <div className="status" data-pr-tooltip={rowData.withdrawReason}
                                                        data-pr-position="right">
                                                        <StatusRenderer text={rowData.status} />
                                                    </div>
                                                </>
                                            ) :
                                                <StatusRenderer text={rowData.status} />
                                            }
                                        </>
                                    )
                                }} sortable />
                                <Column aria-labelledby="partnerLastname" field="clientId.partnerId.lastName" header="Commercial" sortable />
                                <Column aria-labelledby="concession" field="clientId.partnerId.partner.name" header="Concession" sortable />
                                <Column aria-labelledby="siretCode" field="clientId.siretCode" header="N° Siret" body={(rowData) => <SiretRenderer text={rowData.clientId.siretCode} />} sortable></Column>
                                <Column aria-labelledby="update" field="updateDate" header="Date de modification" body={(rowData) => formatDate(rowData.updateDate)} sortable />
                                <Column aria-labelledby="comment" field="comment" header="Commentaire" />

                            </DataTable >
                        </div>

                    </Col>
                </Row>

                <Details visible={visible} onHide={handleHide} deal={sideBarData} />

                {
                    showUpdateModal && selectedDeal && (
                        <UpdateDeal open={showUpdateModal} onClose={closeUpdateModal} deal={selectedDeal} handleUpdate={handleUpdate} />
                    )
                }
                {
                    showAddCommentModal && selectedDeal && (
                        <AddComment open={showAddCommentModal} onClose={closeAddCommentModal} deal={selectedDeal} handleUpdate={handleUpdate} />
                    )
                }
                {
                    showConfirmationModal && selectedDeal && (
                        <DeleteDeal open={showConfirmationModal} onClose={closeConfirmationModal} deal={selectedDeal} />
                    )
                }
                {
                    validateModal && selectedDeal && (
                        <ValidateDeal open={validateModal} onClose={closeValidateModal} deal={selectedDeal} handleUpdate={handleValidation} />
                    )
                }
                <Toast ref={toast} position="top-right" />
            </Container >
        </div >
    )
}

export default Deals;
