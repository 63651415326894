import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import "./button.scss"
import { useSelector } from "react-redux";


interface buttonProps {
    title: string;
    disabled?: boolean;
}

function CustomButton({ title, disabled }: buttonProps) {
    const isDark = useSelector((state: any) => state.theme.isDark);
    const getClass = (type: string) => {
        switch (type) {
            case 'Se connecter':
            case 'Confirmer commande':
            case 'Confirmer':
            case 'Envoyer':
            case 'Valider':
            case 'Valider client':
            case 'Valider affaire':
                return "validate-button";
            case 'Annuler':
            case 'Modifier la date':
            case 'Aucun accessoire':
                return "cancel-button";
            case 'Filtres':
                return isDark ? 'filter-button-dark' : 'filter-button';
            case 'Valider ma distance':
                return isDark ? 'validateDistance-dark' : 'validateDistance-light';
            case 'Supprimer':
                return isDark ? 'delete-dark' : 'delete-light';
            case 'Saisie manuelle':
            case 'Carte':
                return isDark ? 'option-dark' : 'option-light';
            case 'Borne non connecté':
            case 'Borne':
            case 'Borne non connecté':
            case 'Powerboost':
            case 'Installation':
                return isDark ? 'quote-badge-dark' : 'quote-badge-light';
            case 'Suivant':
                return "validate-button-QuickDevis";
            case 'Nos points de charges':
                return "buttonpointsdecharge";

            default:
                break;
        }
    }

    return (
        <Button className={getClass(title)} aria-labelledby={title} label={title} disabled={disabled} />
    );
}

export default CustomButton;
