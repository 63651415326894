import { Link as LinkFromDom, useLocation } from "react-router-dom";
import { Dropdown, Navbar, Text, changeTheme, useTheme } from "@nextui-org/react";
import { FiBell, FiMoon, FiSun } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Key, useState } from "react";
import "./NavbarEMS.scss";
import Logo from "./Logo";
import { User } from "../../entities/user.entity";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/actions/LoginAction";
import { logout } from "../../services/auth.service";
import UserProfile from "./UserProfile";
import { updateTheme } from "../../redux/actions/ThemeAction";


interface NavbarLSProps {
    user?: User;
}

function NavbarLS({ user }: NavbarLSProps) {
    const [visible, setVisible] = useState(false);
    const { isDark } = useTheme();
    const loggedUser = useSelector((state: any) => state.login.loggedUser);
    const dispatch = useDispatch();

    const handleChange = () => {
        const nextTheme = isDark ? "light" : "dark";
        window.localStorage.setItem("data-theme", nextTheme);
        changeTheme(nextTheme);
        if (nextTheme === "dark") {
            dispatch(updateTheme(true))
        } else {
            dispatch(updateTheme(false))

        }
    };

    const [navbarOpen, setNavbarOpen] = useState(false);
    const navigate = useNavigate();

    function handleClick(key: Key) {
        switch (key) {
            case "logout":
                logout();
                dispatch(loginUser(undefined));
                localStorage.removeItem('user');
                navigate("/");
                break;
            case "profile":
                setVisible(true);
                break;
            case "users":
                navigate("users");
                break;
            case "handleProducts":
                navigate("handle-products");
                break;
            default:
                break;
        }
    }

    const handleCollapseSelect = () => {
        setNavbarOpen(false);
    };

    const closeHandler = () => {
        setVisible(false);
    };

    const getColor = () => {
        switch (loggedUser?.role) {
            case 'LSAdmin':
            case 'EMSAdmin':
                return 'red';
            case 'Direction':
                return 'purple';
            case 'Commercial':
                return 'black';
            case 'Installer':
                return 'green';
            case 'InstallerManager':
                return 'yellow';
        }
    }

    const getInitials = () => {
        if (loggedUser) {
            return loggedUser?.firstName[0] + loggedUser?.lastName[0];
        }
    }

    const getDropdown = () => {
        if (loggedUser.role === 'LSAdmin'
            || loggedUser.role === 'EMSAdmin'
            || loggedUser.role === 'Direction'
            || loggedUser.role === 'InstallerManager') {
            return (<Dropdown.Menu
                aria-label="test"
                className="dropdown__menu "
                onAction={(key: Key) => handleClick(key)}
            >
                <Dropdown.Item key="profile">Profil</Dropdown.Item>
                <Dropdown.Item key="users">Gestion des utilisateurs</Dropdown.Item>
                {/* <Dropdown.Item key="handleProducts">Gestion des produits</Dropdown.Item> */}
                <Dropdown.Item key="logout">Se déconnecter</Dropdown.Item>
            </Dropdown.Menu>);
        }
        else {
            return (<Dropdown.Menu
                aria-label="test"
                className="dropdown__menu "
                onAction={(key: Key) => handleClick(key)}
            >
                <Dropdown.Item key="profile">Profil</Dropdown.Item>
                <Dropdown.Item key="logout">Se déconnecter</Dropdown.Item>
            </Dropdown.Menu>);
        }
    }

    const location = useLocation();

    function getNavbarStyle(where: string) {
        const isActive = where === location.pathname;
        let className = 'navbar-link';
        if (isActive) {
            className += ' active';
        }
        return className;
    }

    return (
        <Navbar variant={"sticky"} maxWidth="fluid">
            <Navbar.Brand>
                <LinkFromDom to="/partners-dashboard">
                    <Logo isDark={isDark} partner={true}></Logo>
                </LinkFromDom>
            </Navbar.Brand>

            <Navbar.Content hideIn="xs">
                {
                    (
                        loggedUser.role === 'LSAdmin'
                        || loggedUser.role === 'EMSAdmin'
                        || loggedUser.role === 'Direction'
                        || loggedUser.role === 'Commercial'
                    )
                    &&
                    <Navbar.Link as={Link} to="/clients">
                        <Text b className={getNavbarStyle("/clients")}>
                            Clients
                        </Text>
                    </Navbar.Link>
                }
                {
                    (
                        loggedUser.role === 'LSAdmin'
                        || loggedUser.role === 'EMSAdmin'
                        || loggedUser.role === 'Direction'
                        || loggedUser.role === 'Commercial'
                    )
                    &&
                    <Navbar.Link as={Link} to="/deals">
                        <Text b className={getNavbarStyle("/deals")}>
                            Affaires
                        </Text>
                    </Navbar.Link>
                }
                {
                    (
                        loggedUser.role === 'LSAdmin'
                        || loggedUser.role === 'EMSAdmin'
                        || loggedUser.role === 'Direction'
                        || loggedUser.role === 'Commercial'
                    )
                    &&
                    <Navbar.Link as={Link} to="/orders">
                        <Text b className={getNavbarStyle("/orders")}>
                            Commandes
                        </Text>
                    </Navbar.Link>
                }
                {
                    (
                        loggedUser.role === 'EMSAdmin'
                        || loggedUser.role === 'Installer'
                        || loggedUser.role === 'InstallerManager'
                    )
                    &&
                    <Navbar.Link as={Link} to="/installation">
                        <Text b className={getNavbarStyle("/installation")}>
                            Installations
                        </Text>
                    </Navbar.Link>
                }
                {
                    (
                        loggedUser.role === 'LSAdmin'
                        || loggedUser.role === 'EMSAdmin'
                        || loggedUser.role === 'Direction'
                        || loggedUser.role === 'Commercial'
                    )
                    &&
                    <Navbar.Link as={Link} to="/products-partner">
                        <Text b className={getNavbarStyle("/products-partner")}>
                            Produits
                        </Text>
                    </Navbar.Link>
                }
            </Navbar.Content>
            <Navbar.Content hideIn="xs">
                <Navbar.Link>
                    <FiBell size={24} />
                </Navbar.Link>
                <Navbar.Link>
                    {isDark ? (
                        <FiSun size={24} onClick={handleChange} />
                    ) : (
                        <FiMoon size={24} onClick={handleChange} />
                    )}
                </Navbar.Link>
                <Dropdown isBordered>
                    <Navbar.Item>
                        <Dropdown.Button
                            auto
                            light
                            className="dropdown__button"
                            ripple={false}>
                            <Text b color={getColor()}>{getInitials()}</Text>
                        </Dropdown.Button>
                    </Navbar.Item>
                    {getDropdown()}
                </Dropdown>
            </Navbar.Content>


            <Navbar.Content showIn="xs">
                <Navbar.Toggle onClick={() => setNavbarOpen(!navbarOpen)} />
            </Navbar.Content>

            <UserProfile open={visible} onClose={closeHandler} loggedUser={loggedUser}></UserProfile>
        </Navbar >
    );
}

export default NavbarLS;
