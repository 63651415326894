import axios from 'axios';
import { Contact } from '../entities/contact.entity';

const API_URL = process.env.REACT_APP_API_URL;

export function getContacts() {
    return axios.get(API_URL + 'contacts',);
};

export async function saveContact(contact: Contact) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post(API_URL + 'contacts', contact, { headers: customHeaders });
}