import { Input, getDocumentTheme, Text, Button } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { ProductsEms } from "../../entities/productsEms.entity";
import { useDispatch } from "react-redux";
import { updateTheme } from "../../redux/actions/ThemeAction";
import { getAllProducts } from "../../services/productsEms.service";
import { Col, Container, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./Products.scss"
import { FiPlus } from "react-icons/fi";

function Products() {

  const [isDark, setIsDark] = useState(false);
  const [products, setProducts] = useState<ProductsEms[] | any>([]);
  const [searchInput, setSearchInput] = useState('');
  const [unfilteredProducts, setUnfilteredProducts] = useState<ProductsEms[]>([]);
  const [showAddProduct, setShowAddProduct] = useState(false);


  useEffect(() => {
    let theme = window.localStorage.getItem('data-theme');
    setIsDark(theme === 'dark');

    const observer = new MutationObserver((mutation) => {
      let newTheme = getDocumentTheme(document?.documentElement);
      setIsDark(newTheme === 'dark');
    });

    observer.observe(document?.documentElement, {
      attributes: true,
      attributeFilter: ['data-theme', 'style']
    });

    return () => observer.disconnect();
  }, []);
  const dispatch = useDispatch();

  dispatch(updateTheme(isDark))

  useEffect(() => {
    (
      async () => {
        await loadAllProducts();
      }
    )();
  }, [products.length]);

  const loadAllProducts = async () => {
    await getAllProducts()
      .then(response => {
        setProducts(response.data);
      })
      .catch(err => console.error('Disconnected'));
  };

  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);

    const filteredResults = unfilteredProducts.filter((product) =>
      product.category.toLowerCase().includes(inputValue.toLowerCase()) ||
      product.emsReference.toLowerCase().includes(inputValue.toLowerCase()) ||
      product.partnerReference.toLowerCase().includes(inputValue.toLowerCase()) ||
      product.name.toLowerCase().includes(inputValue.toLowerCase())

    );

    setProducts(filteredResults);
  };

  const bodyTemplate = (rowData: any, field: any) => (
    <div className={field === 'category' ? 'wrap-text' : 'ellipsis'}>
      {rowData[field]}
    </div>
  );

  const cellClassName = (rowData: any) => {
    switch (rowData.category) {
      case 'Forfait installation':
        return 'row-installation';
      case 'Génie civil':
        return 'row-genieCivil';
      case 'Travaux électrique supplémentaires':
        return 'row-tes';
      case 'Signalétique':
        return 'row-signal';
      case 'Produits':
        return 'row-produit';
      case 'CPO':
        return 'row-cpo';
      case 'Cartes':
        return 'row-cartes';
      default:
        return '';
    }
  };



  return (
    <div>
      <Container>
        <Row className="mt-2 mb-3">
          <Col className="align-items-center">
            <Text size={36} b className="test">Gestion des produits</Text>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col
            style={{ display: 'flex' }}
            className="justify-content-end"
          >
            {/* <Button onPress={() => setShowAddProduct(true)} className={isDark ? "newProduct-button-dark" : ""}>
              <FiPlus />
              Nouveau produit
            </Button> */}
          </Col>
          <Col
            xs={6}
            md={6}
            style={{ display: 'flex' }}
            className="justify-content-center"
          >
            <Input
              width='90%'
              type="search"
              placeholder="Rechercher un utilisateur"
              aria-labelledby="Recherche"
              value={searchInput}
              onChange={handleInputChange}
            />
          </Col>
          <Col
            xs={3}
            md={3}
            style={{ display: 'flex' }}
            className="justify-content-start custom__div"
          >
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={!isDark ? "custom_table__light" : "custom_table__dark"}>
              {/* <ContextMenu model={actions} ref={cm} onHide={() => setContextMenuUser(undefined)} className={isDark ? 'dark-context' : 'light'} /> */}
              <DataTable stripedRows columnResizeMode="expand" resizableColumns paginator rows={10} value={products}
                removableSort tableStyle={{ minWidth: '50rem' }} sortMode="multiple" selectionMode="single"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10, 25, 50]}
                emptyMessage="Aucun utilisateur." currentPageReportTemplate="Utilisateur {first} jusqu'à {last} sur {totalRecords}"
              >
                {/* onContextMenu={(e) => cm.current?.show(e.originalEvent)}
                contextMenuSelection={contextMenuUser || undefined} onContextMenuSelectionChange={(e) => setContextMenuUser(e.value)} */}


                <Column bodyClassName={cellClassName} field="category" header="category" body={(rowData) => bodyTemplate(rowData, 'category')} style={{ maxWidth: '110px', fontWeight: '500' }} />
                <Column field="name" header="name" body={(rowData) => bodyTemplate(rowData, 'name')} style={{ maxWidth: '290px' }} />
                <Column aria-labelledby="emsReference" field="emsReference" header="Référence EMS" style={{ fontWeight: "500" }} sortable />
                <Column aria-labelledby="partnerReference" field="partnerReference" header="Référence Partenaire" style={{ fontWeight: "500", width: "12px" }} sortable />
                <Column aria-labelledby="sellPrice" field="sellPrice" header="Prix de vente" sortable />
                <Column aria-labelledby="buyPrice" field="buyPrice" header="Prix d'achat" sortable />
                <Column aria-labelledby="supplier" field="supplier" header="Fournisseur" sortable />
                <Column field="description" header="description" body={(rowData) => bodyTemplate(rowData, 'description')} style={{ maxWidth: '150px' }} />


              </DataTable>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default Products;
