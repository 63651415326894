import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

interface diastanceProps {
  origin: any;
  destination: any;
}

export function calculateDistance({ origin, destination }: diastanceProps) {
  const url = `${API_URL}google-maps/calculate-distance`;
  const requestBody = {
    origin,
    destination,
  };

  const customHeaders = {
    'Content-Type': 'application/json',
  };

  return axios.post(url, requestBody, { withCredentials: true, headers: customHeaders });
}


export default {
  calculateDistance,
};
