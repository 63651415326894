import './App.scss';
import { useEffect, useState } from 'react';
import NavbarEMS from './components/navbar/NavbarEMS';
import { Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import Professional from './components/professional-client/Professional';
import Private from './components/private-client/Private';
import CoOwnership from './components/coOwnership/CoOwnership';
import ChargingStations from './components/charging-stations/ChargingStations';
import Products from './components/ems-products/Products';
import PartnerOffer from './components/partners/offer/PartnerOffer';
import { profile } from './services/auth.service';
import { User } from './entities/user.entity';
import Offer from './components/ems-offer/Offer';
import PartnersHome from './components/partners-space/PartnersHome';
import NavbarLS from './components/navbar/NavbarLS';
import Deals from './components/deals/Deals';
import Clients from './components/clients/Clients';
import ProductsPartner from './components/products-partner/ProductsPartner';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from './redux/actions/LoginAction';
import Auth from './components/credentials/auth/Auth';
import Orders from './components/orders/Orders';
import Users from './components/users/Users';
import Installations from './components/installations/Installations';
import ChargeCard from './components/charge-card/ChargeCard';

function App() {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state: any) => {
    return state.login.loggedUser;
  })

  useEffect(() => {
    profile()
      .then(response => {
        const user = response.data as User;
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(loginUser(user))
      })
      .catch(err => {
        if (err.response.status) {
          localStorage.removeItem('user');
          dispatch(loginUser(undefined));;
        }
      });
  }, []);

  const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);

  useEffect(() => {
    const handleResize = () => {
      setIsXSmallScreen(window.innerWidth < 650);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const widthClass = isXSmallScreen ? "home-background-phone" : "home-background-web";


  return (
    <div className="App">
      {
        loggedUser ? <NavbarLS user={loggedUser}></NavbarLS> : <NavbarEMS></NavbarEMS>
      }
      <Routes>
        {!loggedUser && <Route path="/" element={<div className={widthClass}><Home /></div>}></Route>}
        {!loggedUser && <Route path="/:login" element={<Home />}></Route>}
        {loggedUser && ['LSAdmin', 'EMSAdmin', 'Direction', 'Commercial'].includes(loggedUser.role)
          && <Route path="/" element={<PartnersHome />}></Route>}
        {loggedUser && ['Installer', 'InstallerManager'].includes(loggedUser.role)
          && <Route path="/" element={<Installations />}></Route>}


        <Route path="/professional" element={<Professional />}></Route>
        <Route path="/client" element={<Private />}></Route>
        <Route path="/coOwnership" element={<CoOwnership />}></Route>

        <Route path="/charging-station" element={<ChargingStations />}></Route>
        <Route path="/offer" element={<Offer />}></Route>
        <Route path="/products" element={<Products />}></Route>

        <Route path="/partner-offer" element={<PartnerOffer />}></Route>

        <Route element={<Auth allowedRoles={['LSAdmin', 'EMSAdmin', 'Direction', 'Commercial']} />}>
          <Route path="/partners-dashboard" element={<PartnersHome />}></Route>
        </Route>
        <Route element={<Auth allowedRoles={['LSAdmin', 'EMSAdmin', 'Direction', 'Commercial']} />}>
          <Route path="/deals" element={<Deals />} />
        </Route>
        <Route element={<Auth allowedRoles={['LSAdmin', 'EMSAdmin', 'Direction', 'Commercial']} />}>
          <Route path="/orders" element={<Orders />} />
        </Route>
        <Route element={<Auth allowedRoles={['EMSAdmin', 'Installer', 'InstallerManager']} />}>
          <Route path="/installation" element={<Installations />} />
        </Route>
        <Route element={<Auth allowedRoles={['LSAdmin', 'EMSAdmin', 'Direction', 'Commercial']} />}>
          <Route path="/clients" element={<Clients />} />
        </Route>
        <Route element={<Auth allowedRoles={['LSAdmin', 'EMSAdmin', 'Direction', 'Commercial']} />}>
          <Route path="/products-partner" element={<ProductsPartner />} />
        </Route>

        <Route element={<Auth allowedRoles={['LSAdmin', 'EMSAdmin', 'Direction', 'InstallerManager']} />}>
          <Route path="/users" element={<Users />} />
        </Route>

        <Route element={<Auth allowedRoles={['LSAdmin', 'EMSAdmin', 'Direction', 'InstallerManager']} />}>
          <Route path="/handle-products" element={<Products />} />
        </Route>
        <Route path="/charge-card" element={<ChargeCard />}></Route>


      </Routes>
    </div>
  );
}

export default App;

