import { useEffect, useRef, useState } from "react";
import { getUsers } from "../../services/users.service";
import { Button, Container, Input, Text, getDocumentTheme } from "@nextui-org/react";
import { Col, Row } from "react-bootstrap";
import { User } from "../../entities/user.entity";
import { getPartners } from "../../services/partners.service";
import { Partner } from "../../entities/partner.entity";
import { FiPlus } from "react-icons/fi";
import SignIn from "../credentials/signIn/SignIn";
import { Toast } from "primereact/toast";
import { ContextMenu } from "primereact/contextmenu";
import UpdateUser from "./update-user/UpdateUser";
import DeleteUser from "./delete-user/DeleteUser";
import { formatDate } from "../../helper/dateFormattor";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getRole } from "../../helper/roleGetter";
import CustomButton from "../shared/Buttons/customButton";
import { useDispatch, useSelector } from "react-redux";
import { updateTheme } from "../../redux/actions/ThemeAction";
import "./Users.scss";
import { Role } from "../../constants/enum";

function Users() {
    // const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const toast = useRef<Toast | null>(null);
    const [users, setUsers] = useState<User[] | any>([]);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [contextMenuUser, setContextMenuUser] = useState<User | undefined>(undefined);
    const [searchInput, setSearchInput] = useState('');
    const [withoutFilterUsers, setWithoutFilterUsers] = useState<User[]>([]);

    const [isDark, setIsDark] = useState(false);


    const cm = useRef<ContextMenu>(null);
    const loggedUser = useSelector((state: any) => state.login.loggedUser) as User;

    const loadUsers = async () => {
        await getUsers()
            .then(response => {
                if (loggedUser.role === Role.EMSAdmin) {
                    setUsers(response.data);
                    setWithoutFilterUsers(response.data);
                } else {
                    let filteredUsers = response.data.filter((user: User) => user.role !== Role.EMSAdmin);
                    setUsers(filteredUsers);
                    setWithoutFilterUsers(filteredUsers);
                }

            })
            .catch(err => console.error('Disconnected'));
    };

    const loadPartners = async () => {
        await getPartners()
            .then(response => {
                setPartners(response.data);
            })
            .catch(err => console.error('Disconnected'));
    };

    const closeSignInModal = (reason?: string) => {
        setShowSignInModal(false);
        if (reason === 'Success') {
            if (toast.current) {
                toast.current.show({ severity: 'success', summary: 'Utilisateur créé avec succès', life: 6000 });
            }
        }
    };

    const closeUpdateUserModal = (reason?: string) => {
        setShowUpdateUserModal(false);
        loadUsers();
        if (reason === 'Success') {
            if (toast.current) {
                toast.current.show({ severity: 'success', summary: 'Utilisateur modifié avec succès', life: 6000 });
            }
        }
    };

    const closeDeleteUserModal = (reason?: string) => {
        setShowDeleteUserModal(false);
        loadUsers();
        if (reason === 'Success') {
            if (toast.current) {
                toast.current.show({ severity: 'success', summary: 'Utilisateur supprimé avec succès', life: 6000 });
            }
        }
    };

    useEffect(() => {
        let theme = window.localStorage.getItem('data-theme');
        setIsDark(theme === 'dark');

        const observer = new MutationObserver((mutation) => {
            let newTheme = getDocumentTheme(document?.documentElement);
            setIsDark(newTheme === 'dark');
        });

        observer.observe(document?.documentElement, {
            attributes: true,
            attributeFilter: ['data-theme', 'style']
        });

        return () => observer.disconnect();
    }, []);
    const dispatch = useDispatch();

    dispatch(updateTheme(isDark))

    useEffect(() => {
        (
            async () => {
                await loadUsers();
                await loadPartners();
            }
        )();
    }, []);

    const getParentPartnerName = (parentPartnerId?: number) => {
        return partners.find(partner => partner.id === parentPartnerId)?.name;
    }

    const actions = [
        { label: 'Modifier', icon: 'pi pi-fw pi-pencil', command: () => setShowUpdateUserModal(true) },
        { label: 'Supprimer', icon: 'pi pi-fw pi-trash', command: () => setShowDeleteUserModal(true) },
    ];

    const handleInputChange = (event: any) => {
        const inputValue = event.target.value;
        setSearchInput(inputValue);

        const filteredResults = withoutFilterUsers.filter((user) =>
            user.lastName.toLowerCase().includes(inputValue.toLowerCase()) ||
            user.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
            user.email.toLowerCase().includes(inputValue.toLowerCase()) ||
            user.phone.toLowerCase().includes(inputValue.toLowerCase()) ||
            user.role.toLowerCase().includes(inputValue.toLowerCase()) ||
            getParentPartnerName(user.partner?.parentPartnerId)!.toLowerCase().includes(inputValue.toLowerCase()) ||
            user.partner?.name.toLowerCase().includes(inputValue.toLowerCase())
            // || formatDate(user.creationDate)!.includes(inputValue.toLowerCase())
        );

        setUsers(filteredResults);
    };

    function RoleRenderer({ role }: any) {
        let roleColor = 'black';
        switch (role) {
            case 'LSAdmin':
            case 'EMSAdmin':
                roleColor = isDark ? '#f19ea6' : 'red';
                break;
            case 'Direction':
                roleColor = isDark ? "#e7a2e7" : 'purple';
                break;
            case 'Commercial':
                roleColor = isDark ? "white" : 'purple';
        }
        return (
            <div style={{ fontWeight: '500', fontSize: '13px', color: roleColor }}>
                {getRole(role)}
            </div>
        )
    }

    return (
        <div>
            <Container>
                <Row className="mt-2 mb-3">
                    <Col className="align-items-center">
                        <Text size={36} b>Utilisateurs</Text>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col
                        xs={3}
                        md={3}
                        style={{ display: 'flex' }}
                        className="justify-content-end"
                    >
                        <Button onPress={() => setShowSignInModal(true)} className={isDark ? "newUser-button-dark" : ""}>
                            <FiPlus />
                            Nouvel utilisateur
                        </Button>
                    </Col>
                    <Col
                        xs={6}
                        md={6}
                        style={{ display: 'flex' }}
                        className="justify-content-center"
                    >
                        <Input
                            width='90%'
                            type="search"
                            placeholder="Rechercher un utilisateur"
                            aria-labelledby="Recherche"
                            value={searchInput}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col
                        xs={3}
                        md={3}
                        style={{ display: 'flex' }}
                        className="justify-content-start custom__div"
                    >
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={!isDark ? "custom_table__light" : "custom_table__dark"}>
                            <ContextMenu model={actions} ref={cm} onHide={() => setContextMenuUser(undefined)} className={isDark ? 'dark-context' : 'light'} />
                            <DataTable stripedRows columnResizeMode="expand" resizableColumns paginator rows={10} value={users}
                                removableSort tableStyle={{ minWidth: '50rem' }} sortMode="multiple" selectionMode="single"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[10, 25, 50]}
                                emptyMessage="Aucun utilisateur." currentPageReportTemplate="Utilisateur {first} jusqu'à {last} sur {totalRecords}"
                                onContextMenu={(e) => cm.current?.show(e.originalEvent)}
                                contextMenuSelection={contextMenuUser || undefined} onContextMenuSelectionChange={(e) => setContextMenuUser(e.value)}
                            >
                                <Column aria-labelledby="firstname" field="firstName" header="Prénom" sortable style={{ fontWeight: "500" }} ></Column>
                                <Column aria-labelledby="lastname" field="lastName" header="Nom" sortable style={{ fontWeight: "500" }} ></Column>
                                <Column aria-labelledby="email" field="email" header="Email" sortable />
                                <Column aria-labelledby="phone" field="phone" header="Téléphone" sortable />
                                <Column aria-labelledby="role" field="role" header="Rôle" className="getRoleClassName()" body={(rowData) => <RoleRenderer role={rowData.role} />} sortable />
                                <Column aria-labelledby="parentPartner" field="user.partner.parentPartnerId" header="Plaque" body={(rowData) => getParentPartnerName(rowData.partner.parentPartnerId)} sortable />
                                <Column aria-labelledby="partner" field="partner.name" header="Concession" sortable />
                                <Column aria-labelledby="creationDate" field="creationDate" header="Date de création" body={(rowData) => formatDate(rowData.creationDate)} sortable />
                            </DataTable>
                        </div>
                    </Col>
                </Row>
            </Container>
            {showSignInModal && (
                <SignIn visible={showSignInModal} onClose={closeSignInModal} />
            )}
            {showUpdateUserModal && (
                <UpdateUser visible={showUpdateUserModal} onClose={closeUpdateUserModal} user={contextMenuUser} />
            )}
            {showDeleteUserModal && (
                <DeleteUser visible={showDeleteUserModal} onClose={closeDeleteUserModal} user={contextMenuUser} />
            )}
            <Toast ref={toast} position="top-right" />
            <ContextMenu model={actions} ref={cm}></ContextMenu>
        </div>);
}

export default Users;