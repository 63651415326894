import { useSelector } from "react-redux";
import { QuoteConstants } from "../../../constants/enum";
import "./CommentIndicator.scss";
import { FaComments } from "react-icons/fa";
import { Tooltip } from "primereact/tooltip";

interface badgeProps {
  text: string;
  comment?: string;
}
function CommentIndicator({ text, comment }: badgeProps) {

  return (
    <div className="div__firstColumn">
      <div>
        <Tooltip target=".custom-tooltip-btn" position="bottom">
          <span style={{ fontSize: "12px" }}>Commentaires</span>
        </Tooltip>
        <FaComments className="custom-tooltip-btn icon__add"></FaComments>
      </div>

    </div >
  );
}

export default CommentIndicator;
