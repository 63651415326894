import axios from 'axios';
import { ProductDto } from '../dtos/product.dto';
import { Product } from '../entities/product.entity';
const API_URL = process.env.REACT_APP_API_URL;

export async function getProducts() {
    return axios.get(API_URL + 'products', { withCredentials: true });
};
export async function addProduct(product: ProductDto) {
    return axios.post(API_URL + 'products', product, { withCredentials: true });
}
