import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import { Deal } from "../../../entities/deal.entity";
import { TabMenu } from "primereact/tabmenu";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { CircuitBreaker, DealStatus, MailType, OrderStatus } from "../../../constants/enum";
import { InstallationPerDealDto } from "../../../dtos/installation-per-deal.dto";
import { Product } from "../../../entities/product.entity";
import { getProductsPerDealByDealId, getInstallationsPerDealByDealId, updateDeal } from "../../../services/deals.service";
import { ProductPerDealDto } from "../../../dtos/product-per-deal.dto";
import { Text } from "@nextui-org/react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import UploadQuote from "../../deals/confirmation/upload/UploadQuote";
import { Calendar } from "primereact/calendar";
import { Quote } from "../../../entities/quote.entity";
import { MailDto } from "../../../dtos/mail.dto";
import { sendMail } from "../../../services/mail.service";
import { User } from "../../../entities/user.entity";
import { Toast } from "primereact/toast";
import { formatDate } from "../../../helper/dateFormattor";
import { updateOrder } from "../../../services/orders.service";
import "./ActionModal.scss"
import { MenuItem } from "primereact/menuitem";
import { createSignatureRequest } from "../../../services/yousign.service";

interface ActionModalProps {
    open: boolean;
    onClose: () => void;
    deal?: Deal;
    index?: number;

}

function ActionModal({ open, onClose, deal, index }: ActionModalProps) {
    const loggedUser = useSelector((state: any) => state.login.loggedUser) as User;
    const isDark = useSelector((state: any) => state.theme.isDark) as boolean;
    const [activeIndex, setActiveIndex] = useState(index ? index : 0);
    const [productsPerDeal, setProductsPerDeal] = useState<ProductPerDealDto[]>([]);
    const [selectedAccessories, setSelectedAccessories] = useState<Product[]>([]);
    const [estimatedDistance, setEstimatedDistance] = useState<number>();
    const [circuitBreakerType, setCircuitBreakerType] = useState<string>();
    const [selectedChargingStation, setSelectedChargingStation] = useState<Product>();
    const [dealAccessories, setDealAccesories] = useState<Product[]>([]);
    const [codeBPU, setCodeBPU] = useState('');
    const [reason, setReason] = useState('');
    const [vtDate, setVtDate] = useState(new Date());
    const [filesUploaded, setFilesUploaded] = useState<boolean>(false);
    const toast = useRef<Toast | null>(null);
    const [showAddCommentModal, setShowAddCommentModal] = useState(false);
    const [showViewCommentModal, setShowViewCommentModal] = useState(false);
    const [selectedDeal, setSelectedDeal] = useState<Deal>();
    useEffect(() => {
        (
            async () => {
                await getProductsPerDealByDealId(deal?.id!)
                    .then(response => {
                        if (response.data.length) {
                            setProductsPerDeal(response.data);
                            if (!circuitBreakerType) {
                                let cbType = CircuitBreaker.Mono;
                                let res = response.data.find((ppd: any) => ppd.product.id == 2);
                                if (res) {
                                    cbType = res.quantity == 1 ? CircuitBreaker.Mono : CircuitBreaker.Tri;
                                }
                                setCircuitBreakerType(cbType);
                            }
                            if (!selectedChargingStation) {
                                let res = response.data.find((ppd: any) => ppd.product.category == 'Borne');
                                if (res) {
                                    setSelectedChargingStation(res.product);
                                }

                            }
                            let accessories = productsPerDeal.filter(ppd => ppd.product.category !== 'Borne').map(ppd => ppd.product);
                            setDealAccesories(accessories);
                        }
                    })
                    .catch(err => console.error(err));

                await getInstallationsPerDealByDealId(deal?.id!)
                    .then(response => {
                        if (response.data.length) {
                            if (!estimatedDistance) {
                                let mainInstallation = response.data.find((ipd: InstallationPerDealDto) => ipd.installationDetail.maxInstallationDistance !== null && ipd.installationDetail.minInstallationDistance !== null).installationDetail;
                                let totalDistance = mainInstallation.maxInstallationDistance;
                                if (response.data.length > 1) {
                                    let extraInstallation = response.data.find((ipd: InstallationPerDealDto) => ipd.installationDetail.maxInstallationDistance === null && ipd.installationDetail.minInstallationDistance);
                                    totalDistance += extraInstallation.quantity;
                                }
                                setCodeBPU(mainInstallation.codeBPU)
                                setEstimatedDistance(totalDistance);
                            }
                        }
                    })
                    .catch(err => console.error(err));
            }
        )();
    }, [productsPerDeal.length, selectedAccessories.length, estimatedDistance, circuitBreakerType]);

    function getItems(): MenuItem[] {
        let items = [
            { label: 'Client', icon: 'pi pi-fw pi-user' },
            { label: 'Commande', icon: 'pi pi-fw pi-calendar' },

        ];
        if (deal?.order?.status) {
            items.push({ label: 'Installation', icon: 'pi pi-fw pi-calendar' })
        }

        return items;
    }

    async function getQuote() {
        let resPpd = await getProductsPerDealByDealId(deal?.id!);
        var cbType = CircuitBreaker.Mono;
        if (resPpd.data.length) {
            var cbType = CircuitBreaker.Mono;
            let res = resPpd.data.find((ppd: any) => ppd.product.id == 2);
            if (res) {
                cbType = res.quantity == 1 ? CircuitBreaker.Mono : CircuitBreaker.Tri;
            }
        }

        let resIpd = await getInstallationsPerDealByDealId(deal?.id!);
        if (resIpd.data.length) {
            let mainInstallation = resIpd.data.find((ipd: InstallationPerDealDto) => ipd.installationDetail.maxInstallationDistance !== null && ipd.installationDetail.minInstallationDistance !== null).installationDetail;
            var totalDistance = mainInstallation.maxInstallationDistance;
            if (resIpd.data.length > 1) {
                let extraInstallation = resIpd.data.find((ipd: InstallationPerDealDto) => ipd.installationDetail.maxInstallationDistance === null && ipd.installationDetail.minInstallationDistance);
                totalDistance += extraInstallation.quantity;
            }
        }

        let quote: Quote = {
            typeOfPerson: deal?.clientId?.type!,
            housing: '',
            circuitBreaker: cbType!,
            distance: totalDistance! + 'm',
            client: deal?.clientId,
            dealId: deal?.id!,
            deal: deal
        };

        return quote;
    }

    async function validate(status: DealStatus | OrderStatus) {
        if ([DealStatus.ToEvaluate, DealStatus.VisitToDo].includes(deal!.status as DealStatus)) {
            deal!.installerName = loggedUser.lastName;
        }

        let mail = { deal } as MailDto;
        switch (status) {
            case DealStatus.ToSign:
                deal!.status = status;
                let quote = await getQuote();
                mail.mailType = MailType.SIGN_CLIENT
                mail.quote = quote;
                break;
            case DealStatus.ToAmend:
                deal!.status = status;
                mail.mailType = MailType.AMEND_EMS;
                deal!.comment = reason;
                break;
            case DealStatus.ToDraft:
                deal!.status = status;
                mail.mailType = MailType.DRAFT_EMS;
                break;
            case OrderStatus.Installed:
                deal!.commandStatus = status
                let order = deal!.order;
                order!.status = status;
                order!.updateDate = new Date().toString();
                order!.updatedBy = loggedUser.email;
                await updateOrder(order!);
                mail.mailType = MailType.INSTALLED_CLIENT;
                break;
            case DealStatus.Unachievable:
                deal!.status = status;
                deal!.withdrawReason = reason;
                break;
            case DealStatus.Unreachable:
                if (deal?.relaunch) {
                    if (deal.relaunch < 3) {
                        deal.relaunch = deal.relaunch + 1;
                        if (deal.relaunch === 3) {
                            deal!.status = status;
                            mail.mailType = MailType.UNREACHABLE_CLIENT;
                        }
                    }
                } else {
                    deal!.relaunch = 1;
                }
                break;
        }

        deal!.updateDate = new Date();
        deal!.updatedBy = loggedUser.email;
        delete deal!.order;

        updateDeal(deal!).then(() => {
            if (mail.mailType) {
                if (mail.mailType !== MailType.SIGN_CLIENT) {
                    sendMail(mail);
                } else {
                    createSignatureRequest(mail.quote!);
                }
            }
            onClose();
        });
    }

    const onUploadedFile = (data: string) => {
        setFilesUploaded(true);
        if (toast.current) {
            toast.current.show({ severity: 'success', summary: 'Fichier uploadé avec', life: 6000 });
        }
    }


    return (
        <>
            <Dialog
                visible={open} onHide={onClose}
                closable={true}
                dismissableMask={true}
                showHeader={false}
                closeOnEscape={true}
                style={{ width: '40vw', padding: "0px", borderRadius: "18px" }
                }
                className={isDark ? 'modal-dark' : 'modal-light'}
                breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                contentStyle={{
                    padding: '0px', borderRadius: "18px", background: isDark ? "#2a323d" : "#fff",
                    color: isDark ? "rgba(255,255,255,.87)" : "#495057"
                }}>
                <div className="modal-body" style={{ padding: ".75rem 1rem 1rem 1rem" }}>
                    {/* <div className="div__close">
                        <Button icon="pi pi-times" rounded text severity="secondary" style={{ padding: "10px", marginBottom: '5px', width: '16px', height: '16px' }} onClick={onClose}></Button>
                    </div> */}
                    <TabMenu model={getItems()} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} style={{ width: "100%" }} />
                    <Container style={{ paddingTop: "10px" }}>
                        {activeIndex === 0 &&
                            <>
                                <div className="container__inputs">
                                    <div className="div__input">
                                        <div>
                                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                                <i className="pi pi-user" />
                                                <InputText
                                                    id="lastName"
                                                    name="lastName"
                                                    placeholder="Nom"
                                                    value={deal?.clientId?.lastName}
                                                    disabled
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="div__input">
                                        <div>
                                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                                <i className="pi pi-user" />
                                                <InputText
                                                    id="firstName"
                                                    name="firstName"
                                                    placeholder="Prénom"
                                                    value={deal?.clientId?.firstName}
                                                    disabled
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <div className="container__inputs">
                                    <div className="div__input">
                                        <div>
                                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                                <i className="pi pi-phone" />
                                                <InputText
                                                    id="phone"
                                                    name="phone"
                                                    placeholder="Numéro de téléphone"
                                                    value={deal?.clientId?.phone}
                                                    disabled
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="div__input">
                                        <div>
                                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                                <i className="pi pi-envelope" />
                                                <InputText
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    value={deal?.clientId?.email}
                                                    disabled
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                                <div className="div__input">
                                    <span className="span__section" style={{ marginTop: "10px" }}>Adresse de facturation</span>
                                    <div>
                                        <span className="p-input-icon-left" style={{ width: "100%" }}>
                                            <i className="pi pi-home" />
                                            <InputText
                                                id="address"
                                                name="address"
                                                placeholder="Adresse"
                                                value={deal?.clientId?.address}
                                                disabled
                                                style={{ width: "100%" }}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="div__input">
                                    <div className="container__inputs">
                                        <div className="div__input">
                                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                                <i className="pi pi-map" />
                                                <InputText
                                                    id="zipCode"
                                                    name="zipCode"
                                                    placeholder="Code postal"
                                                    value={deal?.clientId?.zipCode}
                                                    disabled
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        </div>
                                        <div className="div__input">
                                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                                <i className="pi pi-map" />
                                                <InputText
                                                    id="city"
                                                    name="city"
                                                    placeholder="Ville"
                                                    value={deal?.clientId?.city}
                                                    disabled
                                                    style={{ width: "100%" }}
                                                />
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }
                        {
                            activeIndex === 1 && deal?.clientId?.type === 'Particulier' &&
                            <>
                                <Row style={{ paddingRight: "0px" }}>
                                    <Row style={{ paddingRight: "0px" }}>
                                        <Col ><Text size={14} style={{ float: 'left', margin: '0' }}>{selectedChargingStation?.type}</Text></Col>
                                        <Col style={{ paddingRight: "0px" }}>
                                            <Text size={12} style={{ float: 'right', margin: '0', fontWeight: "500", letterSpacing: "0.3px" }}>{selectedChargingStation?.brand} - {selectedChargingStation?.name}</Text>
                                        </Col>

                                    </Row>
                                </Row>
                                <Row>
                                    <Col>
                                        <Text size={14} style={{ float: 'left', margin: '0' }}>Accessoires</Text>
                                    </Col>
                                    <Col>
                                        {dealAccessories.map((acc) => {
                                            return (
                                                <Row>
                                                    <Col>
                                                        <Text size={12} style={{ float: 'right', margin: '0', fontWeight: "500", letterSpacing: "0.3px" }}>{acc.type} {acc.brand}</Text>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        )}
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col>
                                        <Text size={14} style={{ float: 'left', margin: '0' }}>Protection</Text>
                                    </Col>
                                    <Col>
                                        <Text size={12} style={{ float: 'right', margin: '0', fontWeight: "500", letterSpacing: "0.3px" }}>{circuitBreakerType}</Text>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col>
                                        <Text size={14} style={{ float: 'left', margin: '0' }}>Distance</Text>
                                    </Col>
                                    <Col>
                                        <Text size={12} style={{ float: 'right', margin: '0', fontWeight: "500", letterSpacing: "0.3px" }}>{estimatedDistance}m</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Text size={14} style={{ float: 'left', margin: '0' }}>Code BPU</Text>
                                    </Col>
                                    <Col>
                                        <Text size={12} style={{ float: 'right', margin: '0', fontWeight: "500", letterSpacing: "0.3px" }}>{codeBPU}</Text>
                                    </Col>
                                </Row>
                                {(deal?.status === DealStatus.ToEvaluate || (deal?.status === DealStatus.Unreachable && loggedUser.role === 'EMSAdmin')) &&
                                    <>
                                        <Row style={{ marginBottom: '10px' }}>
                                            <Col className="reason__div">
                                                <InputTextarea id="reason" placeholder="Raison" autoResize value={reason} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)} rows={2} cols={30}></InputTextarea>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="installation__customButton">
                                                <Button
                                                    style={{
                                                        color: "black",
                                                        padding: "10px",
                                                        fontSize: '11px',
                                                        width: '100%',
                                                    }}
                                                    severity={"success"}
                                                    aria-labelledby="annuler" label="Valider en l'état"
                                                    onClick={() => validate(DealStatus.ToSign)} />
                                            </Col>
                                            <Col className="installation__customButton">
                                                <Button
                                                    style={{
                                                        color: "black",
                                                        padding: "10px",
                                                        fontSize: '11px',
                                                        width: '100%',
                                                    }}
                                                    severity={"warning"}
                                                    disabled={deal.relaunch === 3}
                                                    aria-labelledby="valider" label="Appel sans réponse"
                                                    onClick={() => validate(DealStatus.Unreachable)} />
                                            </Col>
                                            <Col className="installation__customButton">
                                                <Button
                                                    style={{
                                                        color: "black",
                                                        padding: "10px",
                                                        fontSize: '11px',
                                                        width: '100%',
                                                    }}
                                                    severity={"warning"}
                                                    disabled={!reason}
                                                    aria-labelledby="valider" label="Demander une modification"
                                                    onClick={() => validate(DealStatus.ToAmend)} />
                                            </Col>
                                            <Col className="installation__customButton">
                                                <Button
                                                    style={{
                                                        color: "black",
                                                        padding: "10px",
                                                        fontSize: '11px',
                                                        width: '100%',
                                                    }}
                                                    severity={"danger"}
                                                    disabled={!reason}
                                                    aria-labelledby="valider" label="Non réalisable"
                                                    onClick={() => validate(DealStatus.Unachievable)} />
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </>
                        }
                        {
                            activeIndex === 1 && deal?.status === DealStatus.VisitToDo &&
                            <>
                                <Row style={{ marginBottom: '10px', alignItems: "center" }}>
                                    <Col>
                                        <Text size={14} style={{ float: 'left', margin: '0', fontWeight: '500', letterSpacing: '0.1px' }}>Date de la VT</Text>
                                    </Col>
                                    <Col>
                                        <Calendar value={vtDate} minDate={new Date()} style={{ float: 'right', margin: '0', fontWeight: "500", letterSpacing: "0.3px" }} />
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '10px' }}>
                                    <Col className="reason__div">
                                        <InputTextarea id="reason" placeholder="Raison" autoResize value={reason} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)} rows={2} cols={30}></InputTextarea>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <UploadQuote onUploadedFile={(data) => { onUploadedFile(data) }}></UploadQuote>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            style={{
                                                color: "black",
                                                padding: "10px",
                                                fontSize: '12px',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                            severity={"success"}
                                            disabled={!filesUploaded}
                                            aria-labelledby="valider" label="VT à réaliser"
                                            onClick={() => validate(DealStatus.ToDraft)} />
                                    </Col>
                                    <Col className="installation__customButton">
                                        <Button
                                            style={{
                                                color: "black",
                                                padding: "10px",
                                                fontSize: '11px',
                                                width: '100%',
                                            }}
                                            severity={"warning"}
                                            disabled={deal.relaunch === 3}
                                            aria-labelledby="valider" label="Appel sans réponse"
                                            onClick={() => validate(DealStatus.Unreachable)} />
                                    </Col>
                                    <Col>
                                        <Button
                                            style={{
                                                color: "black",
                                                padding: "10px",
                                                fontSize: '12px',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                            severity={"danger"}
                                            disabled={!reason}
                                            aria-labelledby="valider" label="Non réalisable"
                                            onClick={() => validate(DealStatus.Unachievable)} />
                                    </Col>
                                </Row>
                            </>
                        }
                        {
                            activeIndex === 2 && deal?.order?.status &&
                            <>
                                <Row style={{ marginBottom: '10px' }}>
                                    <Col>
                                        <Text size={14} style={{ float: 'left', margin: '0' }}>Date d'installation</Text>
                                    </Col>
                                    <Col>
                                        <Text size={14} style={{ float: 'left', margin: '0' }}>{formatDate(deal!.order!.installationDate!.toString())}</Text>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '10px' }}>
                                    <Col>
                                        <Text size={14} style={{ float: 'left', margin: '0' }}>Installateur</Text>
                                    </Col>
                                    <Col>
                                        <Text size={14} style={{ float: 'left', margin: '0' }}>{loggedUser.firstName}</Text>
                                    </Col>
                                </Row>
                                {deal?.order?.status === OrderStatus.Planned &&
                                    <>
                                        <Row>
                                            <Col>
                                                <UploadQuote onUploadedFile={(data) => { onUploadedFile(data) }}></UploadQuote>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button
                                                    style={{
                                                        color: "black",
                                                        padding: "10px",
                                                        fontSize: '12px',
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                    severity={"success"}
                                                    disabled={!filesUploaded}
                                                    aria-labelledby="valider" label="Facturer"
                                                    onClick={() => validate(OrderStatus.Installed)} />
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </>
                        }
                    </Container>
                </div>
            </Dialog >
            <Toast ref={toast} position="top-right" />
        </>
    );
}

export default ActionModal;