import { Button, Dropdown, Input, Modal, Text } from "@nextui-org/react";
import { User } from "../../../entities/user.entity";
import { getPartners } from "../../../services/partners.service";
import { useSelector } from "react-redux";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Partner } from "../../../entities/partner.entity";
import { FiMail, FiPhone, FiUser } from "react-icons/fi";
import { updateUser } from "../../../services/users.service";
import { getRole } from "../../../helper/roleGetter";

interface UpdateUserProps {
  visible: boolean;
  onClose: (data?: string) => void;
  user?: User;
}

interface Form {
  lastname?: string;
  firstname?: string;
  phone?: string;
  email?: string;
}
function UpdateUser({ visible, onClose, user }: UpdateUserProps) {
  const loggedUser = useSelector((state: any) => state.login.loggedUser);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<Partner | undefined>(user?.partner);
  const [selectedRole, setSelectedRole] = useState<string | undefined>(user?.role);
  const [concessions, setConcessions] = useState<Partner[]>([]);
  const [input, setInput] = useState<Form>({
    lastname: user?.lastName,
    firstname: user?.firstName,
    phone: user?.phone,
    email: user?.email
  });
  const [error, setError] = useState<Form>({
    lastname: '',
    firstname: '',
    phone: '',
    email: ''
  });

  const loadPartners = async () => {
    await getPartners()
      .then(response => {
        setConcessions(response.data.filter((partner: Partner) => partner.subPartners.length === 0));
        setPartners(response.data);
      })
      .catch(err => console.error('Disconnected'));
  };

  useEffect(() => {
    (
      async () => {
        await loadPartners();
      }
    )();
  }, []);

  function onInputChange(event: any) {
    const { name, value } = event.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(event);
  }

  function validateInput(event: BaseSyntheticEvent) {
    let { name, value } = event.target;
    setError(prev => {
      const stateObj: Form = { ...prev, [name]: '' };
      switch (name) {
        case "lastname":
          if (!value) {
            stateObj[name as keyof Form] = "Veuillez entrer un nom.";
          }
          break;

        case "firstname":
          if (!value) {
            stateObj[name as keyof Form] = "Veuillez entrer un prénom.";
          }
          break;

        case "username":
          if (!value) {
            stateObj[name as keyof Form] = "Veuillez entrer un nom d'utilisateur.";
          }
          break;

        case 'phone':
          if (!value) {
            stateObj[name as keyof Form] = "Veuillez entrer un numéro de téléphone.";
          }
          break;
        case 'email':
          if (!value) {
            stateObj[name as keyof Form] = "Veuillez entrer une adresse mail.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  }

  function selectPartner(event: any) {
    let partnerId = Array.from(event)[0] as number;
    const newPartner = concessions.find(partner => partner.id == partnerId);
    setSelectedPartner(newPartner);
  }

  function selectRole(event: any) {
    let role = Array.from(event)[0] as string;
    setSelectedRole(role);
  }

  function closeHandler() {
    onClose();
  }

  function selectableRoles(): string[] {
    switch (loggedUser.role) {
      case 'Direction':
        return ['Commercial', 'Direction'];
      case 'LSAdmin':
        return ['Commercial', 'Direction', 'LSAdmin'];
      case 'EMSAdmin':
        return ['Commercial', 'Direction', 'LSAdmin', 'EMSAdmin', 'Installer', 'InstallerManager'];
      case 'InstallerManager':
        return ['Installer'];
      default:
        return [];
    }
  }

  function canSubmit(): boolean {
    let res = input.email !== '' && input.firstname !== '' && input.lastname !== '' && input.phone !== '';
    return res;
  }

  function submit() {


    if (user) {
      user!.email = input.email!;
      user!.lastName = input.lastname!;
      user!.firstName = input.firstname!;
      user!.phone = input.phone!;
      user!.role = selectedRole!;
      user!.partner = selectedPartner!;
    }

    updateUser(user!)
      .then(res => {
        onClose('Success')
      });
  }

  return (
    <div>
      <Modal
        closeButton
        blur
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
        width="450px"
      >
        <Modal.Header>
          <Text b id="modal-title" size={18}>
            Modifier utilisateur
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Input
            aria-label='lastname'
            name='lastname'
            clearable
            bordered
            fullWidth
            color="primary"
            size="lg"
            placeholder="Nom"
            contentLeft={<FiUser />}
            onChange={onInputChange}
            onBlur={validateInput}
            initialValue={user?.lastName}
          />
          {error.lastname && <small className='err'>{error.lastname}</small>}
          <Input
            aria-label='firstname'
            name='firstname'
            clearable
            bordered
            fullWidth
            color="primary"
            size="lg"
            placeholder="Prénom"
            contentLeft={<FiUser />}
            onChange={onInputChange}
            onBlur={validateInput}
            initialValue={user?.firstName}
          />
          {error.firstname && <small className='err'>{error.firstname}</small>}
          <Input
            aria-label='phone'
            name='phone'
            clearable
            bordered
            fullWidth
            color="primary"
            size="lg"
            placeholder="Téléphone"
            contentLeft={<FiPhone />}
            onChange={onInputChange}
            onBlur={validateInput}
            initialValue={user?.phone}
          />
          {error.phone && <small className='err'>{error.phone}</small>}
          <Input
            aria-label='email'
            name='email'
            clearable
            bordered
            fullWidth
            color="primary"
            size="lg"
            placeholder="Email"
            contentLeft={<FiMail size={20} />}
            onChange={onInputChange}
            onBlur={validateInput}
            initialValue={user?.email}
            style={{ color: "#fcfcfc82" }}
            disabled
          />
          {error.email && <small className='err'>{error.email}</small>}
          <Dropdown>
            <Dropdown.Button light>{selectedPartner?.name}</Dropdown.Button>
            <Dropdown.Menu
              aria-label="Concession"
              selectionMode="single"
              onSelectionChange={(e) => { selectPartner(e) }}>
              {
                concessions.map(partner => {
                  return <Dropdown.Item key={partner.id}>{partner.name}</Dropdown.Item>
                })
              }
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Button light>{getRole(selectedRole)}</Dropdown.Button>
            <Dropdown.Menu
              aria-label="Role"
              selectionMode="single"
              onSelectionChange={(e) => { selectRole(e) }}>
              {
                selectableRoles().map(role => {
                  return <Dropdown.Item key={role}>{getRole(role)}</Dropdown.Item>
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button onPress={submit} auto disabled={!canSubmit()} >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default UpdateUser;