import { useEffect, useState } from "react";
import { Button, Row, Text } from "@nextui-org/react";
import "./UpdateOrder.scss";
import { Order } from "../../../entities/order.entity";
import { Calendar } from 'primereact/calendar';
import { updateOrder, updateOrderStatus } from "../../../services/orders.service";
import { OrderStatus } from "../../../constants/enum";
import { Dialog } from "primereact/dialog";
import { UppercaseFormatter } from "../../../helper/uppercaseFormattor";
import { getProductsPerDealByDealId } from "../../../services/deals.service";
import { ProductPerDealDto } from "../../../dtos/product-per-deal.dto";
import { formatDate } from "../../../helper/dateFormattor";
import CustomButton from "../../shared/Buttons/customButton";
import { Deal } from "../../../entities/deal.entity";
import "../../shared/DarkMode.scss";
import { useSelector } from "react-redux";

interface UpdateProps {
    open: boolean;
    onClose: () => void;
    order: Order;
    handleSave: (data: boolean) => void;

}

function isWeekend(date: Date): boolean {
    const day = date.getDay();
    return day === 0 || day === 6;
}

function addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function calculateSelectableDate(deal: Deal | null): Date {
    const currentDate = new Date(deal?.creationDate ? deal.creationDate : new Date());
    let workingDaysRemaining = 10;
    let currentDateCopy = new Date(currentDate);

    while (workingDaysRemaining > 0) {
        currentDateCopy = addDays(currentDateCopy, 1);

        if (!isWeekend(currentDateCopy)) {
            workingDaysRemaining--;
        }
    }

    return currentDateCopy;
}

function UpdateOrder({ open, onClose, order, handleSave }: UpdateProps) {
    const isDark = useSelector((state: any) => state.theme.isDark) as boolean;
    const [productsPerDeal, setProductsPerDeal] = useState<ProductPerDealDto[]>([]);
    const [station, setStation] = useState<ProductPerDealDto>();
    const [accessories, setAccessories] = useState<ProductPerDealDto[]>();
    const [showEdit, setShowEdit] = useState(false);
    const [date, setDate] = useState<Date>();

    const handleEdit = async () => {

        if (date) {
            var updatedOrder: Order = {
                ...order,
                installationDate: new Date(date)
            };
        } else {
            var updatedOrder: Order = {
                ...order
            }
        }

        try {
            onClose();
            const res = await updateOrder(updatedOrder);
            let respone = res.data;
            if (respone) {
                updateOrderStatus(respone.id, OrderStatus.Planned)
                    .then(res => {
                        handleSave(true);
                    })
                    .catch(err => { handleSave(false); });
                // .finally(() => sendOrderForm(order));
            }
        } catch (err) {
            console.error(err);
        }
    }

    const handleClickEdit = () => {
        setShowEdit(true)
    }

    useEffect(() => {
        (

            async () => {
                if (order?.deal?.id) {
                    await getProductsPerDealByDealId(order.deal.id)
                        .then(response => {
                            setProductsPerDeal(response.data as ProductPerDealDto[]);
                        })
                        .catch(err => console.error('Disconnected'));
                }
            }
        )();
        let product = productsPerDeal.find(ppd => ppd.product.category === 'Borne')
        let accessories = productsPerDeal.filter(ppd => ppd.product.category === 'accessoire' || ppd.product.category === 'Cable')
        setStation(product)
        // setAccessories(accessories)
    }, [productsPerDeal.length]);
    return (

        <Dialog
            header="Confirmer la date d'intallation de la commande"
            headerStyle={{ padding: "9px 24px", paddingTop: "20px", borderTopLeftRadius: "18px", borderTopRightRadius: "18px", background: isDark ? "#2a323d" : "#fff", color: isDark ? "rgba(255,255,255,.87)" : "#495057" }}
            visible={open} onHide={onClose}
            closable={false}
            closeOnEscape={true}
            dismissableMask={true}
            style={{ width: '38vw', padding: "0px", borderRadius: "18px" }}
            className={isDark ? 'modal-dark' : 'modal-light'}
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            contentStyle={{ padding: '0px', borderBottomLeftRadius: "18px", borderBottomRightRadius: "18px", background: isDark ? "#2a323d" : "#fff", color: isDark ? "rgba(255,255,255,.87)" : "#495057" }}
        >

            <div className="modal-body" style={{ padding: "10px", marginTop: "20px" }}>
                <Row>
                    <Button flat auto rounded color="primary" css={{ h: "30px", cursor: "pointer", marginTop: "-30px", marginLeft: "10px" }} >
                        <Text css={{ color: "inherit" }} size={12} className={isDark ? "header__badge--dark" : "header__badge"}>{order.clientId.firstName} {UppercaseFormatter(order.clientId.lastName)}</Text>
                    </Button>
                    <Button flat auto rounded color="primary" css={{ h: "30px", cursor: "pointer", marginTop: "-30px", marginLeft: "10px" }} >
                        <Text css={{ color: "inherit" }} size={12} className={isDark ? "header__badge--dark" : "header__badge"}>{station?.product.type} </Text>
                    </Button>
                    <Button flat auto rounded color="primary" css={{ h: "30px", cursor: "pointer", marginTop: "-30px", marginLeft: "10px" }} >
                        <Text css={{ color: "inherit" }} size={12} className={isDark ? "header__badge--dark" : "header__badge"}>{station?.product.name} </Text>
                    </Button>
                </Row>
                <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "30px", padding: "0 15px" }}>
                    <span className="span__dateTitle">Date d'installation estimée</span>
                    <span className="span__date">  {formatDate(order?.installationDate?.toString())}</span>
                </Row>
                <Row>
                    <span className={isDark ? 'span__link-dark' : 'span__link'} onClick={handleClickEdit}>Modifier la date</span>
                </Row>
                {showEdit && <div className="div__calendar">
                    <span className="span__dateTitle">Nouvelle date d'installation</span>
                    <Calendar
                        value={date}
                        onChange={(e: any) => setDate(e.value)}
                        dateFormat="dd-mm-yy"
                        minDate={calculateSelectableDate(order?.deal ? order.deal : null)}
                        showIcon />
                </div>}
            </div>
            <div className="div__footer">
                <div onClick={onClose}>
                    <CustomButton title="Annuler" />
                </div>
                <div onClick={handleEdit}>
                    <CustomButton title="Confirmer" />
                </div>
            </div>
        </Dialog>
    );
}

export default UpdateOrder;
