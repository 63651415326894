import { useState } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { RadioButton } from "primereact/radiobutton";
import { useFormik } from "formik";
import "./MapAlternative.scss"
import { Button } from "primereact/button";

interface FormData {
    estimatedDistance: number;
}
interface MapAlternativeProps {
    handleValidate: (data: number) => void;
}

function MapAlternative({ handleValidate }: MapAlternativeProps) {
    const [validated, setValidated] = useState(false);
    const formik = useFormik<FormData>({
        initialValues: {
            estimatedDistance: 0,
        },
        validate: (data) => {
            const errors: Partial<FormData> = {};

            return errors;
        },
        onSubmit: async (data) => {
            handleValidate(data.estimatedDistance);
            formik.resetForm();
        },
    });

    const distanceRadioButtons = [
        {
            id: 'five',
            name: 'distance',
            value: 'Moins de 5m',
            inputId: '4',
        },
        {
            id: 'ten',
            name: 'distance',
            value: 'Moins de 10m',
            inputId: '9',
        },
        {
            id: 'fifteen',
            name: 'distance',
            value: 'Moins de 15m',
            inputId: '14'
        },
        {
            id: 'twentyfive',
            name: 'distance',
            value: 'Moins de 25m',
            inputId: '24'
        },
        {
            id: 'twentyfiveplus',
            name: 'distance',
            value: 'Plus que 25m',
            inputId: '25'
        },]

    return (
        <div >
            <div className="custom__container">
                <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                    <div className="radioButton__distance">
                        {distanceRadioButtons.map((btn, i) => (
                            <div key={i} className="item-spacing">
                                <RadioButton
                                    {...btn}
                                    checked={formik.values.estimatedDistance === Number(btn.inputId)}
                                    onChange={() => {
                                        formik.setFieldValue('estimatedDistance', Number(btn.inputId));
                                        setValidated(true);
                                    }}
                                />
                                <label htmlFor={btn.inputId} className="ml-1">
                                    {btn.value}
                                </label>
                            </div>
                        ))}
                        <div className="submitManualInputButton">
                            <Button type="submit" severity="success" aria-labelledby="valider" disabled={!validated}>
                                Valider
                            </Button>
                        </div>

                    </div>
                </form>


            </div>

        </div>
    );
}

export default MapAlternative;
