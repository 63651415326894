const initialState = {
  loggedUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : undefined
};

const LoginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...state,
        loggedUser: action.payload
      };
    default:
      return state;
  }
};

export default LoginReducer;
