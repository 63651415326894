import { useEffect, useState } from "react";
import { Text } from "@nextui-org/react";
import SharedCard from "../../../shared/SharedCard";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, updateStep1Data } from "../../../../redux/actions/StepsActions";
import individual from "../../../../assets/quote-icons/individual.png";
import pro from "../../../../assets/quote-icons/pro.png";
import "../Step.scss";
import { QuoteConstants } from "../../../../constants/enum";
import { FaUser, FaUsers } from "react-icons/fa";

function Step1() {
  const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);

  useEffect(() => {
    const handleResize = () => {
      setIsXSmallScreen(window.innerWidth < 650);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const widthClass = isXSmallScreen ? "150px" : "200px";

  const dispatch = useDispatch();
  const selectedCard = useSelector(
    (state: any) => state.steps.selectedCards.step1
  );

  const handleCardSelect = (selectedData: any) => {
    dispatch(updateStep1Data(selectedData));
    dispatch(nextStep());
  };

  const isDark = useSelector((state: any) => state.theme.isDark);

  return (
    <div className="custom__container">
      <Text className="step__title"> Votre client est ? </Text>
      <div className="content__container">
        <div
          onClick={() => handleCardSelect(QuoteConstants.individual)}
          className={selectedCard === QuoteConstants.individual ? "card__selected" : ""}>
          <SharedCard
            title={QuoteConstants.individual}
            icon={<FaUser size="100%" className="w-6 shadow-2" />}
            width={widthClass}
            layout="square" />
        </div>
        <div
          onClick={() => handleCardSelect(QuoteConstants.professional)}
          className={selectedCard === QuoteConstants.professional ? "card__selected" : ""}>
          <SharedCard
            title={QuoteConstants.professional}
            icon={<FaUsers size="100%" className="w-6 shadow-2" />}
            width={widthClass}
            layout="square" />
        </div>
      </div>
    </div>
  );
}

export default Step1;
