import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Client } from '../../../../entities/client.entity';
import { Deal } from '../../../../entities/deal.entity';
import { DealStatus, MailType } from '../../../../constants/enum';
import { Dropdown } from 'primereact/dropdown';
import { getClients, saveClientService } from '../../../../services/clients.service';
import { InputText } from 'primereact/inputtext';
import { InstallationDetail } from '../../../../entities/installation-detail.entity';
import { InstallationPerDealDto } from '../../../../dtos/installation-per-deal.dto';
import { MailDto } from '../../../../dtos/mail.dto';
import { Product } from '../../../../entities/product.entity';
import { ProductPerDealDto } from '../../../../dtos/product-per-deal.dto';
import { QuoteConstants as quoteEnum } from '../../../../constants/enum';
import { Text } from '@nextui-org/react';
import { RadioButton } from 'primereact/radiobutton';
import { saveDeal, saveInstallationPerDeal, saveProductPerDeal } from '../../../../services/deals.service';
import { sendMail } from '../../../../services/mail.service';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import './CreateClient.scss';
import '../../../shared/DarkMode.scss';

interface FormData {
    clientStatus: string;
    client?: Client;
    name: string;
    lastname: string;
    email: string;
    phone: string;
    address: string;
    zipcode: string;
    city: string;
    quoteAdress: string;
    streetQuoteAddress: string;
    zipcodeQuoteAddress: string;
    cityQuoteAddress: string;
    comment: string;
    siretCode?: string;
    companyName?: string;
}
interface CreateProps {
    onClose: () => void;
    handleSave: (data: string) => void;
}

export default function CreateClient({ onClose, handleSave }: CreateProps) {
    const t = useSelector((state: any) => state.steps);
    const savedAddress = useSelector((state: any) => state.steps.step4Data);
    const chosenDeal = useSelector((state: any) => state.steps.step5DeltaData);
    const quoteData = useSelector((state: any) => state.steps);
    const distanceData = useSelector((state: any) => state.distance.calculatedDistance);
    const chosenAccessories = useSelector((state: any) => state.steps.step6Data);
    const loggedInPartnerUser = useSelector((state: any) => state.login.loggedUser);

    const quote = {
        typeOfPerson: quoteData.step1Data,
        housing: quoteData.step2Data,
        circuitBreaker: (quoteData.step1Data !== quoteEnum.professional || (quoteData.step1Data === quoteEnum.professional && quoteData.step2Data === quoteEnum.houseOfAPro))
            ? quoteData.step3Data : null,
        typeOfStation: quoteData.step4Data,
        distance: distanceData,
        installationAddress: '',
        installationZipCode: '',
        installationCity: '',
        comment: (quoteData.step1Data === quoteEnum.professional && quoteData.step2Data !== quoteEnum.houseOfAPro) ? quoteData.step4ProData : null
    };
    const [address, setAddress] = useState(savedAddress ? savedAddress.address : "");
    const [zipCode, setZipCode] = useState(savedAddress ? savedAddress.zipCode : "");
    const [city, setCity] = useState(savedAddress ? savedAddress.city : "");
    const [comment, setComment] = useState((quoteData.step1Data === quoteEnum.professional && quoteData.step2Data !== quoteEnum.houseOfAPro) ? quoteData.step4ProData : "");
    const [showQuoteAddressInputs, setShowQuoteAddressInputs] = useState<boolean>(false);
    const [totalAccessory, setTotalAccessory] = useState<number>(0);

    const [showPhoneDropdown, setShowPhoneDropdown] = useState<boolean>(false);
    const [clients, setClients] = useState<Client[]>([]);
    const [savedClient, setSavedClient] = useState<Client | null>(null);
    const [savedDeal, setSavedDeal] = useState<Deal>();

    const radioBtns = [
        {
            id: 'new',
            name: 'clientStatus',
            value: 'Nouveau client',
            inputId: 'new',
            checked: true,
        },
        {
            id: 'old',
            name: 'clientStatus',
            value: 'Client existant',
            inputId: 'old'
        }]

    const yesNoRadioBtns = [
        {
            id: 'same',
            name: 'quoteAddress',
            value: 'Même adresse',
            inputId: 'same',
            checked: true,
        },
        {
            id: 'no',
            name: 'quoteAdress',
            value: 'Nouvelle adresse',
            inputId: 'no'
        }]

    const formik = useFormik<FormData>({
        initialValues: {
            clientStatus: 'Nouveau client',
            name: '',
            lastname: '',
            email: '',
            phone: '',
            address: address,
            zipcode: zipCode,
            city: city,
            quoteAdress: 'Même adresse',
            streetQuoteAddress: '',
            zipcodeQuoteAddress: '',
            cityQuoteAddress: '',
            client: undefined,
            comment: comment,
            siretCode: '',
            companyName: ''
        },
        validate: (data) => {
            const errors: Partial<FormData> = {};

            if (!data.name.trim()) {
                errors.name = 'Veuillez saisir un prénom.';
            }

            if (!data.lastname.trim()) {
                errors.lastname = 'Veuillez saisir un nom.';
            }

            if (quoteData.step1Data === quoteEnum.professional) {
                if (!data.siretCode?.trim()) {
                    errors.siretCode = 'Veuillez saisir un n° Siret';
                } else if (!isValidSiret(data.siretCode)) {
                    errors.siretCode = 'Veuillez saisir un n° Siret valide';
                }

                if (!data.companyName?.trim()) {
                    errors.companyName = "Veuillez saisir le nom de l'entreprise.";
                }
            }

            if (!data.email.trim()) {
                errors.email = 'Veuillez saisir un email.';
            } else if (!isValidEmail(data.email)) {
                errors.email = 'Format d\'email invalide.';
            }

            if (!showPhoneDropdown) {

                if (!data.phone.trim()) {
                    errors.phone = 'Veuillez saisir un numéro de téléphone.';
                } else if (!isValidPhoneNumber(data.phone)) {
                    errors.phone = 'Format de numéro de téléphone invalide.';
                }

                if (!data.address.trim()) {
                    errors.address = 'Veuillez saisir une adresse.';
                }

                if (data.zipcode.length !== 5) {
                    errors.zipcode = 'Veuillez saisir un code postal valide.';
                }
            }

            return errors;
        },
        onSubmit: async (data) => {
            let priceAccessories: number = 0;
            const isProfessional = quoteData.step1Data === quoteEnum.professional && quoteData.step2Data !== quoteEnum.houseOfAPro; //if true than do not generate amount
            let oldClient = formik.values.client;
            let client = undefined;
            onClose();
            if (!showPhoneDropdown) {
                let clientToSend: Client = {
                    type: quote.typeOfPerson,
                    firstName: data.name,
                    lastName: data.lastname,
                    address: data.quoteAdress === 'Même adresse' ? data.address : data.streetQuoteAddress,
                    zipCode: data.quoteAdress === 'Même adresse' ? data.zipcode : data.zipcodeQuoteAddress,
                    city: data.quoteAdress === 'Même adresse' ? data.city : data.cityQuoteAddress,
                    phone: data.phone,
                    email: data.email,
                    creationDate: new Date(),
                    partnerId: {
                        id: loggedInPartnerUser?.id,
                        email: loggedInPartnerUser?.email,
                        phone: loggedInPartnerUser?.phone,
                        username: loggedInPartnerUser?.username,
                        firstName: loggedInPartnerUser?.firstName,
                        lastName: loggedInPartnerUser?.lastName,
                        role: loggedInPartnerUser?.role,
                    }
                }

                if (quoteData.step1Data === quoteEnum.professional) {
                    clientToSend.siretCode = data.siretCode;
                    clientToSend.companyName = data.companyName;
                }
                try {
                    const res = await saveClientService(clientToSend);
                    client = res.data;

                } catch (err) {
                    handleSave("error client");
                    console.error(err);
                }
            }

            if (!isProfessional && chosenAccessories) {
                priceAccessories = chosenAccessories.reduce((acc: number, accessory: any) => acc + Number(accessory.price), 0);
            }

            let deal: Deal = {
                clientId: showPhoneDropdown ? oldClient : client,
                amount: !isProfessional ? Number(chosenDeal.amount) + Number(priceAccessories) : 0,
                numberOfProducts: 0,
                status: !isProfessional ? DealStatus.ToEvaluate : DealStatus.VisitToDo,
                creationDate: new Date(),
                installationAddress: address,
                installationZipCode: zipCode,
                installationCity: city,
                comment: isProfessional ? comment : null,
                distance: parseInt(distanceData),
            }
            await saveDeal(deal)
                .then(res => {
                    handleSave("success deal");
                    deal.id = res.data.id;
                    setSavedDeal(deal);
                })
                .catch(err => handleSave("error deal"));


            if (!isProfessional) {
                let productToSave: Product[] = [];
                if (chosenAccessories === null) {
                    productToSave = chosenDeal.detail.products;
                } else {
                    productToSave = chosenDeal.detail.products.concat(chosenAccessories);
                }
                let productPerDealPromises = productToSave.map(
                    (product: Product) => {
                        let productPerDeal: ProductPerDealDto = {
                            product: product,
                            deal: deal,
                            quantity: 1
                        };
                        return saveProductPerDeal(productPerDeal);
                    }
                );

                await Promise.all(productPerDealPromises);

                let installationPerDealPromises = chosenDeal.detail.installation.map(
                    (installationDetail: InstallationDetail) => {
                        if (installationDetail) {
                            let installationPerDeal: InstallationPerDealDto = {
                                installationDetail: installationDetail,
                                deal: deal,
                                quantity: 1
                            };
                            return saveInstallationPerDeal(installationPerDeal);
                        }
                    }
                );

                await Promise.all(installationPerDealPromises);

                let mailClient = {
                    mailType: MailType.IND_EVALUATE_CLIENT,
                    deal: deal
                } as MailDto;

                let mailInstaller = {
                    mailType: MailType.IND_EVALUATE_INSTALLER,
                    deal: deal
                } as MailDto;

                await sendMail(mailClient);
                await sendMail(mailInstaller);
            }
            else {
                let mailClient = {
                    mailType: MailType.PRO_EVALUATE_CLIENT,
                    deal: deal
                } as MailDto;

                let mailInstaller = {
                    mailType: MailType.PRO_EVALUATE_INSTALLER,
                    deal: deal
                } as MailDto;

                await sendMail(mailClient);
                await sendMail(mailInstaller);
            }

            formik.resetForm();
        },
    });

    const isValidEmail = (email: string) => {
        const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailRegex.test(email);
    };

    const isValidPhoneNumber = (phone: string) => {
        const phoneRegex = /^\+?\d{10,}$/;
        return phoneRegex.test(phone);
    };

    const isValidSiret = (siret: string) => {
        const siretRegex = /^[0-9]{14}$/;
        return siretRegex.test(siret);
    }

    function formatPhoneNumber(phone: string): string {
        const numericPhone = phone.replace(/\D/g, '');
        const formattedPhone = numericPhone.match(/(\d{2})/g);
        if (!formattedPhone) return phone;
        else {
            return formattedPhone.join(' ');

        }
    }

    useEffect(() => {
        if (formik.values.clientStatus === "Client existant") {
            setShowPhoneDropdown(true);
            if (clients.length === 0) {
                loadClientData();
            }
        } else {
            setShowPhoneDropdown(false);
        }
        if (formik.values.quoteAdress === "Nouvelle adresse") {
            setShowQuoteAddressInputs(true);
        } else {
            setShowQuoteAddressInputs(false);
            formik.setFieldValue('streetQuoteAddress', '');
            formik.setFieldValue('zipcodeQuoteAddress', '');
            formik.setFieldValue('cityQuoteAddress', '');
        }
    }, [formik.values.clientStatus, formik.values.quoteAdress, savedClient, totalAccessory]);

    const loadClientData = () => {
        getClients()
            .then((res: any) => {
                setClients(res.data);
            })
            .catch((err: any) => console.error(err))
    };

    const isDark = useSelector((state: any) => state.theme.isDark) as boolean;

    return (
        <div className={isDark ? 'modal-dark' : 'modal-light'}>
            <div className="global-container">
                <Text className="step__title" style={{ marginBottom: "10px" }}>Créer le client</Text>
                <form onSubmit={formik.handleSubmit} style={{ width: "70%" }}>
                    <div className="clientStatus__radioGroup">
                        {radioBtns.map((btn, i) => (
                            <div key={i} className="item-spacing">
                                <RadioButton
                                    {...btn}
                                    checked={formik.values.clientStatus === btn.value}
                                    onChange={() => {
                                        formik.setFieldValue('clientStatus', btn.value);
                                    }}
                                />
                                <label htmlFor={btn.inputId} className="ml-1">
                                    {btn.value}
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="name_lastname_container">
                        <div className="test">
                            <div>
                                <span className="p-input-icon-left" style={{ width: "100%" }}>
                                    <i className="pi pi-user" />
                                    <InputText
                                        id="lastname"
                                        name="lastname"
                                        placeholder="Nom"
                                        disabled={showPhoneDropdown}
                                        value={formik.values.lastname}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={formik.touched.lastname && formik.errors.lastname ? 'p-invalid' : ''}
                                        style={{ width: "100%" }}
                                    />
                                </span>
                            </div>
                            {formik.touched.lastname && formik.errors.lastname && (
                                <div>
                                    <small className="p-error error_test">{formik.errors.lastname}</small>
                                </div>
                            )}
                        </div>

                        <div className="test">
                            <div>
                                <span className="p-input-icon-left" style={{ width: "100%" }}>
                                    <i className="pi pi-user" />
                                    <InputText
                                        id="name"
                                        name="name"
                                        placeholder="Prénom"
                                        disabled={showPhoneDropdown}
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={formik.touched.name && formik.errors.name ? 'p-invalid' : ''}
                                        style={{ width: "100%" }}

                                    />
                                </span>
                            </div>

                            {formik.touched.name && formik.errors.name && (
                                <small className="p-error  error_test">{formik.errors.name}</small>
                            )}
                        </div>
                    </div>

                    {quoteData.step1Data === quoteEnum.professional &&
                        <>
                            <div className="test">
                                <div>
                                    <span className="p-input-icon-left" style={{ width: "100%" }}>
                                        <i className="pi pi-info-circle" />
                                        <InputText
                                            id="siretCode"
                                            name="siretCode"
                                            placeholder="N° Siret"
                                            maxLength={14}
                                            disabled={showPhoneDropdown}
                                            value={formik.values.siretCode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={formik.touched.siretCode && formik.errors.siretCode ? 'p-invalid' : ''}
                                            style={{ width: "100%" }}
                                        />
                                    </span>
                                </div>
                                <div>
                                </div>
                                {formik.touched.siretCode && formik.errors.siretCode && (
                                    <small className="p-error  error_test">{formik.errors.siretCode}</small>
                                )}
                            </div>

                            <div className="test">
                                <div>
                                    <span className="p-input-icon-left" style={{ width: "100%" }}>
                                        <i className="pi pi-info-circle" />
                                        <InputText
                                            id="companyName"
                                            name="companyName"
                                            placeholder="Nom de l'entreprise"
                                            disabled={showPhoneDropdown}
                                            value={formik.values.companyName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={formik.touched.companyName && formik.errors.companyName ? 'p-invalid' : ''}
                                            style={{ width: "100%" }}
                                        />
                                    </span>
                                </div>
                                <div>
                                </div>
                                {formik.touched.companyName && formik.errors.companyName && (
                                    <small className="p-error  error_test">{formik.errors.companyName}</small>
                                )}
                            </div>
                        </>
                    }

                    <div className="test">
                        {!showPhoneDropdown && <div>
                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                <i className="pi pi-phone" />
                                <InputText
                                    id="phone"
                                    name="phone"
                                    placeholder="Numéro de téléphone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={formik.touched.phone && formik.errors.phone ? 'p-invalid' : ''}
                                    style={{ width: "100%" }}
                                />
                            </span>
                        </div>}
                        {showPhoneDropdown && (
                            <div>
                                <Dropdown
                                    inputId="client"
                                    name="client"
                                    value={formik.values.client}
                                    options={clients}
                                    optionLabel="phone"
                                    placeholder="Choisir le numéro de téléphone du client"
                                    onChange={(e: any) => {
                                        formik.setFieldValue('client', e.value);
                                        formik.setFieldValue('phone', e.value.phone);
                                        formik.setFieldValue('email', e.value.email);
                                        formik.setFieldValue('streetQuoteAddress', e.value.address);
                                        formik.setFieldValue('zipcodeQuoteAddress', e.value.zipCode);
                                        formik.setFieldValue('cityQuoteAddress', e.value.city);
                                        formik.setFieldValue('name', e.value.firstName);
                                        formik.setFieldValue('lastname', e.value.lastName);
                                    }}
                                    filter
                                    style={{ width: "100%" }}

                                />
                                <span className="span__phone-warning">* Si vous ne trouvez pas le numéro de votre client, veuillez cocher "Nouveau client"</span>
                            </div>
                        )}

                        {formik.touched.phone && formik.errors.phone && (
                            <small className="p-error error_test">{formik.errors.phone}</small>
                        )}
                    </div>

                    <div className="test">
                        <div>
                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                <i className="pi pi-envelope" />
                                <InputText
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    disabled={showPhoneDropdown}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={formik.touched.email && formik.errors.email ? 'p-invalid' : ''}
                                    style={{ width: "100%" }}

                                />
                            </span>
                        </div>
                        <div>
                        </div>
                        {formik.touched.email && formik.errors.email && (
                            <small className="p-error  error_test">{formik.errors.email}</small>
                        )}
                    </div>

                    <div className="test">
                        <span className="span__address">Adresse d'installation</span>
                        <div>
                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                <i className="pi pi-home" />
                                <InputText
                                    id="address"
                                    name="address"
                                    placeholder="Adresse"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={formik.touched.address && formik.errors.address ? 'p-invalid' : ''}
                                    style={{ width: "100%" }}
                                />
                            </span>
                        </div>

                        {formik.touched.address && formik.errors.address && (
                            <small className="p-error error_test">{formik.errors.address}</small>
                        )}
                    </div>
                    <div className="test">
                        <div className="zipcode_city_container">
                            <div className="test">
                                <span className="p-input-icon-left" style={{ width: "100%" }}>
                                    <i className="pi pi-map" />
                                    <InputText
                                        id="zipcode"
                                        name="zipcode"
                                        placeholder="Code postal"
                                        value={formik.values.zipcode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={formik.touched.zipcode && formik.errors.zipcode ? 'p-invalid' : ''}
                                        style={{ width: "100%" }}
                                    />
                                </span>

                                {formik.touched.zipcode && formik.errors.zipcode && (
                                    <small className="p-error error_test">{formik.errors.zipcode}</small>
                                )}
                            </div>
                            <div className="test">
                                <span className="p-input-icon-left" style={{ width: "100%" }}>
                                    <i className="pi pi-map" />
                                    <InputText
                                        id="city"
                                        name="city"
                                        placeholder="Ville"
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={formik.touched.city && formik.errors.city ? 'p-invalid' : ''}
                                        style={{ width: "100%" }}
                                    />
                                </span>

                                {formik.touched.city && formik.errors.city && (
                                    <small className="p-error error_test">{formik.errors.city}</small>
                                )}
                            </div>
                        </div>

                        {!showPhoneDropdown && (
                            <div className="container__quoteAdress">
                                <span className="span__address">Adresse de facturation</span>
                                {yesNoRadioBtns.map((btn, i) => (
                                    <div key={i} className="item-spacing">
                                        <RadioButton
                                            {...btn}
                                            checked={formik.values.quoteAdress === btn.value}
                                            onChange={() => {
                                                formik.setFieldValue('quoteAdress', btn.value);
                                            }}
                                        />
                                        <label htmlFor={btn.inputId} className="radiobutton_label">
                                            {btn.value}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        )}
                        {
                            (showQuoteAddressInputs || showPhoneDropdown) &&
                            <div>
                                <div className="test" style={{ marginTop: "15px" }}>
                                    {showPhoneDropdown && (<span className="span__address">Adresse de facturation</span>)}

                                    <div>
                                        <span className="p-input-icon-left" style={{ width: "100%" }}>
                                            <i className="pi pi-home" />
                                            <InputText
                                                id="streetQuoteAddress"
                                                name="streetQuoteAddress"
                                                placeholder="Adresse"
                                                disabled={showPhoneDropdown}
                                                value={formik.values.streetQuoteAddress}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className={formik.touched.streetQuoteAddress && formik.errors.streetQuoteAddress ? 'p-invalid' : ''}
                                                style={{ width: "100%" }}
                                            />
                                        </span>
                                    </div>

                                    {formik.touched.streetQuoteAddress && formik.errors.streetQuoteAddress && (
                                        <small className="p-error error_test">{formik.errors.streetQuoteAddress}</small>
                                    )}
                                </div>
                                <div>
                                    <div className="zipcode_city_container">
                                        <div>
                                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                                <i className="pi pi-map" />
                                                <InputText
                                                    id="zipcodeQuoteAddress"
                                                    name="zipcodeQuoteAddress"
                                                    placeholder="Code postal"
                                                    disabled={showPhoneDropdown}
                                                    value={formik.values.zipcodeQuoteAddress}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className={formik.touched.zipcodeQuoteAddress && formik.errors.zipcodeQuoteAddress ? 'p-invalid' : ''}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>

                                            {formik.touched.streetQuoteAddress && formik.errors.streetQuoteAddress && (
                                                <small className="p-error error_test">{formik.errors.streetQuoteAddress}</small>
                                            )}
                                        </div>
                                        <div>
                                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                                <i className="pi pi-map" />
                                                <InputText
                                                    id="cityQuoteAddress"
                                                    name="cityQuoteAddress"
                                                    placeholder="Ville"
                                                    disabled={showPhoneDropdown}
                                                    value={formik.values.cityQuoteAddress}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className={formik.touched.cityQuoteAddress && formik.errors.cityQuoteAddress ? 'p-invalid' : ''}
                                                    style={{ width: "100%" }}
                                                />
                                            </span>

                                            {formik.touched.cityQuoteAddress && formik.errors.cityQuoteAddress && (
                                                <small className="p-error error_test">{formik.errors.cityQuoteAddress}</small>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>


                        }

                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Button className="button__validate" style={{ backgroundColor: "#3EFFFA", color: "black", borderColor: "#3EFFFA", padding: "12px", borderRadius: "6px" }}
                            aria-labelledby="valider" type="submit" label="Valider" />
                    </div>
                </form>
            </div>
        </div>
    );
}
