import axios from "axios";
import { User } from "../entities/user.entity";

const API_URL = process.env.REACT_APP_API_URL;

export function getUsers() {
    return axios.get(API_URL + 'users', { withCredentials: true });
};

export async function updateUser(user: User) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.put(API_URL + 'users', user, { withCredentials: true, headers: customHeaders });
}
export function getTop3Users(id: number) {
    return axios.get(API_URL + `users/top3-three-users/by-partner-parent-id/${id}`, { withCredentials: true });
};