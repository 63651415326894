import { Input, Text, getDocumentTheme } from "@nextui-org/react";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiEdit2 } from "react-icons/fi";
import { getClients } from "../../services/clients.service";
import { Client } from "../../entities/client.entity";
import ClientCard from "./ClientCard";
import { Deal } from "../../entities/deal.entity";
import { formatDate } from "../../helper/dateFormattor";
import { getDeals } from "../../services/deals.service";
import { getOrders } from "../../services/orders.service";
import { Order } from "../../entities/order.entity";
import "./Client.scss";
import TypeRenderer from "../shared/TypeRenderer/Type";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatPhone } from "../../helper/phoneFormattor";
import { Button } from "primereact/button";
import { ContextMenu } from "primereact/contextmenu";
import ClientDetail from "./details/clientDetail";
import { Toast } from "primereact/toast";
import { UppercaseFormatter } from "../../helper/uppercaseFormattor";
import CustomButton from "../shared/Buttons/customButton";
import { User } from "../../entities/user.entity";
import { updateTheme } from "../../redux/actions/ThemeAction";
import "../shared/Tables/table.scss"
import SiretRenderer from "../shared/SiretRenderer/SiretRenderer";
import UpdateClient from "./update/UpdateClient";


function Clients() {
    const [isDark, setIsDark] = useState(false);
    const loggedUser = useSelector((state: any) => state.login.loggedUser) as User;
    const [gridView, setGridView] = useState(false);
    const filters = ['Particulier', 'Professionnel', 'Copropriété'];
    const [showFilter, setShowFilter] = useState(false);
    const [clients, setClients] = useState<Client[]>([]);
    const [withoutFilterClients, setWithoutFilterClients] = useState<Client[]>([]);
    const [searchInput, setSearchInput] = useState('');
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const toast = useRef<Toast | null>(null);

    const [selectedClient, setSelectedClient] = useState<Client | undefined>();
    const cm = useRef<ContextMenu>(null);
    const actions = [
        { label: 'Modifier', icon: 'pi pi-fw pi-pencil', command: () => openUpdateModal(selectedClient) },
        { label: 'Détails', icon: 'pi pi-fw pi-info-circle', command: () => getDetails(selectedClient) },
    ];

    const handleFilters = () => {
        setShowFilter(!showFilter);
        if (!showFilter) {
            setClients(withoutFilterClients);
        }
    }
    useEffect(() => {
        (
            async () => {
                await getClients()
                    .then(response => {
                        let clients = filterByRole(response.data as Client[]);
                        setClients(clients);
                        setWithoutFilterClients(clients);
                    })
                    .catch(err => console.error('Disconnected'));
            }
        )();
    }, [showFilter]);

    function filterByRole(clients: Client[]): Client[] {
        switch (loggedUser.role) {
            case 'Commercial':
                return clients.filter(client => client.partnerId?.email === loggedUser.email);
            case 'Direction':
                return clients.filter(client => client.partnerId?.partner?.id === loggedUser.partner?.id);
            default:
                return clients;
        }
    }

    const handleFilter = (filter: string) => {
        switch (filter) {
            case 'Particulier':
            case 'Professionnel':
            case 'Copropriété':
                let newClientFilter = withoutFilterClients.filter(client => client.type === filter);
                setClients(newClientFilter);
                break;
            case 'Mes clients':
                let myClients = withoutFilterClients.filter(client => client.partnerId?.email === loggedUser.email);
                setClients(myClients);
                break;
            case 'Clients':
                setClients(withoutFilterClients);
                break;
            default:
                break;
        }
    }
    const getBadgeNumber = (filter: string) => {
        if (filter === 'Clients') {
            return withoutFilterClients.length.toString();
        }
        return withoutFilterClients.filter(client => client.type === filter).length.toString();
    }
    const handleInputChange = (event: any) => {
        const inputValue = event.target.value;
        setSearchInput(inputValue);
        const filteredResults = withoutFilterClients.filter((client) =>
            client.lastName.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            client.firstName.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            client.email.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            client.city.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            client.creationDate.toString().includes(inputValue.toLowerCase()) ||
            client.partnerId?.lastName.startsWith(inputValue.toLowerCase()) ||
            client.phone.toLowerCase().startsWith(inputValue.toLowerCase())
        );
        setClients(filteredResults);
    };

    const openUpdateModal = (rowData: any) => {
        if (rowData) {
            setSelectedClient(rowData);
            setShowUpdateModal(true);
        }
    }
    const getDetails = (rowData: any) => {
        if (rowData) {
            setSelectedClient(rowData);
            setShowDetailModal(true);
        }
    }
    const closeUpdateModal = () => {
        setShowUpdateModal(false);
    };

    const closeDetailModal = () => {
        setShowDetailModal(false);
    };

    const setContextMenu = (e: any) => {
        setSelectedClient(e.value);
    }
    useEffect(() => {
        if (showUpdateModal) {
            openUpdateModal(selectedClient);
        }
    }, [selectedClient, showUpdateModal]);

    const handleUpdate = (data: string) => {
        setToastMessage(data);
        if (toast.current) {
            switch (data) {
                case "successful update":
                    window.location.reload();
                    toast.current.show({ severity: 'success', summary: 'Client modifié avec succès', life: 6000 });
                    break;
                case "failed update":
                    toast.current.show({ severity: 'error', summary: 'Echec de modification', life: 6000 });
                    break;
                default:
                    break;
            }
        }
    }
    // const theme = useSelector((state: any) => state.theme.isDark) as boolean;


    useEffect(() => {
        let theme = window.localStorage.getItem('data-theme');
        setIsDark(theme === 'dark');

        const observer = new MutationObserver((mutation) => {
            let newTheme = getDocumentTheme(document?.documentElement);
            setIsDark(newTheme === 'dark');
        });

        observer.observe(document?.documentElement, {
            attributes: true,
            attributeFilter: ['data-theme', 'style']
        });

        return () => observer.disconnect();
    }, []);
    const dispatch = useDispatch();

    dispatch(updateTheme(isDark))

    return (
        <div >
            <Container >
                <Row className="mt-2 mb-3">
                    <Col className="align-items-center">
                        <Text size={36} b >Clients</Text>
                    </Col>
                </Row>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="div__filter">
                        <div className="div__input_icon">
                            <Input
                                width='70%'
                                type="search"
                                placeholder="Rechercher un client"
                                value={searchInput}
                                onChange={handleInputChange}
                                aria-labelledby="search"
                            />
                            <div onClick={handleFilters}>
                                <CustomButton title="Filtres" />
                            </div>
                        </div>
                        {showFilter && <div className="div__button_filter">
                            {filters.map((filter, index) => (
                                <Col key={index} style={{ display: 'flex' }} className="justify-content-center">
                                    <Button
                                        aria-labelledby="btn"
                                        rounded
                                        outlined
                                        size="small"
                                        badge={getBadgeNumber(filter)}
                                        onClick={() => handleFilter(filter)}
                                        label={filter}
                                        style={{ width: '7rem', color: isDark ? '#8dd0ff' : '' }}
                                    >
                                    </Button>
                                </Col>
                            ))}
                        </div>}
                    </div>
                </div>
                <Row>
                    <Col>
                        {gridView ?
                            <Container>
                                <Row>
                                    {
                                        clients.map(client => {
                                            return (<ClientCard key={client.id} client={client}></ClientCard>);
                                        })
                                    }
                                </Row>
                            </Container> :
                            <div className={!isDark ? "custom_table__light" : "custom_table__dark"}>
                                <ContextMenu model={actions} ref={cm} className={isDark ? 'dark-context' : 'light'} />
                                <DataTable stripedRows columnResizeMode="expand" resizableColumns paginator rows={10} value={clients}
                                    removableSort tableStyle={{ minWidth: '50rem' }} sortMode="multiple" selectionMode="single"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    rowsPerPageOptions={[10, 25, 50]}
                                    emptyMessage="Aucun client." currentPageReportTemplate="Client {first} jusqu'à {last} sur {totalRecords}"
                                    onContextMenu={(e) => cm.current?.show(e.originalEvent)}
                                    contextMenuSelection={selectedClient || undefined} onContextMenuSelectionChange={(e) => setContextMenu(e)} >
                                    {/* <Column aria-labelledby="edit" header=" " body={(rowData) => <FiEdit2 onClick={() => openUpdateModal(rowData)} />} sortable={false} /> */}
                                    <Column aria-labelledby="firstname" field="firstName" header="Prénom" sortable style={{ fontWeight: "500" }} ></Column>
                                    <Column aria-labelledby="lastname" field="lastName" header="Nom" sortable style={{ fontWeight: "500" }} body={(rowData) => UppercaseFormatter(rowData.lastName)} ></Column>
                                    <Column aria-labelledby="type" field="clientId.type" header="Type" style={{ minWidth: '6rem' }} body={(rowData) => <TypeRenderer text={rowData.type} companyName={rowData.companyName} />} sortable />
                                    <Column aria-labelledby="phone" field="phone" header="Numéro" sortable body={(rowData) => formatPhone(rowData.phone)} ></Column>
                                    <Column aria-labelledby="email" field="email" header="Email" sortable  ></Column>
                                    <Column aria-labelledby="siretCode" field="clientId.siretCode" header="N° Siret" body={(rowData) => <SiretRenderer text={rowData.siretCode} />} sortable></Column>
                                    <Column aria-labelledby="city" field="city" header="Ville" sortable  ></Column>
                                    <Column aria-labelledby="commercial" field="partnerId.lastName" header="Commercial" sortable ></Column>
                                    <Column aria-labelledby="concession" field="partnerId.partner.name" header="Concession" sortable ></Column>
                                    <Column aria-labelledby="creation" field="id" header="Date de création" sortable body={(rowData) => formatDate(rowData.creationDate.toString())} ></Column>
                                </DataTable>
                            </div>
                        }
                    </Col>
                </Row >

                {showUpdateModal && (
                    <UpdateClient open={showUpdateModal} onClose={closeUpdateModal} client={selectedClient} handleUpdate={handleUpdate} />
                )}
                {showDetailModal && (
                    <ClientDetail open={showDetailModal} onClose={closeDetailModal} client={selectedClient} />
                )}
                <Toast ref={toast} position="top-right" />

            </Container >
        </div >
    )
};

export default Clients;

