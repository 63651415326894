import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Deal } from "../../../entities/deal.entity";
import { Accordion, AccordionTab } from "primereact/accordion";
import ValidateClient from "./client/validateClient";
import { getProductsPerDealByDealId, updateDealStatus } from "../../../services/deals.service";
import { ProductPerDealDto } from "../../../dtos/product-per-deal.dto";
import { Calendar } from "primereact/calendar";
import { Dialog } from 'primereact/dialog';
import ValidateDealDetails from "./deal/validateDeatDeatails";
import CustomButton from "../../shared/Buttons/customButton";
import { useSelector } from "react-redux";
import "./validateDeal.scss";
import "../../shared/DarkMode.scss";
import { FileUpload } from 'primereact/fileupload';
import UploadQuote from "./upload/UploadQuote";
import { tab } from "@testing-library/user-event/dist/tab";
import { DealStatus, MailType } from "../../../constants/enum";
import { User } from "../../../entities/user.entity";
import { MailDto } from "../../../dtos/mail.dto";
import { sendMail } from "../../../services/mail.service";

interface ValidateDealModalProps {
    open: boolean;
    onClose: () => void;
    deal: Deal;
    handleUpdate: (data: string) => void;
}

function isWeekend(date: Date): boolean {
    const day = date.getDay();
    return day === 0 || day === 6;
}

function addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function calculateSelectableDate(deal: Deal): Date {
    const currentDate = new Date(deal?.creationDate ? deal.creationDate : new Date());
    let workingDaysRemaining = 10;
    let currentDateCopy = new Date(currentDate);

    while (workingDaysRemaining > 0) {
        currentDateCopy = addDays(currentDateCopy, 1);

        if (!isWeekend(currentDateCopy)) {
            workingDaysRemaining--;
        }
    }

    return currentDateCopy;
}

function ValidateDeal({ open, onClose, deal, handleUpdate }: ValidateDealModalProps) {

    const [activeIndex, setActiveIndex] = useState<number>(0);

    const [closedIndex, setClosedIndex] = useState<number | number[]>();

    const [tab1, setTab1] = useState<boolean>(false);
    const [tab2, setTab2] = useState<boolean>(false);
    const [tab3, setTab3] = useState<boolean>(false);
    const [tab4, setTab4] = useState<boolean>(false);

    const [canCloseInstallationTab, setCanCloseInstallationTab] = useState(true);

    const [clientValidated, setClientValidated] = useState<boolean>(false);
    const [dealValidated, setDealValidated] = useState<boolean>(false);
    const [signedQuoteValidated, setSignedQuoteValidated] = useState<boolean>(false);
    const [installationValidated, setInstallationValidated] = useState<boolean>(false);

    const [productsPerDeal, setProductsPerDeal] = useState<ProductPerDealDto[]>([]);
    const [station, setStation] = useState<ProductPerDealDto>();
    const [accessories, setAccessories] = useState<ProductPerDealDto[]>();

    useEffect(() => {

        switch (activeIndex) {
            case 0:
                setTab1(true);
                setTab2(false);
                setTab3(false);
                setTab4(false);
                if (tab2) {
                    setDealValidated(true)
                }
                if (tab3) {
                    setSignedQuoteValidated(true)

                }
                if (tab4) {
                    // setSignedQuoteValidated(true)
                    setInstallationValidated(true)

                }
                break;
            case 1:
                setTab2(true);
                setTab1(false);
                setTab3(false);
                setTab4(false);
                if (tab1) {
                    setClientValidated(true)
                }
                if (tab3) {
                    setSignedQuoteValidated(true)
                    //setInstallationValidated(true)
                }
                if (tab4) {
                    setInstallationValidated(true)
                }
                setCanCloseInstallationTab(true)
                break;
            case 2:
                setTab3(true);
                setTab2(false);
                setTab1(false);
                setTab4(false);
                if (tab1) {
                    setClientValidated(true)
                }
                if (tab2) {
                    setDealValidated(true)
                }
                if (tab4) {
                    setInstallationValidated(true)
                }
                break;
            case 3:
                setTab4(true);
                setTab2(false);
                setTab1(false);
                setTab3(false);
                if (tab1) {
                    setClientValidated(true)
                }
                if (tab2) {
                    setDealValidated(true)
                }
                if (tab3) {
                    setSignedQuoteValidated(true)
                }
                setCanCloseInstallationTab(false)
                break;
            default:
                break;
        }

    }, [activeIndex, tab1, tab2, tab3, clientValidated, dealValidated, installationValidated, closedIndex]);

    const bookOrder = () => {
        // onClose();
        if (deal.id && date) {
            updateDealStatus(deal.id.toString(), DealStatus.Won, date.toString())
                .then(async res => {
                    let mail = {
                        mailType: MailType.PLANNED_INSTALLER,
                        order: res.newOrder
                    } as MailDto;

                    await sendMail(mail);
                    handleUpdate("successful booking");
                })
                .catch(err => {
                    handleUpdate("failed booking");
                })
        }
    }

    const handleTabChange = (event: any) => {
        setActiveIndex(event.index);
    }

    useEffect(() => {
        (

            async () => {
                if (deal.id) {
                    await getProductsPerDealByDealId(deal.id)
                        .then(response => {
                            setProductsPerDeal(response.data as ProductPerDealDto[]);
                        })
                        .catch(err => console.error('Disconnected'));
                }

            }
        )();
        let product = productsPerDeal.find(ppd => ppd.product.category === 'Borne')
        let accessories = productsPerDeal.filter(ppd => ppd.product.category === 'Accessoires' || ppd.product.category === 'Cable')
        setStation(product)
        setAccessories(accessories)
    }, [productsPerDeal.length]);


    const [date, setDate] = useState<Date>();

    useEffect(() => {
    }, [date]);
    const test = (itemIndex: number) => {
        setActiveIndex(itemIndex);
    }

    const isDark = useSelector((state: any) => state.theme.isDark) as boolean;

    const handlePostUpload = (data: any) => {
        if (data === "Success") {
            setSignedQuoteValidated(true);
            setActiveIndex(3);
        }
    }
    return (
        <Dialog
            header="Confirmer votre commande"
            headerStyle={{
                padding: "15px 24px", borderTopLeftRadius: "18px", borderTopRightRadius: "18px", fontWeight: "500",
                background: isDark ? "#2a323d" : "#fff", color: isDark ? "rgba(255,255,255,.87)" : "black"
            }}
            visible={open} onHide={onClose}
            closable={false}
            closeOnEscape={true}
            dismissableMask={false}
            style={{ width: '45vw', padding: "0px", borderRadius: "18px" }}
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            contentStyle={{
                padding: '0px', borderBottomLeftRadius: "18px", borderBottomRightRadius: "18px", background: isDark ? "#2a323d" : "#fff",
                color: isDark ? "rgba(255,255,255,.87)" : "#495057"
            }}
            className={isDark ? 'modal-dark' : 'modal-light'}>
            <div style={{ padding: "8px" }}>
                <Container className="container__confirmDeal">
                    <div className="card" style={{ border: "none" }}>
                        <Accordion activeIndex={activeIndex}
                            onTabChange={(e) => handleTabChange(e)}
                            // onTabClose={(e) => handleTabClose(e)}
                            collapseIcon="false"
                            expandIcon="false"
                        >
                            <AccordionTab className={clientValidated ? "acc_test" : ""}
                                header={
                                    <div className="flex align-items-center" style={{ marginLeft: "-10px" }}>
                                        <i className={`pi pi-user ${(clientValidated && !tab1) ? 'valid-client' : ''}`} style={{ marginRight: "6px" }}></i>
                                        <span className={`vertical-align-middle ml-2 ${clientValidated && !tab1 ? 'valid-client' : ''}`} >Client</span>
                                    </div>
                                }>
                                <div className="m-0 mb-3">
                                    <ValidateClient deal={deal} />
                                </div>
                                <div className="div__button">
                                    <div onClick={() => test(1)}>
                                        <CustomButton title="Valider client" />
                                    </div>
                                </div>
                            </AccordionTab>

                            <AccordionTab className={dealValidated ? "acc_test" : ""}
                                header={
                                    <div className="flex align-items-center" style={{ marginLeft: "-10px" }}>
                                        <i className={`pi pi-box ${dealValidated && !tab2 ? 'valid-client' : ''}`} style={{ marginRight: "6px" }}></i>
                                        <span className={`vertical-align-middle ml-2 ${dealValidated && !tab2 ? 'valid-client' : ''}`} >Affaire</span>
                                    </div>
                                }>
                                <div className="m-0">
                                    <ValidateDealDetails deal={deal} station={station} accessories={accessories} />
                                </div>
                                <div className="div__button" style={{ marginTop: "20px" }}>
                                    <div onClick={() => test(2)} >
                                        <CustomButton title="Valider affaire" />
                                    </div>
                                </div>
                            </AccordionTab>
                            <AccordionTab className={signedQuoteValidated ? "acc_test" : ""}
                                header={
                                    <div className="flex align-items-center" style={{ marginLeft: "-10px" }}>
                                        <i className={`pi pi-map ${signedQuoteValidated && !tab3 ? 'valid-client' : ''}`} style={{ marginRight: "6px" }}></i>
                                        <span className={`vertical-align-middle ml-2 ${signedQuoteValidated && !tab3 ? 'valid-client' : ''}`} >Devis signé</span>
                                    </div>
                                }>
                                <div className="m-0">
                                    <UploadQuote onUploadedFile={handlePostUpload} />
                                </div>
                            </AccordionTab>

                            <AccordionTab className={installationValidated ? "acc_test" : ""}
                                disabled={canCloseInstallationTab}
                                header={
                                    <div className="flex align-items-center" style={{ marginLeft: "-10px" }}>
                                        <i className={`pi pi-map ${installationValidated && !tab4 ? 'valid-client' : ''}`} style={{ marginRight: "6px" }}></i>
                                        <span className={`vertical-align-middle ml-2 ${installationValidated && !tab4 ? 'valid-client' : ''}`} >Installation</span>
                                    </div>
                                }>

                                <div className="div__calendar" style={{ alignItems: "center" }} >
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span className="span__Title">Date d'intallation</span>
                                        <span className="span__Title" style={{ color: isDark ? "#f19ea6" : "red" }}>* Champs obligatoire</span>

                                    </div>
                                    <Calendar
                                        value={date}
                                        onChange={(e: any) => setDate(e.value)}
                                        dateFormat="dd-mm-yy"
                                        minDate={calculateSelectableDate(deal)}
                                        showIcon />
                                </div>
                            </AccordionTab>

                        </Accordion >

                        <div className="div__button" style={{ marginTop: "20px", marginBottom: '12px' }}>
                            <div onClick={onClose} style={{ marginRight: "10px" }}>
                                <CustomButton title="Annuler" />
                            </div>
                            <div onClick={() => bookOrder()}>
                                <CustomButton title="Confirmer commande" disabled={date === undefined} />
                            </div>

                        </div>

                    </div >
                </Container >
            </div >
            {/* <Modal.Footer className="custom__footer">
                <Button  >
                    Modifier
                </Button>
            </Modal.Footer> */}
        </Dialog >
    )
}

export default ValidateDeal;