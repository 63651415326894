import GoogleImageImport from "./GoogleImage.png";
import { Image } from "@nextui-org/react";

function GoogleImage() {
    return (
        <div>
            <Image
                src={GoogleImageImport}
                alt="Logo"
                width={175}
            />
        </div>

    );
}

export default GoogleImage;