import { Deal } from "../../../entities/deal.entity";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { User } from "../../../entities/user.entity";
import { Formik, useFormik } from 'formik';
import { InputTextarea } from "primereact/inputtextarea";
import "./AddComment.scss"
import { updateDealComment } from "../../../services/deals.service";

interface AddCommentsProps {
    open: boolean;
    onClose: () => void;
    deal: Deal;
    handleUpdate: (data: string) => void;
}

interface FormDataComment {
    customComment: string
}

interface Comment {
    username: string;
    comment: string;
    date: string;
}


function AddComment({ open, onClose, deal, handleUpdate }: AddCommentsProps) {

    const loggedUser = useSelector((state: any) => state.login.loggedUser) as User;
    let comments: Comment;

    const formik = useFormik<FormDataComment>({
        initialValues: {
            customComment: "",
        },
        validate: (data) => {
            const errors: Partial<FormDataComment> = {};

            if (!data.customComment.trim()) {
                errors.customComment = 'Veuillez saisir un commentaire.';
            }
        },
        onSubmit: async (data) => {

            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            const yyyy = today.getFullYear();

            const currentDate = `${dd}/${mm}/${yyyy}`;

            comments = {
                username: `${loggedUser.firstName}${loggedUser.lastName}`,
                comment: `${data.customComment}`,
                date: currentDate
            };
            try {
                if (deal.id) {
                    await updateDealComment(deal.id, comments);
                    handleUpdate("successful update");
                    onClose();
                }

            } catch (err) {
                console.error(err);
                handleUpdate("failed update");
                onClose();
            }
        }
    })

    return (
        <Dialog header="Veuillez saisir votre commentaire" visible={open} onHide={onClose} closable={false} closeOnEscape={true} dismissableMask={true} >

            <form onSubmit={formik.handleSubmit}>
                <div>
                    <span style={{ width: "100%" }}>
                        <InputTextarea
                            id="comment"
                            name="customComment"
                            placeholder="Commentaire"
                            value={formik.values.customComment}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={formik.touched.customComment && formik.errors.customComment ? 'p-invalid' : ''}
                            style={{ width: "100%" }}
                            rows={5} cols={80}
                        />
                    </span>
                </div>
                <div className="div__buttonsComments">
                    <Button
                        style={{
                            backgroundColor: "lightgrey",
                            color: "black",
                            borderColor: "lightgrey",
                            padding: "10px",
                            marginRight: "10px"

                        }}
                        aria-labelledby="annuler" label="Annuler" onClick={onClose} />
                    <Button
                        style={{
                            backgroundColor: "#3EFFFA",
                            color: "black",
                            borderColor: "#3EFFFA",
                            padding: "10px",

                        }}
                        aria-labelledby="valider" type="submit" label="Valider" />
                </div>
            </form>

        </Dialog>
    )
}

export default AddComment;