import { Text } from "@nextui-org/react";
import { Deal } from "../../../entities/deal.entity";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import "./DeleteDeal.scss";
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { User } from "../../../entities/user.entity";
import { updateDeal } from "../../../services/deals.service";
import { DealStatus } from "../../../constants/enum";
import { FaTrash } from "react-icons/fa";

interface DeleteDealProps {
    open: boolean;
    onClose: () => void;
    deal: Deal;
}

function DeleteDeal({ open, onClose, deal }: DeleteDealProps) {
    const [reason, setReason] = useState(null) as any;
    const [otherReason, setOtherReason] = useState('');
    const loggedUser = useSelector((state: any) => state.login.loggedUser) as User;

    const reasons = [
        { name: "Autre" },
        { name: "Délai de livraison trop long" },
        { name: "Projet reporté" },
        { name: "Sans réponse du client" }
    ];

    function deleteDeal() {
        let reasonToSave = reason.name;
        if (reasonToSave == 'Autre') {
            reasonToSave = otherReason;
        }

        deal.status = DealStatus.Lost;
        deal.withdrawReason = reasonToSave;
        deal.updateDate = new Date();
        deal.updatedBy = loggedUser.email;

        updateDeal(deal).then(() => {
            onClose();
        });

    }

    function canValidate() {
        if (reason) {
            if (reason?.name == 'Autre') {
                return otherReason != '';
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    return (
        <Dialog visible={open} onHide={onClose}
            closable={false} closeOnEscape={true} dismissableMask={true} style={{ width: '40rem' }}
            header={<div style={{ display: 'flex', alignItems: 'center' }}>
                <FaTrash size={20} style={{ marginRight: '0.5rem' }} />
                <span>Abandonner l'affaire</span>
            </div>}
        >

            <div>
                <Container>
                    <Row>
                        <Col>
                            <Text style={{ fontWeight: '500' }}>Merci de selectionner un motif</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Dropdown value={reason} onChange={(e) => { setReason(e.value); setOtherReason(''); }} options={reasons} optionLabel="name"
                                editable placeholder="Motif" style={{ width: '100%' }} />
                        </Col>
                    </Row>
                    {
                        reason?.name == 'Autre' &&
                        (
                            <Row className="mt-3">
                                <Col>
                                    <InputTextarea id="reason" placeholder="Autre motif" style={{ width: '100%' }}
                                        autoResize value={otherReason}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setOtherReason(e.target.value)}
                                        rows={2} cols={30}></InputTextarea>
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </div>
            <div>
                <Container>
                    <div className="button__modify" style={{ marginTop: "10px" }}>
                        <Button
                            style={{
                                backgroundColor: "lightgrey",
                                color: "black",
                                borderColor: "lightgrey",
                                padding: "10px",
                                marginRight: "10px"

                            }}
                            aria-labelledby="annuler" label="Annuler" onClick={onClose} />
                        <Button
                            style={{
                                backgroundColor: "#3EFFFA",
                                color: "black",
                                borderColor: "#3EFFFA",
                                padding: "10px",

                            }}
                            disabled={!canValidate()}
                            aria-labelledby="valider" type="submit" label="Valider"
                            onClick={() => deleteDeal()} />
                    </div>
                </Container>
            </div>
        </Dialog>
    )
}

export default DeleteDeal;