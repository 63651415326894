import axios from 'axios';
import { Deal } from '../entities/deal.entity';
import { ProductPerDealDto } from '../dtos/product-per-deal.dto';
import { InstallationPerDealDto } from '../dtos/installation-per-deal.dto';

const API_URL = process.env.REACT_APP_API_URL;
interface Comment {
    username: string;
    comment: string;
    date: string
}

export function getDeals() {
    return axios.get(API_URL + 'deals', { withCredentials: true });
};

export async function saveDeal(deal: Deal) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post(API_URL + 'deals', deal, { withCredentials: true, headers: customHeaders });
}

export async function updateDeal(deal: Deal) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.put(API_URL + 'deals', deal, { withCredentials: true, headers: customHeaders });
}

export function getDealsByClientId(clientId: number) {
    return axios.get(API_URL + `deals/by-client-id/${clientId}`, { withCredentials: true });
};

export function getDealsByPartnerId(partnerId: number) {
    return axios.get(API_URL + `deals/by-partner-id/${partnerId}`, { withCredentials: true });
}

export function getNumberOfDeals(id: number, partnerId: number, parentPartnerId: number) {
    return axios.get(API_URL + `deals/number-of-deals/${id}/${partnerId}/${parentPartnerId}`, { withCredentials: true });
}

export async function updateDealStatus(dealId: string, newStatus: string, date: string) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    try {
        const response = await axios.put(API_URL + `deals/update-status/${dealId}/${newStatus}/${date}`, null, {
            withCredentials: true,
            headers: customHeaders,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function updateDealComment(id: number, comment: Comment) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.put(API_URL + `deals/update-comment/${id}`, comment, { withCredentials: true, headers: customHeaders });

}


export function getProductPerDeal() {
    return axios.get(API_URL + 'product-per-deal', { withCredentials: true });
};

export function getInstallationPerDeal() {
    return axios.get(API_URL + 'installation-per-deal', { withCredentials: true });
};

export function getProductsPerDealByDealId(id: number) {
    return axios.get(API_URL + 'product-per-deal/by-deal-id/' + id, { withCredentials: true });
};

export function getInstallationsPerDealByDealId(id: number) {
    return axios.get(API_URL + 'installation-per-deal/by-deal-id/' + id, { withCredentials: true });
};

export function deleteInstallationsPerDeal(id: number) {
    // here -- why
    return axios.delete(API_URL + 'installation-per-deal/' + id, { withCredentials: true });
};

export async function saveProductPerDeal(productPerDeal: ProductPerDealDto) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post(API_URL + 'product-per-deal', productPerDeal, { withCredentials: true, headers: customHeaders });
}

export async function updateProductPerDeal(productPerDeal: ProductPerDealDto) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.put(API_URL + 'product-per-deal', productPerDeal, { withCredentials: true, headers: customHeaders });
}

export async function saveInstallationPerDeal(installationPerDeal: InstallationPerDealDto) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return await axios.post(API_URL + 'installation-per-deal', installationPerDeal, { withCredentials: true, headers: customHeaders });
}

export function deleteProductsPerDeal(id: number) {
    return axios.delete(API_URL + 'product-per-deal/' + id, { withCredentials: true });
};