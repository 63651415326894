import CustomButton from '../shared/Buttons/customButton';
import StatusRenderer from '../shared/StatusRenderer/status';
import TypeRenderer from '../shared/TypeRenderer/Type';
import UpdateOrder from './update/UpdateOrder';
import { Button } from 'primereact/button';
import { Col, Container, Row } from 'react-bootstrap';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable } from 'primereact/datatable';
import { downloadOrderFrom, getOrders } from '../../services/orders.service';
import { formatDate } from '../../helper/dateFormattor';
import { getDocumentTheme, Input, Text } from '@nextui-org/react';
import {
    homePageFilters,
    homePageRoutes,
    MailType,
    OrderStatus
} from '../../constants/enum';
import { MailDto } from '../../dtos/mail.dto';
import { mapStatus } from '../../helper/statusMapper';
import { Order } from '../../entities/order.entity';
import { sendMail } from '../../services/mail.service';
import { Toast } from 'primereact/toast';
import { TodaysDate } from '../../helper/todaysDate';
import { updateTheme } from '../../redux/actions/ThemeAction';
import { UppercaseFormatter } from '../../helper/uppercaseFormattor';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { User } from '../../entities/user.entity';
import './Orders.scss';
import '../shared/Tables/table.scss';
import SiretRenderer from '../shared/SiretRenderer/SiretRenderer';
import CommentIndicator from '../shared/CommentIndicator/CommentIndicator';
import Details from '../deals/details/Details';


function Orders() {
    const loggedUser = useSelector((state: any) => state.login.loggedUser) as User;
    const location = useLocation();
    const { filter, type } = location.state || {};
    const filters = [OrderStatus.ToPlan, OrderStatus.Planned, OrderStatus.Installed];
    const [orders, setOrders] = useState<Order[] | any>([]);
    const [withoutFilterOrders, setWithoutFilterOrders] = useState<Order[]>([]);
    const [searchInput, setSearchInput] = useState('');
    const toast = useRef<Toast | null>(null);
    const [showFilter, setShowFilter] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    useEffect(() => {
        (
            async () => {
                await getOrders()
                    .then(response => {
                        let orders = filterByRole(response.data as Order[]);
                        switch (filter) {
                            case homePageFilters.user:
                                if (type === homePageRoutes.order) {
                                    orders = orders.filter(order => order?.clientId?.partnerId?.email === loggedUser.email && order.status === OrderStatus.ToPlan);

                                }
                                else if (type === homePageRoutes.installation) {
                                    orders = orders.filter(order => order?.clientId?.partnerId?.email === loggedUser.email && order.status === OrderStatus.Planned);
                                }
                                break;
                            case homePageFilters.concession:
                                if (type === homePageRoutes.order) { orders = orders.filter(order => order.deal?.clientId?.partnerId?.partner?.name === loggedUser.partner?.name && order.status === OrderStatus.ToPlan); }
                                else if (type === homePageRoutes.installation) {
                                    orders = orders.filter(order => order.deal?.clientId?.partnerId?.partner?.name === loggedUser.partner?.name && order.status === OrderStatus.Planned);
                                }
                                break;
                            default:
                                break;
                        }
                        setOrders(orders);
                        setWithoutFilterOrders(orders);
                    })
                    .catch(err => console.error('Disconnected'));
            }
        )();
    }, [showFilter]);

    function filterByRole(orders: Order[]): Order[] {
        switch (loggedUser.role) {
            case 'Commercial':
                return orders.filter(order => order.clientId.partnerId?.email === loggedUser.email);
            case 'Direction':
                return orders.filter(order => order.clientId.partnerId?.partner?.id === loggedUser.partner?.id);
            default:
                return orders;
        }
    }

    const [showUpdateOrder, setShowUpdateOrder] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<Order>();
    const [contextMenuOrder, setContextMenuOrder] = useState<Order>();
    const [isDark, setIsDark] = useState(false);
    const [sideBarData, setSideBarData] = useState<any>();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        let theme = window.localStorage.getItem('data-theme');
        setIsDark(theme === 'dark');

        const observer = new MutationObserver((mutation) => {
            let newTheme = getDocumentTheme(document?.documentElement);
            setIsDark(newTheme === 'dark');
        });

        observer.observe(document?.documentElement, {

            attributes: true,
            attributeFilter: ['data-theme', 'style']
        });

        return () => observer.disconnect();
    }, []);
    const dispatch = useDispatch();

    dispatch(updateTheme(isDark))


    const openUpdateModal = (order: Order) => {
        setShowUpdateOrder(true);
        setSelectedOrder(order);
    };

    const closeUpdateOrder = () => {
        setShowUpdateOrder(false);
    };

    const openConfirmationModal = (order: Order) => {
        setShowConfirmationModal(true);
        setSelectedOrder(order);
    };

    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const cm = useRef<ContextMenu>(null);

    const actions = [
        {
            label: 'Date d\'installation', icon: 'pi pi-fw pi-pencil', command: () => {
                if (selectedOrder) {
                    openUpdateModal(selectedOrder);
                    setShowUpdateModal(true);
                }
            }, visible: selectedOrder?.status === OrderStatus.ToPlan
        },
        {
            label: 'Abandonner', icon: 'pi pi-fw pi-trash', command: () => {
                if (selectedOrder) {
                    openConfirmationModal(selectedOrder);
                }
            }, visible: selectedOrder?.status !== OrderStatus.Installed
        },
        {
            label: 'Envoyer le bon de commande', icon: 'pi pi-fw pi-envelope', command: async () => {
                let mail = {
                    mailType: MailType.PLANNED_INSTALLER,
                    order: selectedOrder
                } as MailDto;

                await sendMail(mail).then(() => {
                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Bon de commande envoyé', life: 6000 });
                    }
                }).catch(() => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Erreur lors de l\'envoi du bon de commande', life: 6000 });
                    }
                });
            }
        },
        {
            label: 'Télécharger le bon de commande', icon: 'pi pi-fw pi-download', command: async () => {
                let response = await downloadOrderFrom(selectedOrder!);
                let today = new Date();
                let filename = `BDC-EMS-${TodaysDate()}.pdf`;

                const blob = new Blob([response.data]);


                const url = window.URL.createObjectURL(blob);


                const a = document.createElement('a');
                a.href = url;

                a.download = filename;
                a.click();

                window.URL.revokeObjectURL(url);
            }
        },
    ];

    const contextMenuOptions = {
        style: { width: 'fit-content' },
    };

    const handleContextMenu = (e: any, order: Order) => {
        setContextMenuOrder(order);
        e.preventDefault();
        if (cm.current) {
            cm.current.show(e);
        }
    };

    const handleFilter = (filter: string) => {
        switch (filter) {
            case OrderStatus.Installed:
            case OrderStatus.Planned:
            case OrderStatus.ToPlan:
                let newOrder = withoutFilterOrders.filter(order => order.status === filter);
                setOrders(newOrder);
                break;
            case 'Commandes':
                setOrders(withoutFilterOrders);
                break;
            default:
                break;
        }
    }
    const getBadgeNumber = (filter: string) => {
        switch (filter) {
            case OrderStatus.Installed:
            case OrderStatus.Planned:
            case OrderStatus.ToPlan:
                return withoutFilterOrders.filter(order => order.status === filter).length.toString();
            case 'Commandes':
                return withoutFilterOrders.length.toString();
            default:
                break;
        }
    }

    const handleInputChange = (event: any) => {
        const inputValue = event.target.value;
        setSearchInput(inputValue);

        const filteredResults = withoutFilterOrders.filter((order) =>
            order.clientId.firstName.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            order.clientId.lastName.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            order.clientId.type.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            order.startDate.toString().includes(inputValue.toLowerCase()) ||
            order.clientId.companyName?.toLowerCase().includes(inputValue.toLowerCase()) ||
            order.clientId.siretCode?.toString().startsWith(inputValue.toLowerCase())
        );

        setOrders(filteredResults);
    };

    const postUpdateAction = (data: any) => {
        window.location.reload();

        if (data) {
            if (toast.current) {
                toast.current.show({ severity: 'success', summary: 'Date d\'installation confirmée', life: 6000 });
                toast.current.show({ severity: 'success', summary: 'Bon de commande envoyé', life: 6000 });
            }
        } else {
            if (toast.current) {
                toast.current.show({ severity: 'success', summary: 'Echec de modification de la date d\installation', life: 6000 });
            }
        }
    }

    const handleFilters = () => {
        setShowFilter(!showFilter);
        if (!showFilter) {
            setOrders(withoutFilterOrders);
        }
    }

    const handleSideBarOpen = (data: any) => {
        setSideBarData(data);
        setVisible(true);
    }

    const handleHide = () => {
        setVisible(false);
        setSideBarData(null);
    };

    return (
        <div>
            <Container>
                <Row className="mt-2 mb-3">
                    <Col>
                        <Text size={36} b>Commandes</Text>
                    </Col>
                </Row>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="div__filter">
                        <div className="div__input_icon">
                            <Input
                                width='90%'
                                type="search"
                                placeholder="Rechercher une commande"
                                value={searchInput}
                                onChange={handleInputChange}
                                aria-labelledby="search"
                            />
                            <div onClick={handleFilters}>
                                <CustomButton title="Filtres" />
                            </div>
                        </div>
                        {showFilter && <div className="div__button_filter">
                            {filters.map((filter, index) => (
                                <Col key={index} style={{ display: 'flex' }} className="justify-content-center">
                                    <Button
                                        aria-labelledby="btn"
                                        rounded
                                        outlined
                                        size="small"
                                        badge={getBadgeNumber(filter)}
                                        onClick={() => handleFilter(filter)}
                                        label={mapStatus(filter)}
                                        style={{ width: '7rem', color: isDark ? '#8dd0ff' : '' }}
                                    >
                                    </Button>
                                </Col>
                            ))}
                        </div>}
                    </div>
                </div>
                <Row>
                    <Col>
                        <div className={!isDark ? "custom_table__light" : "custom_table__dark"}>
                            <ContextMenu model={actions} ref={cm} {...contextMenuOptions} className={isDark ? 'dark-context' : 'light'} />
                            <DataTable stripedRows columnResizeMode="expand" resizableColumns paginator rows={10} value={orders}
                                removableSort tableStyle={{ minWidth: '50rem' }} sortMode="multiple" selectionMode="single"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[10, 25, 50]}
                                emptyMessage="Aucune affaire." currentPageReportTemplate="Affaire {first} jusqu'à {last} sur {totalRecords}"
                                onContextMenu={(e) => cm.current?.show(e.originalEvent)}
                                contextMenuSelection={selectedOrder || undefined} onContextMenuSelectionChange={(e) => setSelectedOrder(e.value)} >
                                <Column
                                    aria-labelledby="idAffaire"
                                    field="deal.id"
                                    header=""
                                    body={(rowData) => (
                                        <div onClick={() => handleSideBarOpen(rowData)}>
                                            <CommentIndicator text={rowData.id} comment={rowData?.deal?.customNotes} />
                                        </div>
                                    )}
                                ></Column>
                                <Column aria-labelledby="idAffaire" field="deal.id" header="Affaire" sortable style={{ fontWeight: "500" }} ></Column>
                                <Column aria-labelledby="firstname" field="clientId.firstName" header="Prénom" sortable style={{ fontWeight: "500" }} ></Column>
                                <Column aria-labelledby="lastname" field="clientId.lastName" header="Nom" sortable style={{ fontWeight: "500" }} body={(rowData) => UppercaseFormatter(rowData.clientId.lastName)} ></Column>
                                <Column aria-labelledby="type" field="clientId.type" header="Type" style={{ minWidth: '6rem' }} body={(rowData) => <TypeRenderer text={rowData.clientId.type} companyName={rowData.clientId.companyName} />} sortable />
                                <Column aria-labelledby="creation" field="startDate" header="Ouverture" body={(rowData) => formatDate(rowData.startDate.toString())} sortable />
                                <Column aria-labelledby="status" field="status" header="Commande" body={(rowData) => <StatusRenderer text={rowData.status} />} sortable />
                                <Column aria-labelledby="installation" field="installationDate" header="Installation" body={(rowData) => formatDate(rowData.installationDate)} sortable />
                                <Column aria-labelledby="siretCode" field="clientId.siretCode" header="N° Siret" body={(rowData) => <SiretRenderer text={rowData.clientId.siretCode} />} sortable></Column>
                                <Column aria-labelledby="commercial" field="clientId.partnerId.lastName" header="Commercial" sortable />
                                <Column aria-labelledby="concession" field="clientId.partnerId.partner.name" header="Concession" sortable />
                                <Column aria-labelledby="update" field="updateDate" header="Date de modification" body={(rowData) => formatDate(rowData.updateDate)} sortable />
                            </DataTable>
                        </div>
                    </Col>
                </Row>
                <ContextMenu model={actions} ref={cm}></ContextMenu>

                <Details visible={visible} onHide={handleHide} deal={sideBarData} order={true} />

                {showUpdateOrder && selectedOrder && (
                    <UpdateOrder open={showUpdateOrder} onClose={closeUpdateOrder} order={selectedOrder} handleSave={postUpdateAction} />
                )}

                <Toast ref={toast} position="top-right" />
            </Container>
        </div>
    )
}



export default Orders;
