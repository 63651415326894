import { useEffect, useRef, useState } from "react";
import { Deal } from "../../../entities/deal.entity";
import { Sidebar } from "primereact/sidebar";
import "./Details.scss";
import { Button } from "primereact/button";
import { FiPlus } from "react-icons/fi";
import AddComment from "../comments/AddComment";
import { Toast } from "primereact/toast";
import { Timeline } from 'primereact/timeline';

interface DetailsSideBar {
    deal?: any;
    visible: any;
    onHide: any;
    order?: boolean;
}

interface Comment {
    username: string;
    comment: string;
    date?: string;
}

const commentMappings: { [key: string]: { text: string, icon: string, iconStyle: React.CSSProperties, textStyle: React.CSSProperties } } = {
    TO_EVALUATE: { text: 'A évaluer', icon: 'pi pi-exclamation-circle', iconStyle: { color: '#F59E0B', fontWeight: '600' }, textStyle: { fontWeight: 'bold' } },
    TO_SIGN: { text: 'A signer', icon: 'pi pi-spinner', iconStyle: { color: '#6366F1', fontWeight: '600' }, textStyle: { fontWeight: 'bold' } },
    LOST: { text: 'Perdu', icon: 'pi pi-times-circle', iconStyle: { color: 'red', fontWeight: '600' }, textStyle: { fontWeight: 'bold' } },
    WON: { text: 'Gagnée', icon: 'pi pi-check-circle', iconStyle: { color: 'green', fontWeight: '600' }, textStyle: { fontWeight: 'bold' } },
    TO_AMEND: { text: 'A modifier', icon: 'pi pi-exclamation-circle', iconStyle: { color: 'orange', fontWeight: '600' }, textStyle: { fontWeight: 'bold' } },
    UNREACHABLE: { text: 'Client injoignable', icon: 'pi pi-exclamation-circle', iconStyle: { color: 'red', fontWeight: '600' }, textStyle: { fontWeight: 'bold' } },
    TO_PLAN: { text: 'Installation à planifier', icon: 'pi pi-plus-circle', iconStyle: { color: '#F59E0B', fontWeight: '600' }, textStyle: { fontWeight: 'bold' } },
    PLANNED: { text: 'Installation planifiée', icon: 'pi pi-check-circle', iconStyle: { color: 'green', fontWeight: '600' }, textStyle: { fontWeight: 'bold' } },
};

function transformComment(comment: string) {
    return commentMappings[comment] || { text: comment, icon: 'pi pi-comments', iconStyle: {}, textStyle: {} };
}


function Details({ visible, onHide, deal, order }: DetailsSideBar) {
    const [commentArray, setCommentArray] = useState<Comment[] | undefined>(undefined);
    const [showAddCommentModal, setShowAddCommentModal] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const toast = useRef<Toast | null>(null);

    useEffect(() => {
        if (order && deal?.deal && deal.deal?.customNotes) {
            if (deal.deal.customNotes) {
                parseComments(deal.deal.customNotes);
            } else {
                setCommentArray(undefined);
            }

        } else {
            if (deal && deal.customNotes) {
                parseComments(deal.customNotes);
            } else {
                setCommentArray(undefined);
            }
        }

    }, [deal, deal?.customNotes]);

    function parseComments(comments: string) {
        const deserializedComment: Comment[] = JSON.parse(comments);
        setCommentArray(deserializedComment);
    }

    const handleAddComment = () => {
        if (deal) {
            setShowAddCommentModal(true);
        }
    };

    const closeAddCommentModal = () => {
        setShowAddCommentModal(false);
    };

    const handleUpdate = (data: string) => {
        setToastMessage(data);
        if (toast.current) {
            switch (data) {
                case "successful update":
                    toast.current.show({ severity: 'success', summary: 'Modification enregistrée', life: 6000 });
                    window.location.reload();
                    break;
                case "failed update":
                    toast.current.show({ severity: 'error', summary: 'Echec de la modification', life: 6000 });
                    break;
                case "failed email":
                    toast.current.show({ severity: 'error', summary: 'Echec de l\'envoi de mail', life: 6000 });
                    break;
                default:
                    break;
            }
        }
    };

    const customizedMarker = (item: Comment) => {
        const transformedComment = transformComment(item.comment);
        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1 iconStyle" >
                <i style={transformedComment.iconStyle} className={transformedComment.icon}></i>
            </span>
        );
    };

    const customizedContent = (item: Comment) => {
        const transformedComment = transformComment(item.comment);
        return (
            <div className="row customized-content" >
                <span className="span__date" style={transformedComment.iconStyle}>{transformedComment.text}</span>
                <span className="span__name">{item.username}</span>
                <span className="span__content">{item.date}</span>
            </div>
        );
    };

    return (
        <div className="card flex justify-content-center customized-timeline"
        >
            <Sidebar visible={visible} onHide={onHide} closeIcon="false">
                <h2 style={{ marginBottom: "0px", fontSize: '30px' }}>Commentaires</h2>
                <div className="div__button">
                    <Button type="button" text onClick={handleAddComment}>
                        <div style={{ marginBottom: '8px' }}>
                            <FiPlus style={{ fontSize: '12px', fontWeight: '500' }} />
                            <span style={{ fontSize: '12px' }}> Ajouter un commentaire</span>
                        </div>
                    </Button>
                </div>
                <div style={{ marginTop: '12px' }}>
                    {commentArray && (
                        <Timeline value={commentArray} align="left" marker={customizedMarker} content={customizedContent} />
                    )}
                </div>
            </Sidebar>
            {
                showAddCommentModal && deal && (
                    <AddComment open={showAddCommentModal} onClose={closeAddCommentModal} deal={deal} handleUpdate={handleUpdate} />
                )
            }
            <Toast ref={toast} position="top-right" />
        </div>
    );
}

export default Details;
