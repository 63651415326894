import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, updateStep5DeltaData } from "../../../../redux/actions/StepsActions";
import "../Step.scss";
import { StationType } from "../../../../constants/enum";
import { Quote } from "../../../../entities/quote.entity";
import { AxiosResponse } from "axios";
import { PriceWithDetailDto } from "../../../../dtos/price-with-details.dto";
import { getQuotePrice } from "../../../../services/quote.service";
import { Button, Card, Col, Row, Text } from "@nextui-org/react";
import { Container } from "react-bootstrap";
import { ApplyTVA } from "../../../../helper/tvaHelper";
import "./Step5Products.scss";
import { getImage } from "../../../../helper/getImage";

function Step5Connected() {
    const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);
    const quoteData = useSelector((state: any) => state.steps);
    const distanceData = useSelector((state: any) => state.distance.calculatedDistance);
    const [connectedStations, setConnectedStations] = useState<any>([]);
    const [filteredConnectedStations, setfilteredConnectedStations] = useState<any>([]);

    useEffect(() => {
        let quote: Quote = {
            typeOfPerson: quoteData.step1Data,
            housing: quoteData.step2Data,
            circuitBreaker: quoteData.step3Data,
            distance: distanceData,
            // typeOfStation: quoteData.step5Data
        };
        (async () => {
            await getQuotePrice(quote)
                .then((response: AxiosResponse<PriceWithDetailDto[]>) => {
                    let stations = response.data.filter(station => station.total.type === StationType.Connected);
                    setConnectedStations(stations)
                    const updatedStations = stations.map(station => ({
                        ...station,
                        detail: {
                            ...station.detail,
                            products: station.detail.products.filter(product => product.category === 'Borne')
                        }
                    }));
                    setfilteredConnectedStations(updatedStations);
                })
                .catch(err => console.error('Disconnected'));
        })();
        const handleResize = () => {
            setIsXSmallScreen(window.innerWidth < 650);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [connectedStations.length, filteredConnectedStations.length]);
    const widthClass = isXSmallScreen ? "150px" : "200px";
    const dispatch = useDispatch();
    const handleCardSelect = (id: any) => {
        let selectedStation = connectedStations.find((station: any) => station.stationId === id);
        let data = { amount: selectedStation.total.price, detail: selectedStation.detail };
        dispatch(updateStep5DeltaData(data));
        dispatch(nextStep());
    };


    const isDark = useSelector((state: any) => state.theme.isDark);

    return (
        <div className="custom__container">
            <Text className="step__title"> Veuillez choisir votre borne connectée </Text>
            <div className="content__container">
                {filteredConnectedStations.map((item: any) => (
                    <div
                        key={item.id}
                        onClick={() => handleCardSelect(item.stationId)}>
                        <Card className="card__selectedEffect" css={{ w: "360px", h: "380px", padding: "20px" }} isPressable>
                            <Card.Header css={{ padding: "0px" }} >
                                <Col>
                                    <Text h4 className="header__productName" style={{ color: "#59527C" }} >
                                        {item.detail.products[0].name}
                                    </Text>
                                </Col>
                            </Card.Header>
                            <Card.Body css={{ p: 0 }}>
                                <Row style={{ marginBottom: "8px" }}>
                                    <Col span={6}>
                                        <div className="div__StationSubtitle" style={{ marginTop: "15px" }}>
                                            <span className="span__typeSub">Type :</span>
                                            <span className="span__text">Borne connectée</span>
                                        </div>
                                        <div className="div__StationSubtitle">
                                            <span className="span__typeSub">Marque:</span>
                                            <span className="span__text">{item.detail.products[0].brand}</span>
                                        </div>
                                        <div className="div__StationSubtitle">
                                            <span className="span__typeSub">Puissance (Mono):</span>
                                            <span className="span__text">{item.detail.products[0].powerMono}</span>
                                        </div>
                                        <div className="div__StationSubtitle">
                                            <span className="span__typeSub">Puissance (Triphasé):</span>
                                            <span className="span__text">{item.detail.products[0].powerTri}</span>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <img src={getImage(item.detail.products[0].name)} alt={item.detail.products[0].name} className="w-6 shadow-2 image__cardStation" />
                                    </Col>
                                </Row>
                                <Text className="body__description">
                                    {item.detail.products[0].description}
                                </Text>
                            </Card.Body>
                            <Card.Footer>
                                <Container className="mb-0 mt-0">
                                    <Row justify="flex-end">
                                        <Text className="footer__text">{ApplyTVA(item.total.price, quoteData.step1Data)} € (TTC)</Text>
                                    </Row>
                                </Container>
                            </Card.Footer>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Step5Connected;
