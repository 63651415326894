import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nextStep, updateStep2Data, previousStep } from "../../../../../redux/actions/StepsActions";
import { Text } from "@nextui-org/react";
import SharedCard from "../../../../shared/SharedCard";
import coliving from "../../../../../assets/quote-icons/coliving.png";
import privateParking from "../../../../../assets/quote-icons/privateParking.png";
import publicParking from "../../../../../assets/quote-icons/publicParking.png";
import "../../Step.scss";
import { QuoteConstants } from "../../../../../constants/enum";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { FaBuildingLock, FaHouse } from "react-icons/fa6";
import { Tooltip } from "primereact/tooltip";


function Step2BisProfessional() {
  const dispatch = useDispatch();
  const selectedCard = useSelector((state: any) => state.steps.selectedCards.step2);

  const handleCardSelect = (selectedData: any) => {
    dispatch(updateStep2Data(selectedData));
    dispatch(nextStep());
  };
  const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);

  useEffect(() => {
    const handleResize = () => {
      setIsXSmallScreen(window.innerWidth < 650);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const widthClass = isXSmallScreen ? "150px" : "200px";

  return (
    <div className="custom__container">
      <Text className="step__title">Votre borne sera installé sur ?</Text>
      <div className="content__container" style={{ justifyContent: 'center' }}>
        <div
          className={selectedCard === QuoteConstants.houseOfAPro ? "card__selected" : ""}
          onClick={() => handleCardSelect(QuoteConstants.houseOfAPro)}>
          <SharedCard
            title={QuoteConstants.houseOfAPro}
            icon={<FaHouse size="100%" className="w-6 shadow-2" />}
            width={widthClass}
            height="192px"
            layout="square" />
        </div>
        <div
          className={selectedCard === QuoteConstants.privateParking ? "card__selected" : ""}
          onClick={() => handleCardSelect(QuoteConstants.privateParking)}>
          <SharedCard
            title={QuoteConstants.privateParking}
            icon={<FaLock size="100%" className="w-6 shadow-2" />}
            width={widthClass}
            layout="square" />
        </div>

      </div>
      <div className="content__container" style={{ justifyContent: 'center', marginTop: '15px' }}>
        <div
          className={selectedCard === QuoteConstants.publicParking ? "card__selected" : ""}
          onClick={() => handleCardSelect(QuoteConstants.publicParking)}>
          <SharedCard
            title={QuoteConstants.publicParking}
            icon={<FaLockOpen size="100%" className="w-6 shadow-2" />}
            width={widthClass}
            layout="square" />
        </div>

        <Tooltip target=".custom-tooltip-btn">
          <span style={{ fontSize: "12px" }}>EMS ne permet pas ce type d’installation.</span>
        </Tooltip>


        <div className="custom-tooltip-btn"
        // onClick={() => handleCardSelect(QuoteConstants.sharedParking)}
        >
          <SharedCard
            title={QuoteConstants.sharedParking}
            icon={<FaBuildingLock size="100%" className="w-6 shadow-2" />}
            width={widthClass}
            layout="square"
            clickable={false} />
        </div>

      </div>
    </div >
  );
}

export default Step2BisProfessional;
