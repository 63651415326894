import { useRef, useState } from "react";
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadUploadEvent, ItemTemplateOptions } from 'primereact/fileupload';
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import "./upload.scss"
import { uploadFile } from "../../../../services/file-upload.service";

interface UploadProps {
    onUploadedFile: (data: string) => void;
}

function UploadQuote({ onUploadedFile }: UploadProps) {

    const toast = useRef<Toast>(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef<FileUpload>(null);
    const env = process.env.ENV;

    const onTemplateUpload = (e: FileUploadUploadEvent) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current?.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };
    const onUploadFile = (event: any): void => {
        const formData = new FormData();
        const files: File[] = event.files;
        let uploadFilePromise: any[] = [];
        files.forEach(file => {
            uploadFilePromise.push(uploadFile(file, env));
        })

        Promise.all(uploadFilePromise).then((data) => {
            onUploadedFile(data[0].data.message);
        })
            .catch((error) => {
                console.error('Error:', error);
            });

    };
    const onTemplateRemove = (file: File, callback: Function) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
            </div>
        );
    };

    const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
        const file = inFile as File;
        return (
            <div className="div-uploadedInfo">
                <span style={{ fontWeight: "500" }}>Fichier importé : {file.name}</span>
                <span style={{ fontSize: "14px" }}>Taille : {props.formatSize} </span>
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-2 p-3" style={{ fontSize: '16px', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '14px', color: 'var(--text-color-secondary)' }} className="my-5">
                    Glisser et déposer votre fichier ici
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };
    const API_URL = process.env.REACT_APP_API_URL;
    return (
        <div>
            <div className="upload">
                <Toast ref={toast}></Toast>

                <Tooltip target=".custom-choose-btn" content="Choisir" position="bottom" style={{ fontSize: "10px" }} />
                <Tooltip target=".custom-upload-btn" content="Importer" position="bottom" style={{ fontSize: "10px" }} />
                <Tooltip target=".custom-cancel-btn" content="Supprimer" position="bottom" style={{ fontSize: "10px" }} />

                <FileUpload ref={fileUploadRef} name="demo[]" url={`${API_URL}upload/file`} multiple accept="pdf/*" maxFileSize={1000000}
                    onError={onTemplateClear} onClear={onTemplateClear}
                    headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                    chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} customUpload uploadHandler={e => onUploadFile(e)} />

            </div>

        </div>
    )
}

export default UploadQuote;
