import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nextStep, updateStep3Data, updateStep5Data, } from "../../../../redux/actions/StepsActions";
import { Button, Card, Col, Row, Text } from "@nextui-org/react";
import "../Step.scss";
import "./Step5.scss"
import { FaCheckCircle, FaWifi } from "react-icons/fa";
import { Quote } from "../../../../entities/quote.entity";
import { getCheapestQuote, getQuotePrice } from "../../../../services/quote.service";
import { PriceWithDetailDto, cheapestEMSDeal } from "../../../../dtos/price-with-details.dto";
import { AxiosResponse } from "axios";
import { ApplyTVA } from "../../../../helper/tvaHelper";
import { Container } from "react-bootstrap";
import CustomButton from "../../../shared/Buttons/customButton";
import { CircuitBreaker, QuoteConstants, StationType } from "../../../../constants/enum";

function Step5() {
    const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);
    const [totalConnected, setTotalConnected] = useState<number | null>(null);
    const [totalDisconnected, setTotalDisconnected] = useState<number | null>(null);
    const [detailDisconnected, setDetailDisconnected] = useState<any | null>(null);
    const [detailConnected, setDetailConnected] = useState<any | null>(null);
    const quoteData = useSelector((state: any) => state.steps);
    const distanceData = useSelector((state: any) => state.distance.calculatedDistance);

    useEffect(() => {
        let quote: Quote = {
            typeOfPerson: quoteData.step1Data,
            housing: quoteData.step2Data,
            circuitBreaker: quoteData.step3Data,
            distance: distanceData
        };
        (async () => {
            await getCheapestQuote(quote)
                .then((response: AxiosResponse<cheapestEMSDeal>) => {
                    setTotalConnected(response.data.cheapestConnectedEMSDeal.price);
                    setTotalDisconnected(response.data.cheapestDiconnectedEMSDeal.price);
                })
                .catch(err => console.error('Disconnected'));
        })();
        const handleResize = () => {
            setIsXSmallScreen(window.innerWidth < 650);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [totalConnected, totalDisconnected]);
    const widthClass = isXSmallScreen ? "150px" : "200px";

    const dispatch = useDispatch();
    // const handleCardSelect = (price: any, detail: any) => {
    //     let data = { amount: price, detail: detail };
    //     dispatch(updateStep5Data(data));
    //     dispatch(nextStep());
    // };
    const handleCardSelect = (type: string) => {
        dispatch(updateStep5Data(type));
        dispatch(nextStep());
    };
    const isDark = useSelector((state: any) => state.theme.isDark);
    const getDealTitle = () => {
        let distance = distanceData.slice(0, -1);
        if (distance <= 5) {
            return "Forfait 0-5 mètres";
        } else if (distance > 5 && distance <= 10) {
            return "Forfait 5-10 mètres";
        } else if (distance > 10 && distance <= 15) {
            return "Forfait 10-15 mètres";
        } else if (distance > 15 && distance < 25) {
            return "Forfait 15-25 mètres";
        } else {
            return "Forfait 25 mètres+"
        }
    }
    return (
        <div className="custom__container">
            <Text className="step__title--forfait">{getDealTitle()}</Text>
            <Text className="step__title--installateur" >
                Sous réserve de la confirmation de notre installateur
            </Text>
            <Text className="text_instruction">
                Cliquer afin de choisir le type votre borne de recharge. <span className="span__descriptionNote">Le prix sera ajusté en fonction de la borne choisie.</span>
            </Text>
            <div className="content__container" style={{ columnGap: "15px" }}>
                <div onClick={() => handleCardSelect(StationType.Connected)}>
                    <Card className="card__selectedEffect" css={{ w: "390px", h: "400px", padding: "20px" }} isPressable>
                        <Card.Header css={{ padding: "0px" }} >
                            <Col>
                                <Row css={{ columnGap: "5px" }}>
                                    <Button flat auto rounded color="secondary" css={{ h: "30px", cursor: "pointer" }}>
                                        <Text css={{ color: "inherit" }} size={12} className="header__badge">Borne</Text>
                                    </Button>
                                    <Button flat auto rounded color="secondary" css={{ h: "30px", cursor: "pointer" }}>
                                        <Text css={{ color: "inherit" }} size={12} className="header__badge">Powerboost</Text>
                                    </Button>
                                    <Button flat auto rounded color="secondary" css={{ h: "30px", cursor: "pointer" }}>
                                        <Text css={{ color: "inherit" }} size={12} className="header__badge">Installation</Text>
                                    </Button>
                                </Row>
                                <div className="title_icon">
                                    <FaWifi style={{ fontSize: "24px", marginTop: "8px" }} />
                                    <Text h4 className="header__productName" >
                                        Borne  conncetée
                                    </Text>
                                </div>

                            </Col>
                        </Card.Header>
                        <Card.Body css={{ p: 0 }}>
                            <Text className="body__description">
                                Connectivité avancée pour une gestion à distance et un suivi en temps réel de la recharge de votre véhicule électrique via des applications dédiées.
                            </Text>
                            <Row className="body__bulletPoints" css={{ alignItems: "center" }}>
                                <FaCheckCircle className="isDark ? body__iconConnected-light : body__iconConnected-light"></FaCheckCircle>
                                <Text className="body__textIcon">{quoteData.step1Data}</Text>
                            </Row>
                            <Row className="body__bulletPoints" css={{ alignItems: "center" }}>
                                <FaCheckCircle className="isDark ? body__iconConnected-light : body__iconConnected-light"></FaCheckCircle>
                                <Text className="body__textIcon">{quoteData.step2Data}</Text>
                            </Row>
                            <Row className="body__bulletPoints" css={{ alignItems: "center" }}>
                                <FaCheckCircle className="isDark ? body__iconConnected-light : body__iconConnected-light"></FaCheckCircle>
                                <Text className="body__textIcon">{quoteData.step3Data === 'MONO' ? QuoteConstants.circuitBreaker1 : QuoteConstants.circuitBreaker3}</Text>
                            </Row>
                            <Row className="body__bulletPoints" css={{ alignItems: "center" }}>
                                <FaCheckCircle className="isDark ? body__iconConnected-light : body__iconConnected-light" ></FaCheckCircle>
                                <Text className="body__textIcon">Installation certifiée IRVE - {distanceData} mètres</Text>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Container className="mb-0 mt-0">
                                <Row justify="flex-end">
                                    <Text className="footer__text"><span className="span__priceNote">A partir de </span> {ApplyTVA(totalConnected!, quoteData.step1Data)} € (TTC)</Text>
                                </Row>
                            </Container>
                        </Card.Footer>
                    </Card>
                </div>
                {/* <div onClick={() => handleCardSelect(totalDisconnected, detailDisconnected)}> */}
                <div onClick={() => handleCardSelect(StationType.Disconnected)}>
                    {quoteData.step3Data !== CircuitBreaker.Tri && <Card className="card__selectedEffect" css={{ w: "390px", h: "400px", padding: "20px" }} isPressable>
                        <Card.Header css={{ padding: "0px" }} >
                            <Col>
                                <Row css={{ columnGap: "5px" }}>
                                    <CustomButton title="Borne" />
                                    <CustomButton title="Powerboost" />
                                    <CustomButton title="Installation" />
                                </Row>

                                <Text h4 className="header__productName" >
                                    Borne non connectée
                                </Text>
                            </Col>
                        </Card.Header>
                        <Card.Body css={{ p: 0 }}>
                            <Text className="body__description">
                                Solution simple et fiable pour une recharge efficace. Facile  à utiliser, cette borne est une option idéale pour ceux qui privilégient la simplicité.
                            </Text>
                            <Row className="body__bulletPoints" css={{ alignItems: "center" }}>
                                <FaCheckCircle className="isDark ? body__icon-dark : body__icon-light"></FaCheckCircle>
                                <Text className="body__textIcon">{quoteData.step1Data}</Text>
                            </Row>
                            <Row className="body__bulletPoints" css={{ alignItems: "center" }}>
                                <FaCheckCircle className="isDark ? body__icon-dark : body__icon-light"></FaCheckCircle>
                                <Text className="body__textIcon">{quoteData.step2Data}</Text>
                            </Row>
                            <Row className="body__bulletPoints" css={{ alignItems: "center" }}>
                                <FaCheckCircle className="isDark ? body__icon-dark : body__icon-light"></FaCheckCircle>
                                <Text className="body__textIcon">{quoteData.step3Data === 'MONO' ? QuoteConstants.circuitBreaker1 : QuoteConstants.circuitBreaker3}</Text>
                            </Row>
                            <Row className="body__bulletPoints" css={{ alignItems: "center" }}>
                                <FaCheckCircle className="isDark ? body__icon-dark : body__icon-light"></FaCheckCircle>
                                <Text className="body__textIcon">Installation certifiée IRVE - {distanceData} mètres</Text>
                            </Row>

                        </Card.Body>
                        <Card.Footer>
                            <Container className="mb-0 mt-0">
                                <Row justify="flex-end">
                                    <Text className="footer__text"><span className="span__priceNote">A partir de </span> {ApplyTVA(totalDisconnected!, quoteData.step1Data)} € (TTC)</Text>
                                </Row>
                            </Container>
                        </Card.Footer>
                    </Card>
                    }
                </div>
            </div>
        </div >
    );
}

export default Step5;
