const TVAIndividual = 5.5;
const TVAProfessional = 20;

export function ApplyTVA(price: number, clientType?: string): string {
    if (clientType === 'Professionnel') {
        return (price * (1 + TVAProfessional / 100)).toFixed(2);
    }
    return (price * (1 + TVAIndividual / 100)).toFixed(2);
}

export function RemoveTVA(price: number, clientType?: string): string {
    if (clientType === 'Professionnel') {
        let x = price / (1 + (TVAProfessional / 100));
        return x.toFixed(2);
    } else {
        let x = price / (1 + (TVAIndividual / 100));
        return (price / (1 + (TVAIndividual / 100))).toFixed(2);
    }

}