// import { useAnimation } from "framer-motion";
import React, { Children, useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

interface QuoteAnimationProps {
    children: JSX.Element;
    width?: "fit-content" | "100%";
    trigger?: boolean;
}

function QuoteAnimation({ children, width = "fit-content", trigger = false }: QuoteAnimationProps) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true })

    const mainControls = useAnimation();

    useEffect(() => {
        if (trigger) {
            mainControls.start("visible");
        } else {
            mainControls.start("hidden");
        }
    }, [trigger, mainControls]);

    useEffect(() => {
        if (isInView) {
        }
    })
    return (
        <div style={{ position: "relative", width: "100%", overflow: "hidden" }}>
            <motion.div
                variants={{
                    hidden: { opacity: 0, x: "100%" },
                    visible: { opacity: 1, x: "0%" },
                    exiting: { opacity: 0, x: "-100%" },
                }}
                // This is how you trigger
                initial="hidden"
                // animate = "visible"
                animate={mainControls}
                exit="exiting"
                transition={{ duration: 0.3, delay: 0.25 }}
            >{children}</motion.div>


        </div>
    )
}

export default QuoteAnimation;