import { useState, useRef, useEffect } from "react";
import "./PartnersHome.scss";
import Quote from "../quote/Quote";
import SharedCard from "../shared/SharedCard";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { Deal } from "../../entities/deal.entity";
import { getDeals, getInstallationPerDeal, getNumberOfDeals, getProductPerDeal } from "../../services/deals.service";
import { DealStatus, homePageFilters } from "../../constants/enum";
import { Button } from "primereact/button";
import { FaUserCircle } from "react-icons/fa";
import { Order } from "../../entities/order.entity";
import { getNumberOfOrders } from "../../services/orders.service";
import { useNavigate } from "react-router-dom";
import { LightUser } from "../../entities/LightUser.entity";
import { getTop3Users } from "../../services/users.service";
import { dashboardNumbers } from "../../entities/dashboardNumbers.entity";
import { priceFormattor } from "../../helper/priceFormattor";
import { ProductPerDealDto } from "../../dtos/product-per-deal.dto";
import { InstallationPerDealDto } from "../../dtos/installation-per-deal.dto";


function PartnersHome() {
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toast = useRef<Toast | null>(null);
  const [deals, setDeals] = useState<Deal[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [productsPerDeal, setProductsPerDeal] = useState<ProductPerDealDto[]>([]);
  const [installationsPerDeal, setInstallationsPerDeal] = useState<InstallationPerDealDto[]>([]);


  const loggedUser = useSelector((state: any) => state.login.loggedUser);
  const [filter, setFilter] = useState<string>("user");
  const [timeFilter, setTimeFilter] = useState<string>("allTime");

  //buttons
  const [user, showUser] = useState<boolean>(true);
  const [concession, showConcession] = useState<boolean>(false);
  const [plaque, showPlaque] = useState<boolean>(false);

  const [allTime, showAllTime] = useState<boolean>(true);
  const [threeMonths, showThreeMonths] = useState<boolean>(false);
  const [currentYear, showCurrentYear] = useState<boolean>(false);

  const [ca, setCA] = useState<number>(0);
  const [top3, setTop3] = useState<LightUser[]>([]);

  const [orderStat, setOrderStat] = useState<dashboardNumbers[]>([]);
  const [dealStat, setDealStat] = useState<dashboardNumbers[]>([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const dealsResponse = await getDeals();
        setDeals(dealsResponse.data as Deal[]);

        const orderStatResponse = await getNumberOfDeals(loggedUser.id, loggedUser.partner.id, loggedUser.partner.parentPartnerId);
        setDealStat(orderStatResponse.data);

        const dealStatResponse = await getNumberOfOrders(loggedUser.id, loggedUser.partner.id, loggedUser.partner.parentPartnerId);
        setOrderStat(dealStatResponse.data as dashboardNumbers[]);

        const top3Response = await getTop3Users(loggedUser.partner.parentPartnerId);
        setTop3(top3Response.data as LightUser[]);

        const productsPerDeal = await getProductPerDeal();
        setProductsPerDeal(productsPerDeal.data as ProductPerDealDto[]);

        const installationsPerDeal = await getInstallationPerDeal();
        setInstallationsPerDeal(installationsPerDeal.data as InstallationPerDealDto[]);

      } catch (err) {
        console.error('Error loading data:', err);
      }
    };

    fetchData();
  }, []);
  const navigate = useNavigate();
  const onRedirect = (key: string) => {
    switch (key) {
      case "deals":
        if (filter === "user") {
          handleCardClick("deals");
        } else {
          if (loggedUser.role !== "Commercial") {
            handleCardClick("deals");
          }
        }
        break;
      case "orders":
        if (filter === "user") {
          handleCardClick("orders");
        } else {
          if (loggedUser.role !== "Commercial") {
            handleCardClick("orders");
          }
        }
        break;
      case "installations":
        if (filter === "user") {
          handleCardClick("installations");
        } else {
          if (loggedUser.role !== "Commercial") {
            handleCardClick("installations");
          }
        }
    }
  }

  const handleCardClick = (key: string) => {
    switch (key) {
      case "orders":
        navigate("/orders", { state: { filter, type: "order" } });
        break;
      case "installations":
        navigate("/orders", { state: { filter, type: "installation" } });
        break;
      case "deals":
        navigate("/deals", { state: { filter } });
        break;
      default:
        break;

    }
  };

  const onSelectFilter = (filter: string) => {
    setFilter(filter);
    switch (filter) {
      case homePageFilters.user:
        showUser(true);
        showPlaque(false);
        showConcession(false);
        break;
      case homePageFilters.concession:
        showPlaque(false);
        showConcession(true);
        showUser(false);
        break;
      case homePageFilters.plaque:
        showPlaque(true);
        showConcession(false);
        showUser(false);
        break;
      default:
        break;
    }
  }
  const onTimeFilter = (filter: string) => {
    setTimeFilter(filter);
    switch (filter) {
      case homePageFilters.allTime:
        showAllTime(false);
        showCurrentYear(false);
        showThreeMonths(true);
        break;
      case homePageFilters.months:
        showCurrentYear(true);
        showThreeMonths(false);
        showAllTime(false);
        break;
      case homePageFilters.currentYear:
        showCurrentYear(false);
        showThreeMonths(false);
        showAllTime(true);
        break;
      default:
        break;
    }
  }


  const getOrderStat = (data: string) => {
    let typeFilter: string = "";
    let timeFilter: string = "";

    if (user) typeFilter = "Commercial";
    else if (concession) typeFilter = "Concession";
    else if (plaque) typeFilter = "Plaque";

    if (allTime) timeFilter = "all";
    else if (threeMonths) timeFilter = "threeMonths";
    else if (currentYear) timeFilter = "currentYear";

    if (typeFilter && timeFilter) {
      const userNumbers = orderStat.find((result: any) => result.type === typeFilter && result.data === data);
      return userNumbers ? userNumbers[timeFilter as keyof dashboardNumbers].toString() : "0";
    }
  };

  const getDealStat = () => {
    let typeFilter: string = "";
    let timeFilter: string = "";

    if (user) typeFilter = "Commercial";
    else if (concession) typeFilter = "Concession";
    else if (plaque) typeFilter = "Plaque";

    if (allTime) timeFilter = "all";
    else if (threeMonths) timeFilter = "threeMonths";
    else if (currentYear) timeFilter = "currentYear";

    if (typeFilter && timeFilter) {
      const userNumbers = dealStat.find((result: any) => result.type === typeFilter);
      return userNumbers ? userNumbers[timeFilter as keyof dashboardNumbers].toString() : "0";
    }

  };


  const getCA = (filter: string) => {
    let total: number = 0;
    let newDeals: Deal[] = [];
    if (allTime) {
      newDeals = deals.filter(deal => deal.status === DealStatus.Won);
    } else if (threeMonths) {
      const currentDate = new Date();
      const threeMonthsAgo = new Date(currentDate);
      threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
      newDeals = deals.filter((deal) => deal.status === DealStatus.Won && new Date(deal?.creationDate ? deal.creationDate : currentDate) > threeMonthsAgo);

    } else if (currentYear) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      newDeals = deals.filter((deal) => deal.status === DealStatus.Won && new Date(deal?.creationDate ? deal.creationDate : currentDate).getFullYear() === currentYear);
    }

    if (deals.length !== 0) {
      switch (filter) {
        case homePageFilters.user:
          newDeals = newDeals.filter(deal => deal.clientId?.partnerId?.email === loggedUser.email);
          break;
        case homePageFilters.concession:
          newDeals = newDeals.filter(deal => deal.clientId?.partnerId?.partner?.name === loggedUser.partner.name);

          break;
        case homePageFilters.plaque:
          newDeals = newDeals.filter(deal => deal.clientId?.partnerId?.partner?.parentPartnerId === loggedUser.partner.parentPartnerId);
          break;
        default:
          break;
      }
    }
    newDeals.forEach((deal) => {
      total += Number(deal.amount);
    });

    return priceFormattor(total.toString());
  };

  const getCommission = (filter: string) => {
    let total: number = 0;
    let newDeals: Deal[] = [];
    if (allTime) {
      newDeals = deals.filter(deal => deal.status === DealStatus.Won);
    } else if (threeMonths) {
      const currentDate = new Date();
      const threeMonthsAgo = new Date(currentDate);
      threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
      newDeals = deals.filter((deal) => deal.status === DealStatus.Won && new Date(deal?.creationDate ? deal.creationDate : currentDate) > threeMonthsAgo);

    } else if (currentYear) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      newDeals = deals.filter((deal) => deal.status === DealStatus.Won && new Date(deal?.creationDate ? deal.creationDate : currentDate).getFullYear() === currentYear);
    }

    if (deals.length !== 0) {
      switch (filter) {
        case homePageFilters.user:
          newDeals = newDeals.filter(deal => deal.clientId?.partnerId?.email === loggedUser.email);
          break;
        case homePageFilters.concession:
          newDeals = newDeals.filter(deal => deal.clientId?.partnerId?.partner?.name === loggedUser.partner.name);

          break;
        case homePageFilters.plaque:
          newDeals = newDeals.filter(deal => deal.clientId?.partnerId?.partner?.parentPartnerId === loggedUser.partner.parentPartnerId);
          break;
        default:
          break;
      }
    }
    newDeals.forEach((deal) => {
      let hasInstallation = installationsPerDeal.some(ipd => ipd.deal?.id === deal.id);
      if (hasInstallation) {
        total += 30;
      }
      let dealProductsCommissions = productsPerDeal.filter(ppd => ppd.deal?.id === deal.id).map(ppd => ppd.product.commission);
      if (dealProductsCommissions.length > 0) {
        let dealCommission = dealProductsCommissions.reduce((acc, value) => {
          return Number(acc) + Number(value)
        });
        total += dealCommission;

      }


    });
    return priceFormattor(total.toString());
  };

  const openQuoteModal = () => {
    setShowQuoteModal(true);
  };

  const closeQuoteModal = () => {
    setShowQuoteModal(false);
  };

  const handleQuoteData = (data: any) => {
    setToastMessage(data);
    if (toast.current) {
      switch (data) {
        case "success client":
          toast.current.show({ severity: 'success', summary: 'Client créé avec succès', life: 6000 });
          break;
        case "success deal":
          toast.current.show({ severity: 'success', summary: 'Affaire associée créé avec succès', life: 6000 });
          break;
        case "success quote":
          toast.current.show({ severity: 'success', summary: 'Devis envoyé au client', life: 6000 });
          break;
        case "error":
          toast.current.show({ severity: 'error', summary: 'Echec de la création du devis', life: 6000 });
          break;
        case "error quote":
          toast.current.show({ severity: 'error', summary: 'Echec de l\'envoi du devis', life: 6000 });
          break;
        case "error deal":
          toast.current.show({ severity: 'error', summary: 'Echec de la création de l\'affaire associé', life: 6000 });
          break;
        default:
          break;
      }
    }
  };
  return (
    <div>
      <div className="container__homepage">
        <div className="div__colOne">
          <div onClick={openQuoteModal}>
            <SharedCard
              title="Quick devis"
              width="225px"
              height="70px"
              layout="rectangle" />
          </div>
        </div>


        <div className="div__colTwo">
          <div className="div__users">
            {user && <Button aria-labelledby="btn" severity="secondary" outlined size="small" onClick={() => onSelectFilter(homePageFilters.concession)}
              label={loggedUser?.firstName}>
            </Button>}
            {concession && <Button aria-labelledby="btn" severity="secondary" outlined size="small" onClick={() => onSelectFilter(homePageFilters.plaque)}
              label="Concession ">
            </Button>}
            {
              plaque && <Button aria-labelledby="btn" severity="secondary" outlined size="small" onClick={() => onSelectFilter(homePageFilters.user)}
                label="Plaque">
              </Button>
            }

            {allTime && <Button aria-labelledby="btn" severity="secondary" outlined size="small"
              label="All time " onClick={() => onTimeFilter(homePageFilters.allTime)}>
            </Button>}
            {threeMonths && <Button aria-labelledby="btn" severity="secondary" outlined size="small"
              label="3T" onClick={() => onTimeFilter(homePageFilters.months)}>
            </Button>}
            {currentYear && <Button aria-labelledby="btn" severity="secondary" outlined size="small"
              label={`${new Date().getFullYear()}`} onClick={() => onTimeFilter(homePageFilters.currentYear)}>
            </Button>}
          </div>
          <div className="div_activity">
            <span className="span__homepageTitle">Activité</span>
          </div>
          <div className="div__sharedCards">
            <div onClick={() => onRedirect("deals")}>
              <SharedCard
                title="Affaires"
                number={getDealStat()}
                width="190px"
                height="90px"
                layout="rectangle"
              />
            </div>
            <div onClick={() => onRedirect("orders")}>
              <SharedCard
                title="commandes"
                number={getOrderStat("order")}
                width="190px"
                height="90px"
                layout="rectangle"
              />
            </div>
            <div onClick={() => onRedirect("installations")}>
              <SharedCard
                title="Installations"
                number={getOrderStat("installation")}
                width="190px"
                height="90px"
                layout="rectangle"
              />
            </div>
          </div>
          <div className="div_activity">
            <span className="span__homepageTitle">Revenu</span>
          </div>
          <div className="div__sharedCards">
            <SharedCard
              title="C.A"
              number={getCA(filter) + " €"}
              width="190px"
              height="90px"
              layout="rectangle"
              clickable={false}
            />
            <SharedCard
              title="Commissions"
              number={getCommission(filter) + " €"}
              width="190px"
              height="90px"
              layout="rectangle"
              clickable={false}
            />

          </div>
          <div className="div_activity">
            <span className="span__homepageTitle" style={{ marginTop: "5px" }}>Top vendeurs</span>
          </div>
          <div className="div__usersTop3">
            {top3.length > 0 && top3.map((user) => (
              <div key={user.user_id} className="subDiv__users col-3">
                <FaUserCircle size={35} />
                <div className="div__userInfo">
                  <span className="span__info">{user.firstName} {user.lastName}</span>
                  <span className="span__info" style={{ fontSize: "13px" }}>{user.partnerName}</span>
                </div>
              </div>
            ))
            }

          </div>
          {/* <div className="div_activity">
            <span className="span__homepageTitle">Top produits</span>
          </div>
          <div className="div__usersTop3">
            <div className="subDiv__users col-3">
              <MdPlaylistAddCheckCircle size={40} />
              <div className="div__userInfo">
                <span className="span__info">Borne A</span>
              </div>
            </div>
            <div className="subDiv__users col-3">
              <MdPlaylistAddCheckCircle size={40} />
              <div className="div__userInfo">
                <span className="span__info">Chargeur portable</span>
              </div>
            </div>
            <div className="subDiv__users col-3">
              <MdPlaylistAddCheckCircle size={40} />
              <div className="div__userInfo">
                <span className="span__info">Cable</span>
              </div>
            </div>
          </div> */}
        </div>

      </div>
      {showQuoteModal && (
        <Quote open={showQuoteModal} onClose={closeQuoteModal} onQuoteData={handleQuoteData} />
      )}
      <Toast ref={toast} position="top-right" />
    </div>
  );
}

export default PartnersHome;
