import React, { useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { PrimeReactProvider } from 'primereact/api';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { NextUIProvider, createTheme, getDocumentTheme } from '@nextui-org/react';
import Store from './redux/Store';
import './index.scss';
import './style.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { updateTheme } from './redux/actions/ThemeAction';

const lightTheme = createTheme({
  type: 'light',
  theme: {
    colors: {
      primary: '#4C4CFF',
      background: '#FFFFFF',
      surface: '#FFFFFF',
      text: '#333333',
    },
  },
});

const darkTheme = createTheme({
  type: 'dark',
  theme: {
    colors: {
      primary: '#4C4CFF',
      background: '#20262e',
      backgroundContrast: '#2a323d',
      surface: '#333333',
      text: '#FFFFFF',
      '--nextui--navbarBlurBackgroundColor': '#2a323d',
    },
  },
});

function Main() {
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    let theme = window.localStorage.getItem('data-theme');
    setIsDark(theme === 'dark');

    const observer = new MutationObserver((mutation) => {
      let newTheme = getDocumentTheme(document?.documentElement);
      setIsDark(newTheme === 'dark');
    });

    observer.observe(document?.documentElement, {
      attributes: true,
      attributeFilter: ['data-theme', 'style']
    });

    return () => observer.disconnect();
  }, []);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={Store}>
          <NextUIProvider theme={isDark ? darkTheme : lightTheme}>
            <PrimeReactProvider >
              <App />
            </PrimeReactProvider>
          </NextUIProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);
root.render(<Main />);

reportWebVitals();
