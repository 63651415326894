import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nextStep, updateStep3Data, } from "../../../../../redux/actions/StepsActions";
import { Text } from "@nextui-org/react";
import "../../Step.scss";
import SharedCard from "../../../../shared/SharedCard";
import { QuoteConstants } from "../../../../../constants/enum";


function Step3NumberOfStation() {
    const dispatch = useDispatch();
    const selectedCard = useSelector((state: any) => state.steps.selectedCards.step2);

    const handleCardSelect = (selectedData: any) => {
        dispatch(updateStep3Data(selectedData));
        dispatch(nextStep());
    };
    const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);

    useEffect(() => {
        const handleResize = () => {
            setIsXSmallScreen(window.innerWidth < 650);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const widthClass = isXSmallScreen ? "150px" : "200px";

    return (
        <div className="custom__container"   >
            <Text className="step__title">
                Combien de bornes vous voulez installer?
            </Text>
            <div className="content__container">
                <div
                    className={selectedCard === QuoteConstants.lessThanFive ? "card__selected" : ""}
                    onClick={() => handleCardSelect(QuoteConstants.lessThanFive)}>
                    <SharedCard
                        title={QuoteConstants.lessThanFive}
                        width={widthClass}
                        layout="square" />
                </div>
                <div
                    className={selectedCard === QuoteConstants.lessThanTen ? "card__selected" : ""}
                    onClick={() => handleCardSelect(QuoteConstants.lessThanTen)}>
                    <SharedCard
                        title={QuoteConstants.lessThanTen}
                        width={widthClass}
                        layout="square" />
                </div>
                <div
                    className={selectedCard === QuoteConstants.lessThanTen ? "card__selected" : ""}
                    onClick={() => handleCardSelect(QuoteConstants.lessThanTen)}>
                    <SharedCard
                        title={QuoteConstants.moreThanTen}
                        width={widthClass}
                        layout="square" />
                </div>
            </div>
        </div>
    );
}

export default Step3NumberOfStation;
