const initialState = {
    isDark: false
};

export const ThemeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "UPDATE_THEME":
            return {
                ...state,
                isDark: action.payload,
            };
        default:
            return state;
    }
}
