
import { Button } from 'primereact/button';
import CustomButton from '../shared/Buttons/customButton';
import { Link as LinkFromDom } from "react-router-dom";
import { Image } from "react-bootstrap";
import AppleImage from "../../assets/AppleImage";
import GoogleImage from "../../assets/GoogleImage";
import EclaireImage from "../../assets/EclaireImage";
import Numero1Image from "../../assets/quote-icons/numero-1.png";
import Numero2Image from "../../assets/quote-icons/numero-2.png";
import Numero3Image from "../../assets/quote-icons/numero-3.png";
import Numero4Image from "../../assets/quote-icons/numero-4.png";
import Checked from "../../assets/quote-icons/checked.png";
import "./ChargeCard.scss";
import { IconBase } from "react-icons";
import { FaCircleArrowUp, FaCircleCheck } from "react-icons/fa6";


function ChargeCard() {


    return (
        <div className="container" >
            <div className="row">
                <div  >
                    <div className="divEntete">
                        <div >
                            <EclaireImage></EclaireImage>
                        </div>
                        <div>
                            <span className="divPoliceTexteCarte">
                                La carte
                            </span>
                        </div>
                    </div>
                    <div className="divAvantages">
                        <div className='container'>
                            <div className='row'>
                                <div className="col-2" style={{ lineHeight: "32px" }} >
                                    <Image
                                        src={Checked}
                                        alt="Logo"
                                        height={20}
                                        width={20}
                                    />
                                </div>
                                <div className='col' style={{ padding: "0px" }}>
                                    <div className="divPoliceTexteAvantage">
                                        <span style={{ textAlign: "left" }}>
                                            Aucun abonnement
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-2" style={{ lineHeight: "32px" }}>
                                    <Image
                                        src={Checked}
                                        alt="Logo"
                                        height={20}
                                        width={20}
                                    />
                                </div>
                                <div className='col' style={{ padding: "0px" }}>
                                    <div className="divPoliceTexteAvantage">
                                        <span style={{ textAlign: "left" }}>
                                            Accedez à plus de 300 000 bornes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-2" style={{ lineHeight: "32px" }}>
                                    <Image
                                        src={Checked}
                                        alt="Logo"
                                        height={20}
                                        width={20}
                                    />
                                </div>
                                <div className='col' style={{ padding: "0px" }}>
                                    <div className="divPoliceTexteAvantage">
                                        <span style={{ textAlign: "left" }}>
                                            Prix au Kwh très compétitif
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divEspaces">
                    </div>
                    <div className="divEtapes">
                        <div className="etapesTitre">
                            <span className="divPoliceTexteUsage">
                                4 étapes
                            </span>
                            <br></br>
                            <span className="divPoliceTexteUsageSub">
                                pour utiliser votre carte
                            </span>
                        </div>
                        <div className="etapes">
                            <div>
                                <div>
                                    <Image
                                        src={Numero1Image}
                                        alt="Logo"
                                        height={30}
                                        width={30}
                                    />
                                </div>
                                <div style={{ paddingTop: "5px", paddingBottom: "20px" }}>
                                    <span className="divPoliceEtapeName">
                                        Telechargez l'application
                                    </span>
                                </div>
                            </div>
                            <div className="divImage" >
                                <LinkFromDom to="https://apps.apple.com/fr/app/easy-charging-quality/id1613916615">
                                    <AppleImage></AppleImage>
                                </LinkFromDom>
                            </div>
                            <div className="divImage">
                                <LinkFromDom to="https://play.google.com/store/apps/details?id=com.easyChargingQuality.app&hl=fr&gl=US&pli=1">
                                    <GoogleImage></GoogleImage>
                                </LinkFromDom>
                            </div>
                        </div>
                        <div className="etapes">
                            <div>
                                <div>
                                    <Image
                                        src={Numero2Image}
                                        alt="Logo"
                                        height={30}
                                        width={30}
                                    />
                                </div>
                                <div style={{ paddingTop: "5px", paddingBottom: "20px" }}>
                                    <span className="divPoliceEtapeName">
                                        Connectez la carte
                                    </span>
                                    <div style={{ paddingLeft: "30px", paddingRight: "30px", paddingTop: "15px" }}>
                                        <span className="divPoliceEtapeContenue">
                                            Renseigner dans l'application le code qui se trouve au dos de votre carte, commencant par FR-LMS.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="etapes">
                            <div>
                                <div>
                                    <Image
                                        src={Numero3Image}
                                        alt="Logo"
                                        height={30}
                                        width={30}
                                    />
                                </div>
                                <div style={{ paddingTop: "5px", paddingBottom: "20px" }}>
                                    <span className="divPoliceEtapeName">
                                        Connectez votre RIB
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="etapes">
                            <div>
                                <div>
                                    <Image
                                        src={Numero4Image}
                                        alt="Logo"
                                        height={30}
                                        width={30}
                                    />
                                </div>
                                <div style={{ paddingTop: "5px", paddingBottom: "20px" }}>
                                    <span className="divPoliceEtapeName">
                                        Rechargez votre voiture
                                    </span>
                                </div>
                                <div>
                                    <LinkFromDom to="https://ems.evc-net.com/Locations" className="divButton2">
                                        <CustomButton title="Nos points de charges" />
                                    </LinkFromDom>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divEspaces">
                    </div>
                </div>
                <div className="divEspaces">
                </div>
            </div>

        </div >
    );


};

export default ChargeCard;
