import { useSelector } from "react-redux";
import { QuoteConstants } from "../../../constants/enum";
import "./Type.scss";

interface badgeProps {
  text: string;
  companyName?: string;
}

function TypeRenderer({ text, companyName }: badgeProps) {
  const isDark = useSelector((state: any) => state.theme.isDark);

  function getTypeClass(text: string) {
    switch (text) {
      case QuoteConstants.individual:
        return isDark ? 'badge-individual-dark' : 'badge-individual';
      case QuoteConstants.professional:
        return isDark ? 'badge-professional-dark' : 'badge-professional';
      default:
        return '';
    }
  }

  const statusClass = getTypeClass(text);
  return (
    <div>
      <div>
        <span className={getTypeClass(text)}>{text}</span>
      </div>
      {
        companyName && <div>
          <span className={isDark ? 'badge-companyName-dark' : 'badge-companyName'}>{companyName}</span>
        </div>
      }
    </div>
  );
}

export default TypeRenderer;
