interface AdressProps {
  address: string;
  zipCode: string;
  city: string;
}
export const nextStep = () => ({ type: 'NEXT_STEP' });
export const previousStep = () => ({ type: 'PREVIOUS_STEP' });
export const updateStep1Data = (data: any) => ({ type: 'UPDATE_STEP1_DATA', payload: data });
export const updateStep2Data = (data: any) => ({ type: 'UPDATE_STEP2_DATA', payload: data });
export const updateStep3Data = (data: any) => ({ type: 'UPDATE_STEP3_DATA', payload: data });
export const updateStep4Data = (data: AdressProps) => ({ type: 'UPDATE_STEP4_DATA', payload: data });
export const updateStep4ProData = (data: string) => ({ type: 'UPDATE_STEP4_PRO_DATA', payload: data });
export const updateStep5Data = (data: any) => ({ type: 'UPDATE_STEP5_DATA', payload: data });
export const updateStep5DeltaData = (data: any) => ({ type: 'UPDATE_STEP5_DELTA_DATA', payload: data });
export const updateStep6Data = (data: any) => ({ type: 'UPDATE_STEP6_DATA', payload: data });

export const resetFields = () => ({ type: 'RESET_FIELDS' });



