import { useEffect, useState } from "react";
import { Col, Input, Modal, Row, Text } from "@nextui-org/react";
import { FiUser, FiXCircle } from "react-icons/fi";
import { Deal } from "../../../entities/deal.entity";
import { Client } from "../../../entities/client.entity";
import { FaUserCog, FaUserEdit, FaPhoneAlt, FaEnvelope, FaHouseUser, FaRegCalendarPlus } from "react-icons/fa";
import { formatPhone } from "../../../helper/phoneFormattor";
import { formatDate } from "../../../helper/dateFormattor";

import "./clientDetail.scss";
import TypeRenderer from "../../shared/TypeRenderer/Type";
import { getDeals } from "../../../services/deals.service";
import { getOrders } from "../../../services/orders.service";
import { Dialog } from 'primereact/dialog';
import { useSelector } from "react-redux";
import "../../shared/DarkMode.scss"
import { DealStatus } from "../../../constants/enum";

interface UpdateProps {
    open: boolean;
    onClose: () => void;
    client?: Client;
}
interface FormData {
    clientStatus: string;
    client: Client | null;
    name: string;
    lastname: string;
    email: string;
    phone: string;
    address: string;
    zipcode: string;
    city: string;
}

export default function ClientDetail({ open, onClose, client }: UpdateProps) {

    const [deals, setDeals] = useState<Deal[]>([]);
    const [orders, setOrders] = useState<Deal[]>([]);
    useEffect(() => {
        (
            async () => {
                await getDeals()
                    .then(response => {
                        setDeals(response.data);
                    })
                    .catch(err => console.error('cannot load dealsPerclient'));

                await getOrders()
                    .then(response => {
                        setOrders(response.data);;
                    })
                    .catch(err => console.error('cannot load dealsPerclient'));
            }
        )();
    }, []);
    const getNumberOfDeals = (id: number) => {
        let nbDeals = 0;
        let dealsPerClient = deals.filter(d => d.clientId?.id === id && d.status === DealStatus.ToSign);
        if (dealsPerClient.length !== 0) {
            dealsPerClient.forEach((deal) => {
                nbDeals += 1;
            });
        }
        return nbDeals;
    };
    const getNumberOfOrders = (id: number) => {
        let nbOrders: number = 0;
        let ordersPerClient = orders.filter(o => o.clientId?.id === id);
        if (ordersPerClient.length !== 0) {
            ordersPerClient.forEach((deal) => {
                nbOrders += 1;
            });
        }
        return nbOrders;
    }
    const isDark = useSelector((state: any) => state.theme.isDark) as boolean;

    return (
        // <Modal
        //     aria-labelledby="modal-title"
        //     aria-describedby="modal-description"
        //     open={open}
        //     onClose={onClose}
        //     style={{ padding: '20px 0px', paddingBottom: '30px' }}
        //     width="45%"
        // >

        <Dialog
            header="Fiche client"
            headerStyle={{
                padding: "15px 24px", borderTopLeftRadius: "18px", borderTopRightRadius: "18px", fontWeight: "500",
                background: isDark ? "#2a323d" : "#fff", color: isDark ? "rgba(255,255,255,.87)" : "black"
            }}
            visible={open} onHide={onClose}
            closable={false}
            closeOnEscape={true}
            dismissableMask={true}
            style={{ width: '40vw', padding: "0px", borderRadius: "18px" }}
            className={isDark ? 'modal-dark' : 'modal-light'}
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            contentStyle={{
                borderBottomLeftRadius: "18px", borderBottomRightRadius: "18px", background: isDark ? "#2a323d" : "#fff",
                color: isDark ? "rgba(255,255,255,.87)" : "#495057"
            }}>
            {/* <Modal.Header css={{ height: "50px", justifyContent: "center" }}>
                <FaUserCog style={{ fontSize: "30px", marginRight: "10px" }} />
                <Text id="modal-title" size={18} b className="header__title" css={{ letterSpacing: '0.2px' }}>
                    Fiche client
                </Text>
            </Modal.Header> */}

            {/* <Modal.Body className="modal-body" css={{ overflow: "hidden", marginTop: "40px" }} > */}

            <div className="row">
                <div className="col-5" style={{ borderRight: "1px solid #9c9c9c78", padding: "50px 0px" }}>
                    <div style={{ marginBottom: "25px", display: "flex", flexDirection: "column" }}>
                        <span style={{ fontSize: "18px", textAlign: "center" }} className="span__name">{client?.firstName} {client?.lastName}</span>
                        <span style={{ textAlign: "center" }}><TypeRenderer text={client?.type ? client.type : ''} /></span>

                    </div>
                    <div className="div__alignInputs">
                        <FaPhoneAlt className="icon__clientInfo"></FaPhoneAlt>
                        <span className="span__clientInfo">{client?.phone ? formatPhone(client?.phone) : ''}</span>
                    </div>
                    <div className="div__alignInputs">
                        <FaEnvelope className="icon__clientInfo"></FaEnvelope>
                        <span className="span__clientInfo">{client?.email}</span>
                    </div>
                    <div className="div__alignInputs">
                        <FaHouseUser className="icon__clientInfo"></FaHouseUser>
                        <span className="span__clientInfo">{client?.address}, {client?.zipCode} {client?.city}</span>
                    </div>
                    <div className="div__alignInputs">
                        <FaRegCalendarPlus className="icon__clientInfo"></FaRegCalendarPlus>
                        <span className="span__clientInfo">{formatDate(client?.creationDate ? client.creationDate.toString() : '')}</span>
                    </div>

                </div>

                <div className="col-7" style={{ padding: "0 20px" }}>
                    <span className="span__name" style={{ float: "left", marginBottom: "6px" }}>Information concession</span>
                    <div className="div__inputClientRelatedInfo">
                        <span className="span__title">Concession</span>
                        <span className="span__text">{client?.partnerId?.partner?.name}</span>
                    </div>
                    <div className="div__inputClientRelatedInfo">
                        <span className="span__title">Commercial</span>
                        <span className="span__text">{client?.partnerId?.firstName} {client?.partnerId?.lastName}</span>
                    </div>
                    <hr style={{ margin: "13px 0", backgroundColor: "black" }} />
                    <span className="span__name" style={{ float: "left", marginBottom: "6px" }}>Affaires</span>
                    <div className="div__inputClientRelatedInfo">
                        <span className="span__title">Affaires en cours</span>
                        <span className="span__text">{getNumberOfDeals(client?.id ? client.id : -1)}</span>
                    </div>
                    {/* <div className="div__inputClientRelatedInfo">
                        <span className="span__title">Plus de détais</span>
                        <span className="span__text">Détails</span>
                    </div> */}
                    <hr style={{ margin: "13px 0", backgroundColor: "black" }} />
                    <span className="span__name" style={{ float: "left", marginBottom: "6px" }}>Commandes</span>
                    <div className="div__inputClientRelatedInfo">
                        <span className="span__title">Commandes en cours</span>
                        <span className="span__text">{getNumberOfOrders(client?.id ? client.id : -1)}</span>
                    </div>
                    {/* <div className="div__inputClientRelatedInfo">
                        <span className="span__title">Plus de détais</span>
                        <span className="span__text">Détails</span>
                    </div> */}
                </div>
            </div>
            {/* Add your modal footer here if needed */}
        </Dialog>
    );
}

