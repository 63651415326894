import React, { useEffect, useState } from "react";
import { Container, Card, Text, Col, Row, Button } from "@nextui-org/react";

import quote from "../../assets/cards-icons/quote.png";
import "./SharedCard.scss";
import { useNavigate } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import { FaUser } from "react-icons/fa";

interface cardProps {
  title: string;
  number?: string;
  icon?: React.ReactNode;
  image?: string;
  width?: string;
  height?: string;
  layout?: "rectangle" | "square";
  action?: string;
  clickable?: boolean;

}

function SharedCard({ title, number, icon, image, width, height, layout, action, clickable }: cardProps) {
  const cardStyle = {
    width: width || "100%",
    height: height || "auto",
  };

  const cardClass = layout === "square" ? "card--square" : "card--rectangle";
  const cardImageClass =
    layout === "square" ? "card__image--square" : "card__image--rectangle";
  const cardContentClass =
    layout === "square" ? "card__content--square" : "card__content--rectangle";
  const cardTitleClass =
    layout === "square" ? "card__title--square" : "card__title--rectangle";

  const clickableCard = clickable !== false ? 'clickable-card' : 'blocked-card';

  const navigate = useNavigate();
  const handleClick = (action: string) => {
    switch (action) {
      case "deal":
        navigate("/deals");
        break;
      case "order":
        navigate("/orders");
        break;
      default:
        break;
    }
  }


  return (

    <Card className={`custom-card ${cardClass} ${clickableCard}`} style={cardStyle}  >

      {(icon || image) && <div className="mb-3" style={{ height: "80px" }}>
        {icon}
        {image &&
          <img src={image} alt="Disjoncteur" style={{ height: "120px" }} className="w-6 shadow-2" />
        }
      </div>}

      {layout === "rectangle" && (
        <div >
          <div className="div__customTextContainer">
            <span className="span__customCardTitle" >{title}</span>
            <span className="span__customCardNumber">{number}</span>
          </div>
        </div>
      )}
      {layout === "square" &&
        <div style={{ display: "flex", justifyContent: "center" }} >
          <span className="mb-1 span__customCardTitle" >{title}</span>
        </div>
      }

    </Card>
  );
}

export default SharedCard;
