import axios from "axios";
import { Quote } from "../entities/quote.entity";

const API_URL = process.env.REACT_APP_API_URL;

export function createSignatureRequest(quote: Quote) {
    return axios.post(API_URL + 'sign/create', quote, { withCredentials: true });
}

export function sendSignatureRequest(signatureRequestId: string) {
    return axios.post(API_URL + `sign/send/${signatureRequestId}`, null, { withCredentials: true });
}

export function downloadSignedDocument(signatureRequestId: string) {
    return axios.get(API_URL + `sign/${signatureRequestId}`, { withCredentials: true, responseType: 'blob' });
}