function Professional() {
  return (
    <div>
      <h1>Professionnel</h1>
      <p style={{ fontSize: "20px" }}>
      Under construction
      </p>
    </div>
  )
}

export default Professional;
