import { InputText } from "primereact/inputtext";
import CustomButton from "../../../shared/Buttons/customButton";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Client } from "../../../../entities/client.entity";
import { formatPhone } from "../../../../helper/phoneFormattor";
import { updateClientService } from "../../../../services/clients.service";
import { QuoteConstants as quoteEnum } from '../../../../constants/enum';

interface UpdateClientFormProps {
    client?: Client;
    submit: (data: string) => void;
    cancel: () => void;
}

interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address: string;
    zipCode: string;
    city: string;
    siretCode?: string;
    companyName?: string;
}
interface ModifiedFields {
    lastName?: boolean;
    firstName?: boolean;
    email?: boolean;
    phone?: boolean;
    address?: boolean;
    zipCode?: boolean;
    city?: boolean;
    siretCode?: string;
    companyName?: string;
}

function UpdateClientForm({ client, submit, cancel }: UpdateClientFormProps) {
    const [modifiedFields, setModifiedFields] = useState<ModifiedFields>({});

    const formik = useFormik<FormData>({
        initialValues: {
            firstName: client?.firstName ? client.firstName : '',
            lastName: client?.lastName ? client.lastName : '',
            email: client?.email ? client.email : '',
            phone: client?.phone ? formatPhone(client.phone) : '',
            address: client?.address ? client.address : '',
            zipCode: client?.zipCode ? client.zipCode : '',
            city: client?.city ? client.city : '',
            siretCode: client?.siretCode ? client.siretCode : '',
            companyName: client?.companyName ? client.companyName : '',
        },
        validate: (data) => {
            const errors: Partial<FormData> = {};

            if (!data.firstName.trim()) {
                errors.firstName = 'Veuillez saisir un prénom.';
            }

            if (!data.lastName.trim()) {
                errors.lastName = 'Veuillez saisir un nom.';
            }
            if (!data.email.trim()) {
                errors.email = 'Veuillez saisir un email.';
            }

            if (client?.type === quoteEnum.professional) {
                if (!data.siretCode?.trim()) {
                    errors.siretCode = 'Veuillez saisir un n° Siret';
                } else if (!isValidSiret(data.siretCode)) {
                    errors.siretCode = 'Veuillez saisir un n° Siret valide';
                }

                if (!data.companyName?.trim()) {
                    errors.companyName = "Veuillez saisir le nom de l'entreprise.";
                }
            }

            return errors;
        },
        onSubmit: async (data) => {
            const updatedClient = { ...client };
            Object.keys(data).forEach((fieldName: any) => {
                if (updatedClient.hasOwnProperty(fieldName)) {
                    updatedClient[fieldName as keyof FormData] = data[fieldName as keyof FormData];
                }
            });

            try {
                await updateClientService(updatedClient as Client);
                submit("successful update");

            } catch (err) {
                submit("Failed update");
            }

            formik.resetForm();
        },
    });

    const isValidSiret = (siret: string) => {
        const siretRegex = /^[0-9]{14}$/;
        return siretRegex.test(siret);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        formik.handleChange(e);

        setModifiedFields((prevModifiedFields) => ({
            ...prevModifiedFields,
            [name as keyof FormData]: value !== formik.initialValues[name as keyof FormData],
        }));
    };

    const submitForm = () => {
        formik.submitForm();
    };

    const cancelForm = () => {
        formik.resetForm();
        cancel();
    };

    return (
        <div>
            <span className="span__section">Informations personnelles</span>
            <div className="container__inputs">
                <div className="div__input">
                    <div>
                        <span className="p-input-icon-left" style={{ width: "100%" }}>
                            <i className="pi pi-user" />
                            <InputText
                                id="lastName"
                                name="lastName"
                                placeholder="Nom"
                                value={formik.values.lastName}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                                className={formik.touched.lastName && formik.errors.lastName ? 'p-invalid' : ''}
                                style={{ width: "100%" }}
                            />
                        </span>
                    </div>
                    {modifiedFields.lastName && !formik.errors.lastName && (
                        <div className="div__modification">
                            <FaCheck className="icon__check"></FaCheck>
                            <small className="span__modif">Modifié</small>
                        </div>
                    )}
                    {formik.touched.lastName && formik.errors.lastName && (
                        <div>
                            <small className="p-error small__error">{formik.errors.lastName}</small>
                        </div>
                    )}
                </div>


                <div className="div__input">
                    <div>
                        <span className="p-input-icon-left" style={{ width: "100%" }}>
                            <i className="pi pi-user" />
                            <InputText
                                id="firstName"
                                name="firstName"
                                placeholder="Prénom"
                                value={formik.values.firstName}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                                className={formik.touched.firstName && formik.errors.firstName ? 'p-invalid' : ''}
                                style={{ width: "100%" }}
                            />
                        </span>
                    </div>
                    {modifiedFields.firstName && !formik.errors.firstName && (
                        <div className="div__modification">
                            <FaCheck className="icon__check"></FaCheck>
                            <small className="span__modif">Modifié</small>
                        </div>
                    )}
                    {formik.touched.firstName && formik.errors.firstName && (
                        <small className="p-error  small__error">{formik.errors.firstName}</small>
                    )}
                </div>
            </div>
            {client?.type === quoteEnum.professional &&
                <>
                    <div className="test">
                        <div>
                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                <i className="pi pi-info-circle" />
                                <InputText
                                    id="siretCode"
                                    name="siretCode"
                                    placeholder="N° Siret"
                                    maxLength={14}
                                    value={formik.values.siretCode}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={formik.touched.siretCode && formik.errors.siretCode ? 'p-invalid' : ''}
                                    style={{ width: "100%" }}
                                />
                            </span>
                        </div>
                        <div>
                        </div>
                        {formik.touched.siretCode && formik.errors.siretCode && (
                            <small className="p-error  error_test">{formik.errors.siretCode}</small>
                        )}
                    </div>

                    <div className="test">
                        <div>
                            <span className="p-input-icon-left" style={{ width: "100%" }}>
                                <i className="pi pi-info-circle" />
                                <InputText
                                    id="companyName"
                                    name="companyName"
                                    placeholder="Nom de l'entreprise"
                                    value={formik.values.companyName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={formik.touched.companyName && formik.errors.companyName ? 'p-invalid' : ''}
                                    style={{ width: "100%" }}
                                />
                            </span>
                        </div>
                        <div>
                        </div>
                        {formik.touched.companyName && formik.errors.companyName && (
                            <small className="p-error  error_test">{formik.errors.companyName}</small>
                        )}
                    </div>
                </>
            }
            <div className="div__input">
                <div>
                    <span className="p-input-icon-left" style={{ width: "100%" }}>
                        <i className="pi pi-phone" />
                        <InputText
                            id="phone"
                            name="phone"
                            placeholder="Numéro de téléphone"
                            value={formik.values.phone}
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                            className={formik.touched.phone && formik.errors.phone ? 'p-invalid' : ''}
                            style={{ width: "100%" }}
                        />
                    </span>
                </div>
                {modifiedFields.phone && !formik.errors.phone && (
                    <div className="div__modification">
                        <FaCheck className="icon__check"></FaCheck>
                        <small className="span__modif">Modifié</small>
                    </div>
                )}
                {formik.touched.phone && formik.errors.phone && (
                    <small className="p-error small__error">{formik.errors.phone}</small>
                )}
            </div>
            <div className="div__input">
                <div>
                    <span className="p-input-icon-left" style={{ width: "100%" }}>
                        <i className="pi pi-envelope" />
                        <InputText
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={formik.values.email}
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                            className={formik.touched.email && formik.errors.email ? 'p-invalid' : ''}
                            style={{ width: "100%" }}
                        />
                    </span>
                </div>
                <div>
                </div>
                {modifiedFields.email && !formik.errors.email && (
                    <div className="div__modification">
                        <FaCheck className="icon__check"></FaCheck>
                        <small className="span__modif">Modifié</small>
                    </div>
                )}
                {formik.touched.email && formik.errors.email && (
                    <small className="p-error  error_test">{formik.errors.email}</small>
                )}
            </div>
            <div className="div__input">
                <span className="span__section" style={{ marginTop: "10px" }}>Adresse de facturation</span>
                <div>
                    <span className="p-input-icon-left" style={{ width: "100%" }}>
                        <i className="pi pi-home" />
                        <InputText
                            id="address"
                            name="address"
                            placeholder="Adresse"
                            value={formik.values.address}
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                            className={formik.touched.address && formik.errors.address ? 'p-invalid' : ''}
                            style={{ width: "100%" }}
                        />
                    </span>
                </div>
                {modifiedFields.address && !formik.errors.address && (
                    <div className="div__modification">
                        <FaCheck className="icon__check"></FaCheck>
                        <small className="span__modif">Modifié</small>
                    </div>
                )}
                {formik.touched.address && formik.errors.address && (
                    <small className="p-error small__error">{formik.errors.address}</small>
                )}
            </div>
            <div className="div__input">
                <div className="container__inputs">
                    <div className="div__input">
                        <span className="p-input-icon-left" style={{ width: "100%" }}>
                            <i className="pi pi-map" />
                            <InputText
                                id="zipCode"
                                name="zipCode"
                                placeholder="Code postal"
                                value={formik.values.zipCode}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                                className={formik.touched.zipCode && formik.errors.zipCode ? 'p-invalid' : ''}
                                style={{ width: "100%" }}
                            />
                        </span>
                        {modifiedFields.zipCode && !formik.errors.zipCode && (
                            <div className="div__modification">
                                <FaCheck className="icon__check"></FaCheck>
                                <small className="span__modif">Modifié</small>
                            </div>
                        )}
                        {formik.touched.zipCode && formik.errors.zipCode && (
                            <small className="p-error small__error">{formik.errors.zipCode}</small>
                        )}
                    </div>
                    <div className="div__input">
                        <span className="p-input-icon-left" style={{ width: "100%" }}>
                            <i className="pi pi-map" />
                            <InputText
                                id="city"
                                name="city"
                                placeholder="Ville"
                                value={formik.values.city}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                                className={formik.touched.city && formik.errors.city ? 'p-invalid' : ''}
                                style={{ width: "100%" }}
                            />
                        </span>
                        {modifiedFields.city && !formik.errors.city && (
                            <div className="div__modification">
                                <FaCheck className="icon__check"></FaCheck>
                                <small className="span__modif">Modifié</small>
                            </div>
                        )}
                        {formik.touched.city && formik.errors.city && (
                            <small className="p-error small__error">{formik.errors.city}</small>
                        )}
                    </div>
                </div>
            </div>

            <div className="button__modify">
                <div onClick={cancelForm}>
                    <CustomButton title="Annuler" />
                </div>
                <div onClick={submitForm}>
                    <CustomButton title="Valider" />
                </div>
            </div>
        </div>
    )
}

export default UpdateClientForm;