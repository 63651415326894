import axios from 'axios';
import { LoginDto, RegisterDto, RegisterV2Dto } from '../dtos/auth.dto';

const API_URL = process.env.REACT_APP_API_URL;
const test = process.env;

export function register({ email, password }: RegisterDto) {
    return axios.post(API_URL + 'auth/register', {
        email,
        password,
    });
};

export function registerv2(registerDto: RegisterV2Dto) {
    return axios.post(API_URL + 'auth/registerv2', registerDto);
};

export function changePassword({ email, password, newPassword }: any) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return axios.post(API_URL + 'auth/change-password', {
        email,
        password,
        newPassword
    }, { withCredentials: true, headers: customHeaders });
}

export function resetPassword(email: string) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };
    return axios.post(API_URL + 'auth/reset-password', {
        email: email
    }, { headers: customHeaders });
}

export async function login({ email, password }: LoginDto) {
    const customHeaders = {
        'Content-Type': 'application/json',
    };

    const response = await axios
        .post(API_URL + 'auth/login',
            {
                email,
                password,
            },
            { withCredentials: true, headers: customHeaders }
        );
    if (response.data.username) {
        localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
};

export async function profile() {
    return await axios.get(API_URL + 'auth/profile', { withCredentials: true });
}

export async function logout() {
    localStorage.removeItem("user");
    return axios.post(API_URL + "auth/logout", null, { withCredentials: true }).then((response) => {
        return response.data;
    });
};