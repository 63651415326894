import { DealStatus, OrderStatus } from "../constants/enum";

export function mapStatus(technicalStatus: string, page?: string): string {
    switch (technicalStatus) {
        case DealStatus.ToEvaluate:
            return "A évaluer";
        case DealStatus.VisitToDo:
            return "VT à réaliser";
        case DealStatus.ToAmend:
            return "A modifier";
        case DealStatus.Unachievable:
            return "Non réalisable";
        case DealStatus.ToSign:
            if (page === "Installateur") {
                return "Signature client";
            }
            else {
                return "A signer";
            }
        case DealStatus.Won:
            return "Gagnée";
        case DealStatus.Lost:
            return "Perdu";
        case DealStatus.ToDraft:
            return "A rédiger";
        case DealStatus.Unreachable:
            return "Client injoignable";
        case OrderStatus.ToPlan:
            if (page === "Installateur") {
                return "Planification en cours"
            }
            else {
                return "A planifier";
            }
        case OrderStatus.Planned:
            return "Planifié";
        case OrderStatus.Installed:
            return "Installé";
        case OrderStatus.Paid:
            return "Payé";
        default:
            return ""
    }
}
