import link from "../assets/products/cables/link.jpg";
import cable from "../assets/products/cables/cable.jpg";
import beOn from "../assets/products/cables/beOn.jpg";
import chargeur from "../assets/products/cables/chargeur.jpg";
import pied from "../assets/products/cables/pied.jpg";
import connected from "../assets/products/cables/connected.jpg";
import wallbox from "../assets/products/cables/wallbox.jpg"

export function getImage(image: string) {
    switch (image) {
        case "E Home":
            return link;
        case "Witty Start":
        case "Witty Share":
            return connected;
        case "Wallbox Pulsar":
        case "WALLBOX Power Boost monophasé":
        case "WALLBOX Power boost triphasé":
            return wallbox;
        case "Home BeOn":
            return beOn;
        case "Type 2 - 22 kw - 7m":
        case "Type 2 - 7,4kw - 7m":
            return cable;
        case "T2 - 3.6 kw - 7.5m":
            return chargeur;
        case "Pieds de montage":
            return pied;
        default:
            return "";
    }
}
