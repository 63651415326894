import { Deal } from "../../../../entities/deal.entity";
import { ProductPerDealDto } from "../../../../dtos/product-per-deal.dto";
import "./../validateDeal.scss"

interface ValidateDealModalProps {
    deal: Deal;
    station?: ProductPerDealDto;
    accessories?: ProductPerDealDto[];
}

function ValidateDealDetails({ deal, station, accessories }: ValidateDealModalProps) {

    return (
        <div className="row">
            <span className="span__categorie">Produits</span>
            <div className="row div__customList" >
                <ul className="span__Title" style={{ listStyleType: 'circle', paddingLeft: "20px", marginBottom: "0" }}>
                    <li style={{ marginBottom: "0" }}>
                        <div className="row" style={{ marginLeft: "5px" }}>
                            <span className="span__Title col-4" >{station?.product.type} </span>
                            <span className="col-8 span__Info">{station?.product.brand} - {station?.product.name}</span>
                        </div>
                    </li>
                    <li>
                        <div className="row" style={{ marginLeft: "5px" }}>
                            <span className="span__Title col-4" >Adresse d'installation </span>
                            <span className="col-8 span__Info">{deal.installationAddress}, {deal.installationZipCode} {deal.installationCity}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <span className="span__categorie">Accessoriess</span>
            {accessories?.map((acc, index) => {
                return (
                    <div className="row div__customList" key={index}>
                        <ul className="span__Title" style={{ listStyleType: 'circle', paddingLeft: "20px", marginBottom: "0" }}>
                            <li style={{ marginBottom: "0" }}>
                                <div className="row" style={{ marginLeft: "5px" }}>
                                    <span className="col-4 span__Title " >{acc.product.type} </span>
                                    <span className="col-8 span__Info">{acc.product.brand} - {acc.product.name}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                )
            })
            }
        </div>

    )
}

export default ValidateDealDetails;