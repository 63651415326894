import { CircuitBreaker } from "../constants/enum";
import { InstallationPerDealDto } from "../dtos/installation-per-deal.dto";
import { Deal } from "../entities/deal.entity";
import { Quote } from "../entities/quote.entity";
import { getInstallationsPerDealByDealId, getProductsPerDealByDealId } from "../services/deals.service";

export async function getQuote(deal: Deal): Promise<Quote> {
    let resIpd = await getInstallationsPerDealByDealId(deal?.id!);
    if (resIpd.data.length) {
        let mainInstallation = resIpd.data.find((ipd: InstallationPerDealDto) => ipd.installationDetail.maxInstallationDistance !== null && ipd.installationDetail.minInstallationDistance !== null).installationDetail;
        var totalDistance = mainInstallation.maxInstallationDistance;
        var cbType = mainInstallation.circuitBreaker;
        if (resIpd.data.length > 1) {
            let extraInstallation = resIpd.data.find((ipd: InstallationPerDealDto) => ipd.installationDetail.maxInstallationDistance === null && ipd.installationDetail.minInstallationDistance);
            totalDistance += extraInstallation.quantity;
        }
    }

    let quote: Quote = {
        typeOfPerson: deal?.clientId?.type!,
        housing: '',
        circuitBreaker: cbType!,
        distance: totalDistance! + 'm',
        client: deal?.clientId,
        dealId: deal?.id!,
        deal: deal
    };

    return quote;
}