import { combineReducers } from 'redux';
import { distanceReducer, stepReducer } from './StepsReducer';
import LoginReducer from './LoginReducer';
import { ThemeReducer } from './ThemeReducer';


const rootReducer = combineReducers({
  steps: stepReducer,
  distance: distanceReducer,
  login: LoginReducer,
  theme: ThemeReducer
});

export default rootReducer;