import { useEffect, useState } from "react";
import { Modal } from "@nextui-org/react";
import QuoteAnimation from "../animations/QuoteAnimation";
import { useDispatch, useSelector } from "react-redux";
import { previousStep, resetFields } from "../../redux/actions/StepsActions";
import { FiArrowLeftCircle, FiXCircle } from "react-icons/fi";
import { Col, Container, Row } from "react-bootstrap";
import { Dialog } from 'primereact/dialog';
import Step1 from "./Steps/Step1/Step1";

import Step2ForIndiv from "./Steps/Step2/Step2ForIndiv/Step2ForIndiv";
import Step2ForProf from "./Steps/Step2/Step2ForProf/Step2ForProf";

import Step3TypeOfCircuitBreaker from "./Steps/Step3/Step3TypeOfCircuitBreaker";
import Step3ForAppartment from "./Steps/Step3/Step3ForIndiv/Step3ForAppartment";
import Step4Map from "./Steps/Step4/Step4Map";
import Step5 from "./Steps/Step5-QuoteResult/Step5";
import CreateClient from "./Steps/CreateClient/CreateClient";

import "./Quote.scss";
import { QuoteConstants, StationType } from "../../constants/enum";
import Step6 from "./Steps/Step6.tsx/Step6";
import Step3NumberOfStation from "./Steps/Step3/Step3ForPro/Step3NumberOfStation";
import Step4ClientComment from "./Steps/Step4/Step4Pro/Step4ClientComment";
import Step5Connected from "./Steps/Step5.2/Step5Connected";
import Step5Disconnected from "./Steps/Step5.2/Step5Disconnected";

interface QuoteProps {
  open: boolean;
  onClose: () => void;
  onQuoteData: (data: string) => void;
}
interface CustomHeaderProps {
  isDark: boolean;
  handlePreviousStep: () => void;
  closeModal: () => void;
  step: number; // or the appropriate type for your use case
}

function Quote({ open, onClose, onQuoteData }: QuoteProps) {
  const isDark = useSelector((state: any) => state.theme.isDark) as boolean;
  const [showContent, setShowContent] = useState(true);
  const selectedChoiceOnStep1 = useSelector((state: any) => state.steps.selectedCards.step1);
  const selectedChoiceOnStep2 = useSelector((state: any) => state.steps.selectedCards.step2);
  const selectedChoiceOnStep3 = useSelector((state: any) => state.steps.selectedCards.step3);
  const selectedChoiceOnStep4 = useSelector((state: any) => state.steps.selectedCards.step4);
  const selectedChoiceOnStep5 = useSelector((state: any) => state.steps.selectedCards.step5);
  const [quoteData, setQuoteData] = useState('');

  const dispatch = useDispatch();

  const sendDataToParent = () => {
    onQuoteData(quoteData);
  };
  const sendToHomePage = (data: any) => { onQuoteData(data); }
  const closeModal = () => {
    dispatch(resetFields());
    onClose();
  };

  const handlePreviousStep = () => {
    dispatch(previousStep());
  };

  const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);

  useEffect(() => {
    const handleResize = () => {
      setIsXSmallScreen(window.innerWidth < 650);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const widthClass = isXSmallScreen ? "100%" : "750px";
  const fullScreen = isXSmallScreen ? true : false;

  const step = useSelector((state: any) => state.steps.step);
  const CustomHeader = ({ isDark, handlePreviousStep, closeModal, step }: CustomHeaderProps) => (
    <Container>
      <Row>
        <Col span={1}>
          {step !== 1 && (
            <FiArrowLeftCircle
              style={{ float: "left", color: isDark ? "#c8c8c8" : "rgb(73, 80, 87)", cursor: 'pointer' }}
              size={35}
              className="button"
              onClick={handlePreviousStep}
            />
          )}
        </Col>
        <Col span={10}></Col>
        <Col span={1}>
          <FiXCircle
            style={{ float: "right", color: isDark ? '#f19ea6' : '#EF4444', cursor: 'pointer' }}
            size={35}
            className="button__close"
            onClick={closeModal}
          ></FiXCircle>
        </Col>
      </Row>
    </Container>
  );
  return (
    <Dialog
      header={<CustomHeader isDark={isDark} handlePreviousStep={handlePreviousStep} closeModal={closeModal} step={step} />}
      headerStyle={{
        padding: "14px 24px", borderTopLeftRadius: "18px", borderTopRightRadius: "18px", fontWeight: "500",
        background: isDark ? "#2a323d" : "#fff", color: isDark ? "rgba(255,255,255,.87)" : "black"
      }}
      visible={open} onHide={onClose}
      closable={false}
      closeOnEscape={true}
      dismissableMask={true}
      style={{ width: '60vw', padding: "0px", borderRadius: "18px" }}
      breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      contentStyle={{
        paddingBottom: '15px', borderBottomLeftRadius: "18px", borderBottomRightRadius: "18px", background: isDark ? "#2a323d" : "#fff",
        color: isDark ? "rgba(255,255,255,.87)" : "#495057"
      }}
      className={isDark ? 'modal-dark' : 'modal-light'}>
      <div className="modal-body">

        {step === 1 && (
          <QuoteAnimation trigger={showContent}>
            <Step1 />
          </QuoteAnimation>)}

        {step === 2 && selectedChoiceOnStep1 === QuoteConstants.individual && (
          <QuoteAnimation trigger={showContent}>
            <Step2ForIndiv />
          </QuoteAnimation>)}
        {step === 2 && selectedChoiceOnStep1 === QuoteConstants.professional && (
          <QuoteAnimation trigger={showContent}>
            <Step2ForProf />
          </QuoteAnimation>)}

        {step === 3 && (selectedChoiceOnStep2 === QuoteConstants.house || selectedChoiceOnStep2 === QuoteConstants.houseOfAPro) && (
          <QuoteAnimation trigger={showContent}>
            <Step3TypeOfCircuitBreaker />
          </QuoteAnimation>)}
        {step === 3 && (selectedChoiceOnStep2 === QuoteConstants.publicParking || selectedChoiceOnStep2 === QuoteConstants.privateParking || selectedChoiceOnStep2 === QuoteConstants.sharedParking) && (
          <QuoteAnimation trigger={showContent}>
            <Step3NumberOfStation />
          </QuoteAnimation>)}
        {step === 3 && selectedChoiceOnStep2 === QuoteConstants.appartment && (
          <QuoteAnimation trigger={showContent}>
            <Step3ForAppartment />
          </QuoteAnimation>)}


        {step === 4 && (selectedChoiceOnStep3 === QuoteConstants.lessThanFive || selectedChoiceOnStep3 === QuoteConstants.lessThanTen || selectedChoiceOnStep3 === QuoteConstants.moreThanTen)
          && (<QuoteAnimation trigger={showContent}>
            <Step4ClientComment />
          </QuoteAnimation>)}

        {step === 4 && (selectedChoiceOnStep3 === QuoteConstants.no || selectedChoiceOnStep3 === QuoteConstants.yes)
          && (<QuoteAnimation trigger={showContent}>
            <div className="span__blocked">
              <span style={{ fontSize: "17px", fontWeight: "500" }}>EMS ne propose pas encore cette installation, Nous travaillons actuellement cette possibilité.</span>
            </div>
          </QuoteAnimation>)}

        {step === 4 &&
          ((selectedChoiceOnStep1 === QuoteConstants.individual && (selectedChoiceOnStep3 !== QuoteConstants.no && selectedChoiceOnStep3 !== QuoteConstants.yes)) ||
            (selectedChoiceOnStep1 === QuoteConstants.professional && selectedChoiceOnStep2 === QuoteConstants.houseOfAPro))
          && (<QuoteAnimation trigger={showContent}>
            <Step4Map></Step4Map>
          </QuoteAnimation>
          )}


        {step === 5 && ((selectedChoiceOnStep1 === QuoteConstants.professional && selectedChoiceOnStep2 === QuoteConstants.houseOfAPro) || selectedChoiceOnStep1 !== QuoteConstants.professional) && (
          <QuoteAnimation trigger={showContent}>
            <Step5></Step5>
          </QuoteAnimation>)}

        {step === 5 && (selectedChoiceOnStep1 === QuoteConstants.professional && selectedChoiceOnStep2 !== QuoteConstants.houseOfAPro) && (
          <QuoteAnimation trigger={showContent}>
            <CreateClient onClose={closeModal} handleSave={sendToHomePage}></CreateClient>
          </QuoteAnimation>)}

        {step === 6 && ((selectedChoiceOnStep1 === QuoteConstants.professional && selectedChoiceOnStep2 === QuoteConstants.houseOfAPro) || selectedChoiceOnStep1 !== QuoteConstants.professional) && selectedChoiceOnStep5 === StationType.Connected && (
          <QuoteAnimation trigger={showContent}>
            <Step5Connected></Step5Connected>
          </QuoteAnimation>)}

        {step === 6 && ((selectedChoiceOnStep1 === QuoteConstants.professional && selectedChoiceOnStep2 === QuoteConstants.houseOfAPro) || selectedChoiceOnStep1 !== QuoteConstants.professional) && selectedChoiceOnStep5 === StationType.Disconnected && (
          <QuoteAnimation trigger={showContent}>
            <Step5Disconnected></Step5Disconnected>
          </QuoteAnimation>)}

        {step === 7 && (
          <QuoteAnimation trigger={showContent}>
            <CreateClient onClose={closeModal} handleSave={sendToHomePage}></CreateClient>
          </QuoteAnimation>)}

      </div>
    </Dialog >
  );
}

export default Quote;

