import { Button, Modal, Text } from "@nextui-org/react";
import { Toast } from "primereact/toast";
import { BaseSyntheticEvent, useRef, useState } from "react";
import { changePassword } from "../../../services/auth.service";
import { Password } from "primereact/password";
import './ChangePassword.scss';

interface ChangePasswordProps {
    email?: string;
    open: boolean;
    onClose: (reason?: string) => void;
}

interface Form {
    password: string;
    newPassword: string;
    confirmNewPassword: string;
}

function ChangePassword({ email, open, onClose }: ChangePasswordProps) {
    const toast = useRef<Toast | null>(null);
    const [input, setInput] = useState<Form>({
        password: '',
        newPassword: '',
        confirmNewPassword: ''
    });

    const [error, setError] = useState<Form>({
        password: '',
        newPassword: '',
        confirmNewPassword: ''
    });

    const [progress, setProgress] = useState("0%");
    const [message, setMessage] = useState("");
    const [strengthChecks, setStrengthChecks] = useState({
        length: false,
        hasUpperCase: false,
        hasLowerCase: false,
        hasDigit: false,
        hasSpecialChar: false,
    });

    function onInputChange(event: any) {
        const { name, value } = event.target;

        if (name === 'newPassword') {
            strengthChecks.length = value.length >= 8 ? true : false;
            strengthChecks.hasUpperCase = /[A-Z]+/.test(value);
            strengthChecks.hasLowerCase = /[a-z]+/.test(value);
            strengthChecks.hasDigit = /[0-9]+/.test(value);
            strengthChecks.hasSpecialChar = /(?=.*?[#?!@$%^&*-])+/.test(value);

            let verifiedList = Object.values(strengthChecks).filter((value) => value);

            let strength =
                verifiedList.length == 5
                    ? "Strong"
                    : verifiedList.length >= 2
                        ? "Medium"
                        : "Weak";

            setProgress(`${(verifiedList.length / 5) * 100}%`);
            setMessage(strength);
        }

        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(event);
    }

    function getActiveColor(type: string): string {
        if (type === "Strong") return "#8BC926";
        if (type === "Medium") return "#FEBD01";
        return "#FF0054";
    };

    function validateInput(event: BaseSyntheticEvent) {
        let { name, value } = event.target;
        setError(prev => {
            const stateObj: Form = { ...prev, [name]: '' };
            switch (name) {
                case 'password':
                    if (!value) {
                        stateObj[name as keyof Form] = "Veuillez entrer votre mot de passe.";
                    }
                    break;
                case 'newPassword':
                    if (!value) {
                        stateObj[name as keyof Form] = "Veuillez entrer votre nouveau mot de passe.";
                    }
                    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(value)) {
                        stateObj[name as keyof Form] = "Le mot de passe doit contenir: au moins de huit caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial";
                    }
                    break;
                case 'confirmNewPassword':
                    if (!value) {
                        stateObj[name as keyof Form] = "Veuillez confirmer votre nouveau mot de passe.";
                    }
                    if (value !== input.newPassword) {
                        stateObj[name as keyof Form] = "Les mots de passe entrés sont différents";
                    }
                    break;

                default:
                    break;
            }
            return stateObj;
        });
    }



    function canSubmit(): boolean {
        let res = input.password != ''
            && input.newPassword != ''
            && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(input.newPassword)
            && input.confirmNewPassword != ''
            && (input.newPassword === input.confirmNewPassword);
        return res;
    }

    const submit = () => {
        let password = input.password;
        let newPassword = input.newPassword;
        changePassword({ email, password, newPassword })
            .then(res => onClose('Success'))
            .catch(error => {
                if (toast.current) {
                    toast.current.show({ severity: 'error', summary: 'Mot de passe incorrect', life: 6000 });
                }
            })

    }

    function handleModalClose() {
        onClose();
    }

    return (
        <Modal
            closeButton
            blur
            aria-labelledby="modal-title"
            width="450px"
            open={open}
            onClose={handleModalClose}
            className="modal__container"
            fullScreen={false}
        >
            <Modal.Header>
                <Text b id="modal-title" size={18}>
                    Modifiez votre mot de passe
                </Text>
            </Modal.Header>
            <Modal.Body>
                <div className="input-container">
                    <Password
                        name='password'
                        placeholder="Ancien mot de passe"
                        onChange={onInputChange}
                        onBlur={validateInput}
                        aria-labelledby="oldpwd"
                        style={{ width: '100%' }}
                        toggleMask
                    />
                </div>
                {error.password && <span className='err'>{error.password}</span>}
                <div className="input-container">
                    <div className="input-box">
                        <Password
                            name='newPassword'
                            placeholder="Nouveau mot de passe"
                            onChange={onInputChange}
                            onBlur={validateInput}
                            aria-labelledby="newpwd"
                            style={{ width: '100%' }}
                            toggleMask
                        />
                    </div>
                    <div className="strength-bar">
                        <div
                            className="progress"
                            style={{
                                width: progress,
                                height: '100%',
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                backgroundColor: getActiveColor(message),
                            }}
                        ></div>
                    </div>
                </div>
                {error.newPassword && <span className='err'>{error.newPassword}</span>}
                <div className="input-container">
                    <Password
                        name='confirmNewPassword'
                        placeholder="Confirmer le mot de passe"
                        onChange={onInputChange}
                        onBlur={validateInput}
                        aria-labelledby="cfnewpwd"
                        style={{ width: '100%' }}
                        toggleMask
                    />
                </div>
                {error.confirmNewPassword && <span className='err'>{error.confirmNewPassword}</span>}
            </Modal.Body>
            <Modal.Footer className="footer__div">
                <Button auto onPress={handleModalClose} className="button__cancel">
                    Annuler
                </Button>
                <Button auto onPress={submit} disabled={!canSubmit()}>
                    Confirmer
                </Button>
            </Modal.Footer>
            <Toast ref={toast} position="top-right" />
        </Modal >
    );
}

export default ChangePassword;