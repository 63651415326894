import { Modal, Text } from "@nextui-org/react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import CustomButton from "../../shared/Buttons/customButton";
import './ResetPassword.scss';
import { resetPassword } from "../../../services/auth.service";
import { AxiosError } from "axios";

interface ResetPasswordProps {
    open: boolean;
    onClose: (success?: boolean, message?: string) => void;
}

function ResetPassword({ open, onClose }: ResetPasswordProps) {
    const [email, setEmail] = useState('');

    function handleModalClose() {
        onClose();
    }

    function submit() {
        resetPassword(email)
            .then(_res => {
                onClose(true, 'Email de réinitialisation envoyé');
            })
            .catch((error: AxiosError) => {
                if (error.response?.status == 404) {
                    onClose(false, 'Email de réinitialisation envoyé');
                } else {
                    onClose(false, 'Une erreur est survenue');
                }
            });
    }

    return (
        <Modal
            closeButton
            blur
            aria-labelledby="modal-title"
            width="450px"
            open={open}
            onClose={handleModalClose}
            className="modal__container"
            fullScreen={false}
        >
            <Modal.Header>
                <Text b id="modal-title" size={24}>
                    Mot de passe oublié
                </Text>
            </Modal.Header>
            <Modal.Body>
                <Text>Nous vous enverrons les instructions de réinitialisation de votre mot de passe par e-mail.</Text>
                <InputText value={email} onChange={(e) => setEmail(e.target.value)} />
                <div className="div__reset-button" onClick={submit}>
                    <CustomButton title="Envoyez-moi un e-mail" />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ResetPassword;