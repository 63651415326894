import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { Logger } from "sass";
import { User } from "../../../entities/user.entity";
import Logo from "../../navbar/Logo";
import AccessDenied from "../access-denied/AccessDenied";

interface AuthProps {
    allowedRoles: string[];
}

function Auth({ allowedRoles }: AuthProps) {
    const loggedUser = useSelector((state: any) => state.login.loggedUser) as User;
    return loggedUser && allowedRoles.find((role) => loggedUser.role == role) ? (
        <Outlet />
    ) : <AccessDenied />
}

export default Auth;