import { useSelector } from "react-redux";
import "./SiretRenderer.scss";
import { useState } from "react";

interface badgeProps {
    text: string;
}

function SiretRenderer({ text }: badgeProps) {
    const isDark = useSelector((state: any) => state.theme.isDark);

    function getTypeClass(text: string) {
        if (text !== null) {
            return !isDark ? 'siret-professional' : 'siret-professional-dark';
        }
    }

    return (
        <div >
            <span className={getTypeClass(text)}>{text}</span>
        </div>
    );
}

export default SiretRenderer;
