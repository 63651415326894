export function getRole(technicalRole?: string) {
    switch (technicalRole) {
        case 'Commercial':
            return 'Commercial';
        case 'Direction':
            return 'Manager';
        case 'LSAdmin':
            return 'Administrateur LS';
        case 'EMSAdmin':
            return 'Administrateur EMS';
        case 'Installer':
            return 'Installateur';
        case 'InstallerManager':
            return 'Administrateur installateur';
    }
}