import { Col, Container, Row } from "react-bootstrap";
import { Badge, Card, Text } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { FiChevronsRight, FiMail, FiMapPin, FiPhoneCall, FiUser } from "react-icons/fi";
import { FaPiggyBank } from "react-icons/fa";
import './ClientCard.scss';
import { Client } from "../../entities/client.entity";
import { getClients } from "../../services/clients.service";
import { Deal } from "../../entities/deal.entity";
import { Order } from "../../entities/order.entity";
import { formatDate } from "../../helper/dateFormattor";
import { getDealsByClientId } from "../../services/deals.service";
import { getOrdersByClientId } from "../../services/orders.service";
import TypeRenderer from "../shared/TypeRenderer/Type";
import { DealStatus } from "../../constants/enum";

interface ClientCardProps {
    client: Client;
}

function ClientCard({ client }: ClientCardProps) {
    const [clients, setClients] = useState<Client[]>([]);
    const [dealsPerClient, setDealsPerClient] = useState<Deal[]>([]);
    const [ordersPerClient, setOrdersPerClient] = useState<Order[]>([]);

    useEffect(() => {
        (
            async () => {
                await getDealsByClientId(client.id as number)
                    .then(response => {
                        setDealsPerClient(response.data);;
                    })
                    .catch(err => console.error('cannot load dealsPerclient'));

                await getOrdersByClientId(client.id as number)
                    .then(response => {
                        setOrdersPerClient(response.data);
                    })
                    .catch(err => console.error('cannot load dealsPerclient'));
            }
        )();
    }, [clients]);

    const getClientBalance = (id: number) => {
        let paidAmount: number = 0;
        if (dealsPerClient.length !== 0) {
            dealsPerClient.forEach((deal) => {
                if (deal.status === DealStatus.ToSign) {
                    paidAmount += Number(deal.amount);
                }
            });
        }
        return paidAmount;
    };

    const getNumberOfDeals = (id: number) => {
        let deals = 0;
        if (dealsPerClient.length !== 0) {
            dealsPerClient.forEach((deal) => {
                deals += 1;
            });
        }
        return deals;
    };

    const getNumberOfOrders = (id: number) => {
        let orders: number = 0;
        if (ordersPerClient.length !== 0) {
            ordersPerClient.forEach((deal) => {
                orders += 1;
            });
        }
        return orders;
    }

    return (
        <Col xs={6} md={6} key={client.id} className='clientcard__maincol'>
            <Card key={client.id}>
                <Card.Body css={{ flexDirection: 'row', padding: 0, height: '350px' }}>
                    <Container className="clientcard__container__left">
                        <Row>
                            <Text size={28} weight={'bold'}>{client.firstName} {client.lastName}</Text>
                        </Row>
                        <Row className="mb-3">
                            <TypeRenderer text={client.type} />
                            {/* <Badge disableOutline css={{ background: 'White', color: 'black' }} isSquared variant="flat" borderWeight={'bold'}>{client.type}</Badge> */}
                        </Row>
                        <Row>
                            <Col xs={2} md={2}>
                                <FiMapPin></FiMapPin>
                            </Col>
                            <Col>
                                {client.city}
                            </Col>
                        </Row>
                    </Container>
                    <Container className="clientcard__container__right justify-content-left">
                        <Row className="mb-4">
                            <Row style={{ height: "40px" }}>
                                <Col xs={2} md={2}>
                                    <Text size={20} weight={'bold'}>{client.id ? getNumberOfDeals(client.id) : ''}</Text>
                                </Col>
                                <Col xs={10} md={10}>
                                    <Text size={20} weight={'bold'}>Affaire</Text>
                                </Col>
                            </Row>
                            <Row style={{ height: "40px" }}>
                                <Col xs={2} md={2}>
                                    <Text size={20} weight={'bold'}>{client.id ? getNumberOfOrders(client.id) : ''}</Text>
                                </Col>
                                <Col xs={10} md={10}>
                                    <Text size={20} weight={'bold'}>Commande</Text>
                                </Col>
                            </Row>
                            <Row style={{ height: "40px" }}>
                                <Col xs={2} md={2}>
                                    <FaPiggyBank></FaPiggyBank>
                                </Col>
                                <Col xs={10} md={10}>
                                    <Text size={20} weight={'bold'}>{client.id ? getClientBalance(client.id) : ''} €</Text>
                                </Col>
                            </Row>
                        </Row>
                        <Row>
                            <Row>
                                <Col xs={2} md={2}>
                                    <FiPhoneCall></FiPhoneCall>
                                </Col>
                                <Col xs={10} md={10}>
                                    <Text size={14} weight={'bold'}>{client.phone}</Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2} md={2}>
                                    <FiMail></FiMail>
                                </Col>
                                <Col xs={10} md={10}>
                                    <Text size={14} weight={'bold'}>{client.email}</Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2} md={2}>
                                    <FiUser></FiUser>
                                </Col>
                                <Col xs={10} md={10}>
                                    <Text size={14} weight={'bold'}>{client.partnerId?.firstName} {client.partnerId?.lastName}</Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2} md={2}>
                                    <FiChevronsRight></FiChevronsRight>
                                </Col>
                                <Col xs={10} md={10}>
                                    <Text size={14} weight={'bold'}>{client.creationDate ? formatDate(client.creationDate.toString()) : ''}</Text>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default ClientCard;