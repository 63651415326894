import { Deal } from "../../../../entities/deal.entity";
import { UppercaseFormatter } from "../../../../helper/uppercaseFormattor";
import { formatPhone } from "../../../../helper/phoneFormattor";
import "./../validateDeal.scss"


interface ValidateDealModalProps {
    deal: Deal;
}

function ValidateClient({ deal }: ValidateDealModalProps) {

    return (
        <div style={{ display: "flex", flexDirection: "column", paddingLeft: "5px" }}>
            <ul className="span__Title" style={{ listStyleType: 'circle', paddingLeft: "10px", marginBottom: "0" }}>
                <li style={{ marginBottom: "0" }}>
                    <div className="row" style={{ marginLeft: "5px" }}>
                        <span className="span__Title col-4" >Nom</span>
                        <span className="col-8 span__Info">{UppercaseFormatter(deal.clientId?.lastName ? deal.clientId.lastName : '')}</span>
                    </div>
                </li>
                <li style={{ marginBottom: "0" }}>
                    <div className="row" style={{ marginLeft: "5px" }}>
                        <span className="span__Title col-4" >Prénom</span>
                        <span className="col-8 span__Info">{deal.clientId?.firstName}</span>
                    </div>
                </li>
                <li style={{ marginBottom: "0" }}>
                    <div className="row" style={{ marginLeft: "5px" }}>
                        <span className="span__Title col-4" >Téléphone</span>
                        <span className="col-8 span__Info">{formatPhone(deal.clientId?.phone ? deal.clientId.phone : '')}</span>
                    </div>
                </li>
                <li style={{ marginBottom: "0" }}>
                    <div className="row" style={{ marginLeft: "5px" }}>
                        <span className="span__Title col-4" >Adresse de facturation</span>
                        <span className="col-8 span__Info">{deal.clientId?.address}, {deal.clientId?.zipCode} {deal.clientId?.city}</span>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default ValidateClient;