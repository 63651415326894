import { Link as LinkFromDom, useParams } from "react-router-dom";
import { Collapse, Dropdown, Navbar, changeTheme, useTheme, Text, Link, } from "@nextui-org/react";
import { FiInstagram, FiMoon, FiSun, FiTwitter } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { Key, useRef, useState } from "react";
import "./NavbarEMS.scss";
import Logo from "./Logo";
import Login from "../credentials/login/Login";
import { useDispatch, useSelector } from "react-redux";
import { updateTheme } from "../../redux/actions/ThemeAction";
import ChangePassword from "../credentials/change-password/ChangePassword";
import { Toast } from "primereact/toast";

function NavbarEMS() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isFirstButtonClicked, setIsFirstButtonClicked] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [email, setEmail] = useState<string | undefined>('');
  const toast = useRef<Toast | null>(null);
  const { isDark } = useTheme();

  const handleChange = () => {
    const nextTheme = isDark ? "light" : "dark";
    window.localStorage.setItem("data-theme", nextTheme);
    changeTheme(nextTheme);
    if (nextTheme === "dark") {
      dispatch(updateTheme(true))
    } else {
      dispatch(updateTheme(false))

    }
  };

  function handleClick(key: Key) {
    switch (key) {
      case "userLogin":
        navigate("/login");
        break;
      case "professional":
        navigate("/professional");
        break;
      case "private":
        navigate("/client");
        setNavbarOpen(false);
        break;
      case "coOwnership":
        navigate("/coOwnership");
        break;
      case "charging-station":
        navigate("/charging-station");
        break;
      case "offer":
        navigate("/offer");
        break;
      case "products":
        navigate("/products");
        break;
      case "partner-offer":
        navigate("/partner-offer");
        break;
      case "partner-network":
        navigate("/");
        break;
      default:
        break;
    }
    handleDropdownButtonClick();
  }
  const handleDropdownButtonClick = () => {
    setIsFirstButtonClicked(!isFirstButtonClicked);
  };
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const openLoginModal = () => {
    setShowLoginModal(true);
  };

  const closeLoginModal = (firstLogin?: boolean, email?: string) => {
    setShowLoginModal(false);
    if (!firstLogin && !email) {
      navigate('/');
    } else {
      setEmail(email);
      setShowChangePassword(true);
    }
  };

  const closeChangePasswordModal = (reason?: string) => {
    setShowChangePassword(false);
    if (reason === 'Success' && toast.current) {
      toast.current.show({ severity: 'success', summary: 'Mot de passe changé avec succès. Veuillez vous connecter.', life: 6000 });
    }
    navigate('/');
  };

  return (
    <div>
      <Navbar variant={"sticky"} maxWidth="fluid">
        <Navbar.Brand>
          <LinkFromDom to="/">
            <Logo isDark={isDark} partner={false}></Logo>
          </LinkFromDom>
        </Navbar.Brand>

        <Navbar.Content hideIn="xs">
          {/* <Dropdown isBordered>
            <Navbar.Item>
              <Dropdown.Button
                auto
                light
                className={`dropdown__button ${isFirstButtonClicked ? "dropdown__button-active" : ""}`}
                ripple={false}
                onClick={handleDropdownButtonClick}
              >
                <Text b>
                  Je m'équipe
                </Text>
              </Dropdown.Button>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label="test"
              className="dropdown__menu "
              onAction={(key: Key) => handleClick(key)}
            >
              <Dropdown.Item key="private">Je suis un particulier</Dropdown.Item>
              <Dropdown.Item key="professional">
                Je suis un professionel
              </Dropdown.Item>
              <Dropdown.Item key="coOwnership">
                Je suis une copropriété
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

          {/* <Dropdown isBordered>
            <Navbar.Item>
              <Dropdown.Button
                auto
                light
                className="dropdown__button"
                ripple={false}
                onClick={handleDropdownButtonClick}
              >
                <Text b>
                  Je m'informe
                </Text>
              </Dropdown.Button>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label="test"
              className="dropdown__menu "
              onAction={(key: Key) => handleClick(key)}
            >
              <Dropdown.Item key="charging-station">
                Comment choisir sa borne ?
              </Dropdown.Item>
              <Dropdown.Item key="offer">L'offre EMS</Dropdown.Item>
              <Dropdown.Item key="products">Les produits EMS</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown isBordered>
            <Navbar.Item>
              <Dropdown.Button
                auto
                light
                className="dropdown__button"
                ripple={false}
                onClick={handleDropdownButtonClick}
              >
                <Text b>
                  Je m'associe
                </Text>
              </Dropdown.Button>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label="test"
              className="dropdown__menu "
              onAction={(key: Key) => handleClick(key)}
            >
              <Dropdown.Item key="partner-offer">
                L'offre 360 partenaires
              </Dropdown.Item>
              <Dropdown.Item key="partner-network">
                Le réseau de partenaires
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </Navbar.Content>
        <Navbar.Content hideIn="xs">
          <Navbar.Link onClick={openLoginModal}>
            <Text b>
              Mon espace
            </Text>
          </Navbar.Link>
          <Navbar.Link>
            {isDark ? (
              <FiSun size={24} onClick={handleChange} />
            ) : (
              <FiMoon size={24} onClick={handleChange} />
            )}
          </Navbar.Link>
          {/* <Navbar.Link>
            <FiInstagram size={24} />
          </Navbar.Link>
          <Navbar.Link>
            <FiTwitter size={24} />
          </Navbar.Link> */}
        </Navbar.Content>

        {/* 
        {navbarOpen && (

          <Navbar.Content>
            <Navbar.Collapse>
              <Navbar.CollapseItem>
                <Collapse.Group>
                  <Collapse title="Je m'équipe" showArrow={false} >
                    <Text
                      onClick={() => {
                        handleClick("private");
                        setNavbarOpen(false);
                      }}>Je suis un particulier
                    </Text>
                    <Text
                      onClick={() => {
                        handleClick("professional");
                        setNavbarOpen(false);
                      }}
                    >Je suis un professionel
                    </Text>
                    <Text
                      onClick={() => {
                        handleClick("coOwnership");
                        setNavbarOpen(false);
                      }}
                    >Je suis une copropriété
                    </Text>
                  </Collapse>
                </Collapse.Group>
              </Navbar.CollapseItem>
              <Navbar.CollapseItem>
                <Collapse.Group>
                  <Collapse title="Je m'informe" showArrow={false} css={{ paddingBottom: "0px", paddingTop: "0px" }}>
                    <Text
                      onClick={() => {
                        handleClick("charging-station");
                        setNavbarOpen(false);
                      }}
                    >Comment choisir sa borne ?
                    </Text>
                    <Text
                      onClick={() => {
                        handleClick("offer");
                        setNavbarOpen(false);
                      }}
                    >L'offre EMS
                    </Text>
                    <Text
                      onClick={() => {
                        handleClick("products");
                        setNavbarOpen(false);
                      }}
                    >Les produits EMS
                    </Text>
                  </Collapse>
                </Collapse.Group>
              </Navbar.CollapseItem>
              <Navbar.CollapseItem>
                <Collapse.Group>
                  <Collapse title="Je m'associe" className="test" showArrow={false}>
                    <div
                      onClick={() => {
                        handleClick("partner-offer");
                        setNavbarOpen(false);
                      }}
                    >
                      L'offre 360 partenaires
                    </div>
                    <div
                      onClick={() => {
                        handleClick("partner-network");
                        setNavbarOpen(false);
                      }}
                    >
                      Le réseau de partenaires
                    </div>
                  </Collapse>
                </Collapse.Group>
              </Navbar.CollapseItem>
              <Navbar.CollapseItem>
                <Text
                  color="inherit"
                  className="navBar__phone--title"

                  onClick={openLoginModal}>
                  Mon espace
                </Text>
              </Navbar.CollapseItem>
            </Navbar.Collapse>
          </Navbar.Content>

        )} */}

        {/* <Navbar.Content showIn="xs">
          <Navbar.Toggle onClick={() => setNavbarOpen(true)} />
        </Navbar.Content> */}
      </Navbar>
      {showLoginModal && (
        <Login open={showLoginModal} onClose={closeLoginModal} />
      )}
      {showChangePassword && (
        <ChangePassword open={showChangePassword} onClose={closeChangePasswordModal} email={email} />
      )}
      <Toast ref={toast} position="top-right" />
    </div>
  );
}

export default NavbarEMS;


