import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export function getPartners() {
    return axios.get(API_URL + 'partners', { withCredentials: true });
};
export function getPartnersById(parentId: number) {
    return axios.get(API_URL + `partners/${parentId}`, { withCredentials: true });
};
