import { Modal, Text } from "@nextui-org/react";
import { useEffect, useRef, useState } from "react";
import { login } from '../../../services/auth.service';
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../../redux/actions/LoginAction";
import { Toast } from "primereact/toast";
import { User } from "../../../entities/user.entity";
import { InputText } from "primereact/inputtext";
import CustomButton from "../../shared/Buttons/customButton";
import ResetPassword from "../reset-password/ResetPassword";

interface LoginProps {
  open: boolean;
  onClose: (firstLogin?: boolean, email?: string) => void;
}

function Login({ open, onClose }: LoginProps) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const loggedUser = useSelector((state: any) => state.login.loggedUser);
  const [showResetPassword, setShowResetPassword] = useState(false);

  //Responsivity
  const [isXSmallScreen, setIsXSmallScreen] = useState(window.innerWidth < 650);
  useEffect(() => {
    const handleResize = () => {
      setIsXSmallScreen(window.innerWidth < 650);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const navigate = useNavigate();
  const handleModalClose = () => {
    onClose();
  };

  useEffect(() => {
    if (loggedUser) {
      navigate('/partners-dashboard');
    }
  }, [loggedUser]);

  const toast = useRef<Toast | null>(null);

  const submit = () => {
    login({ email, password })
      .then((user: User) => {
        if (!user.firstLogin) {
          dispatch(loginUser(user));
          handleModalClose();
        } else {
          onClose(true, user.email);
        }
      })
      .catch(error => {
        if (toast.current) {
          toast.current.show({ severity: 'error', summary: 'Adresse e-mail ou mot de passe incorrect', life: 6000 });
        }
      })
  }

  function closeResetPasswordModal(success?: boolean, message?: string) {
    if (toast.current && success !== undefined) {
      toast.current.show({ severity: success ? "success" : "error", summary: message, life: 6000 });
    }
    setShowResetPassword(false);
  }

  return (
    <div>
      <Modal
        closeButton
        blur
        aria-labelledby="modal-title"
        width="450px"
        open={open}
        onClose={handleModalClose}
        className="modal__container"
        fullScreen={false}
      >
        <Modal.Header>
          <Text b id="modal-title" size={18}>Connectez-vous à EMS</Text>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span className="p-input-icon-left" style={{ width: "100%" }}>
              <i className="pi pi-envelope" />
              <InputText
                id="Email"
                name="Email"
                placeholder="Email"
                onChange={e => setEmail(e.target.value)}
                style={{ width: "100%" }}
                aria-labelledby="Email"
              />
            </span>
          </div>
          <div>
            <span className="p-input-icon-left" style={{ width: "100%" }}>
              <i className="pi pi-lock" />
              <InputText
                id="password"
                name="password"
                placeholder="Mot de passe"
                onChange={e => setPassword(e.target.value)}
                style={{ width: "100%" }}
                aria-labelledby="Mot de passe"
                type="password"
              />
            </span>
          </div>
          <div className="div__reset-pwd">
            <span className="span__reset-pwd" onClick={e => setShowResetPassword(true)} >
              Mot de passe oublié?
            </span>
          </div>
        </Modal.Body>
        <div className="footer__div">
          <div onClick={handleModalClose}>
            <CustomButton title="Annuler" />
          </div>
          <div onClick={submit}>
            <CustomButton title="Se connecter" />
          </div>
        </div>
        <Toast ref={toast} position="top-right" />
      </Modal>
      {showResetPassword && (
        <ResetPassword open={showResetPassword} onClose={closeResetPasswordModal} />
      )}
    </div>
  );
}
export default Login;
